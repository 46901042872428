import axios from 'axios';

axios.defaults.withCredentials = true;
axios.defaults.crossDomain = true;

const apiUrl = {
	staticAssetsURL: process.env.REACT_APP_STATICS_ASSETS_URL,
	path: {
		microsoftLogin: '/maples/session/oauth2/microsoft/url',
		validateAuthToken: '/maples/session/oauth2/microsoft/valid ate',
		auth: '/maples/auth',
		strategies: '/strategies',
		strategy: '/strategy',
		teamsLogin: '/teams',
		accessToken: '/maples/session/renew',
		office: '/maples/office',
		area: '/maples/area',
		teak: '/maples/teak',
		teakFeature: '/maples/teak/feature',
		teakType: '/maples/teak/type',
		teakReservation: '/maples/teak/reservation',
		claimDesk: '/claim',
		member: '/maples/member',
		findMany: '/findMany',
		findNext: '/findNext',
		banner: '/banner',
		reservation: '/reservation',
		end: '/end',
		mapOutlines: '/map/outlines',
		healthQuestionnaireLastCompletion: '/healthQuestionnaireLastCompletion',
		confirmHealthQuestionnaireCompletion: '/confirmHealthQuestionnaireCompletion',
		badge: '/maples/badge',
		verificationCode: '/verificationCode',
		validateVerificationCode: '/verificationCode/validate',
		photo: '/photo',
		cancel: '/cancel',
		cancelOccurences: '/cancelOccurences',
		organization: '/maples/organization',
		logo: '/logo',
		userFeedback: '/maples/feedback',
		upsertVisitor: '/upsert',
		postpone: '/postpone',
		ceylon: '/maples/ceylon',
		registerCertificate: '/registerCertificate',
		validateCertificate: '/validateCertificate',
		grouped: '/grouped',
		findInRange: '/findInRange',
		completePlusStatus: '/completePlusStatus',
		getPlusStatus: '/plusStatus',
		favoriteTeakEntity: '/addFavoriteTeakEntity',
		favoriteAspenItem: '/editFavoriteAspenItem',
		reportSick: '/reportSick',
		findColleague: '/findMyColleague',
		url: '/url',
		broadcast: '/broadcast',
		releaseNotes: '/maples/release',
		areaMapElement: '/areaMapElement',
		countInRange: '/teak/booking/countInRange',
		category: '/category',
		updatedStatistics: '/teak/type/stats',
		emergencyNotification: '/broadcastCrisis',
		oakCrisis: '/oakCrisis/',
		updateMemberResponse: '/updateMemberResponse',
		pine: '/maples/pine',
		invitation: '/invitation',
		visitor: '/visitor',
		featureNamesTranslations: '/translations/teakFeatures.json',
		validate: '/validate',
		findColleagueCalendarView: '/findMyColleagueCalendarView',
		findOtherColleaguesCalendarView: '/findOtherColleaguesCalendarView',
		paymentIntent: '/payment/createIntent',
		context: '/context',
		favoriteOffice: '/editFavoriteOffice',
		availabilityView: '/availabilityView',
		verificationCodeCheckIn: '/verificationCode/checkIn',
		nukiLock: (id) => `/maples/nukiLock/${id}/unlock`,
	},
};

export {
	getManyTeakTypes,
	getBadgeVerificationCode,
	validateBadgeVerificationCode,
	confirmHealthQuestionnaireCompletion,
	healthQuestionnaireLastCompletion,
	getOrganizationLogo,
	getOrganization,
	submitUserFeedback,
	registerCertificate,
	validateCertificate,
	completePlusStatus,
	getPlusStatus,
	processTeakEntityToFavorites,
	getLoginStrategies,
	validateAuthCode,
	findColleagueFromEmail,
	getLoginUrl,
	loginViaTeams,
} from './additionals.js';

export {getManyTeakFeatures, getTeakFeatureById} from './teakFeature.js';

export {getAreasFromOffice, getAreaById, getBannerForArea, getMapOutline} from './areas.js';

export {getOffices, getBannerForOffice} from './offices.js';

export {
	getMicrosoftLoginUrl,
	memberChangeLastSelectedOffice,
	getMember,
	getMemberPhoto,
	loggingOut,
	updateMemberLanguage,
	generateOTP,
	validateOTP,
	updateProfileCompletionAPI,
	getPersonalizedStats,
} from './member.js';

export {getTeakEntity, bookTeakEntity, getManyEntities, reportProblemForTeakEntity, checkConflictsForRecurringBooking} from './teakEntity';

export {findBookingsInRange, checkInNow, checkOutNow, extendTeakBooking} from './teakBooking';

export {reportProblemForEntity, reportOtherProblem, reportProblemUploadPhoto, reportBugProblem} from './reportProblem';

export {getBadgesData} from './badges';

export {getAspenTypes, getAspenItems} from './aspen';

export default apiUrl;
