import {useTranslation} from 'react-i18next';
import {DateTime} from 'luxon';
import {useHistory} from 'react-router';
import ReportItem from './ReportItem';
import SecondaryHeader from '../../../Common/Header/SecondaryHeader';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {enableBottomMenu} from '../../../../store/auth';
import {loadIssueReports} from '../../../../store/reportProblem';
import Icon from '../../../Common/Icon';
import useTimer from '../../../../hooks/useTimer';
import PullToRefresh from 'react-simple-pull-to-refresh';
import RefreshingContent from '../../../Common/RefreshingContent';
import {Typography} from '../../../Common/Typography';


export function getReportItemsCategorized(reportList) {
	if (!reportList || reportList.length < 1) return [];
	let sortedReportList = reportList.slice().sort((a, b) => DateTime.fromISO(b?.createdAt) - DateTime.fromISO(a?.createdAt));

	let reportItemsArray = sortedReportList.map(report => {
		return <ReportItem reportData={report} />;
	});

	return reportItemsArray;
}

function MyReportsPage() {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const reportList = useSelector((state) => state.reportProblem.issueReports);
	const selectedOffice = useSelector((state) => state.auth.data.selectedOffice);
	const reportItems = getReportItemsCategorized(reportList);

	useEffect(() => {
		dispatch(loadIssueReports(true));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedOffice]);

	// * every 1 min load the reports data again
	useTimer(60000, () => {
		dispatch(loadIssueReports());
	});
	return (
		<PullToRefresh
			onRefresh={() => {
				return Promise.resolve(dispatch(loadIssueReports(true)));
			}}
			resistance={'3'}
			backgroundColor={'white'}
			pullDownThreshold={70}
			refreshingContent={<RefreshingContent />}
			pullingContent={''}>
			<div className="my-reports-page">
				<SecondaryHeader
					headerTranslationKey={'personalStats.stats.reportedProblems'}
					backButtonClickHandler={() => history.goBack()}
					disableBoxShadow={true}
				/>
				<div className='my-reports-page-content'>
					<div className='my-reports-title'>
						<Typography variant="headline-medium">{t('settings.reportProblem.allReportedProblems')}</Typography>
						<Typography variant="title-small">{t('settings.reportProblem.reportedProblems', {count: reportItems?.length})}</Typography>
					</div>
					<div className="my-reports-container-items">{reportItems}</div>
				</div>
			</div>
		</PullToRefresh>
	);
}

function NoResultsMessage({activeTab}) {
	const {t} = useTranslation();
	return (
		<div className="no-reports-wrapper">
			<Icon iconName={'manageSearch'} cssClass={'action-icon'} iconColor={'#C4C4C4'} />
			<p className="bold">{t('settings.reportProblem.myReports.noReports')}</p>
			<p>{t(`settings.reportProblem.myReports.noReportsInfo.${activeTab}`)}</p>
		</div>
	);
}
export default MyReportsPage;
