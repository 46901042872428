import {useEffect} from 'react';

function useTimer(delay, callback) {
	useEffect(() => {
		const timer = setInterval(() => callback(), delay || 1000);

		return () => {
			clearInterval(timer);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
}

export default useTimer;
