import {t} from 'i18next';
import {DateTime} from 'luxon';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {checkinIntoSession} from '../../api/event';
import {CARD} from '../../constants/cards';
import {ROUTES} from '../../constants/routes';
import {updateActionSheet} from '../../store/auth';
import {enableCard, updateErrorCard, updateSuccessCard} from '../../store/cards';
import {loadEventSessionsOfUser, showBoothOnMap} from '../../store/events';
import {BOTTOM_MAP_CARDS} from '../../constants/bottomCardTypes';

const SessionMenu = (props) => {
	const dispatch = useDispatch();
	const data = useSelector((state) => state.auth.data.actionSheetData);
	const selectedOffice = useSelector((state) => state.auth.data.selectedOffice);
	const isUserCheckedIntoEvent = useSelector((state) => state.events.userCheckedIntoEvent);
	const history = useHistory();

	function redirectToSessionDetails() {
		history.push(ROUTES.SESSION_DETAILS, {session: data});
	}

	function redirectToAskQuestion() {
		history.push(ROUTES.ASK_QUESTION, {session: data});
	}

	function redirectToCGIMeter() {
		history.push(ROUTES.EXTERNAL_LINK, {url: data?.cgiMeterPollUrl, heading: 'CGI Meter'});
	}

	async function checkinHandler(e) {
		e.stopPropagation();
		if (data?.userCheckedIn) return;
		dispatch(enableCard(true));
		dispatch(updateActionSheet(false));
		try {
			await checkinIntoSession(data?.wengeEvent, data?._id);
			dispatch(updateSuccessCard(CARD.MAIN_SUCCESS, 'successMessages.eventCheckedInSuccessfully', 'event.successCheckinSession'));
			dispatch(loadEventSessionsOfUser(data?.wengeEvent));
		} catch (error) {
			console.log(error);
			dispatch(updateErrorCard(CARD.MAIN_ERROR, 'event.errorCheckinSession'));
		}
	}

	function openInAppSessionFeedback(e) {
		if (data?.userAlreadyRated) {
			e.stopPropagation();
			return;
		}
		history.push(ROUTES.RATE_SESSION, {session: data});
	}



	function showSessionLocationOnMap() {
		dispatch(showBoothOnMap(data?.wengeBooth, BOTTOM_MAP_CARDS.boothData));
		history.push({
			pathname: ROUTES.MAP_VIEW,
		});
	}

	return (
		<>
			<div className="action-sheet-option" onClick={redirectToSessionDetails}>
				{t('event.actionMenu.sessionDetails')}
			</div>
			{isUserCheckedIntoEvent && isCheckInOpen(data, selectedOffice) && (
				<div className={`action-sheet-option ${data?.userCheckedIn ? 'checked-in-view' : ''}`} onClick={checkinHandler}>
					{t(data?.userCheckedIn ? 'event.actionMenu.attendeeCheckedIn' : 'event.actionMenu.checkInNow')}
				</div>
			)}
			{isUserCheckedIntoEvent && isLivePollOpen(data, selectedOffice) && (
				<div className="action-sheet-option" onClick={redirectToCGIMeter} >
					{t('event.actionMenu.cgiMeter')}
				</div>
			)}
			{isUserCheckedIntoEvent && isQandAOpen(data) && (
				<div className="action-sheet-option" onClick={redirectToAskQuestion}>
					{t('event.actionMenu.sendQuestion')}
				</div>
			)}

			{isUserCheckedIntoEvent && isRatingSessionOpen(data, selectedOffice) && (
				<div className={`action-sheet-option-main ${data?.userAlreadyRated ? 'checked-in-view' : ''}`} onClick={openInAppSessionFeedback}>
					{t(data?.userAlreadyRated ? 'event.actionMenu.userAlreadyRated' : 'event.actionMenu.feedbackForm')}
				</div>
			)}
			{data?.wengeBooth?.mapRepresentation && (
				<div className={`action-sheet-option`} onClick={showSessionLocationOnMap}>
					{t('event.actionMenu.showLocationOnMap')}
				</div>
			)}
		</>
	);
};

export function isRatingSessionOpen(data, selectedOffice) {
	const timezone = selectedOffice?.timezone;
	const currentTime = DateTime.now().setZone(timezone);
	const rateSessionAfterEnd = data?.rateSessionAfterEnd ? Math.abs(data?.rateSessionAfterEnd / 60000 / 60) : 24; //show rate your session after end of event -> amount in hrs
	const rateSessionBeforeEnd = data?.rateSessionBeforeEnd ? Math.abs(data?.rateSessionBeforeEnd / 60000) : 15; //show rate your session if end time is in X mins -> amount in mins
	const sessionStartTime = DateTime.fromISO(data?.start).setZone(timezone);
	const sessionEndTime = DateTime.fromISO(data?.end).setZone(timezone);
	const enableRatings = data?.enableRatings;

	if (!enableRatings) return false;

	if (sessionStartTime > currentTime) {
		return false;
	}

	if (sessionEndTime.minus({minutes: rateSessionBeforeEnd}) > currentTime || sessionEndTime.plus({hours: rateSessionAfterEnd}) < currentTime) {
		return false;
	}

	return true;
}

export function isCheckInOpen(data, selectedOffice) {
	const timezone = selectedOffice?.timezone;
	const currentTime = DateTime.now().setZone(timezone);
	const checkInAfterEnd = data?.checkInAfterEnd ? Math.abs(data?.checkInAfterEnd / 60000) : 30; //show check in after end time is in X min -> amount in mins
	const checkInBeforeStart = data?.checkInBeforeStart ? Math.abs(data?.checkInBeforeStart / 60000) : 30; //show check in if start time is in X min -> amount in mins
	const sessionStartTime = DateTime.fromISO(data?.start).setZone(timezone);
	const sessionEndTime = DateTime.fromISO(data?.end).setZone(timezone);
	const enableCheckins = data?.enableCheckins;

	if (!enableCheckins) return false;

	if (sessionStartTime.toLocaleString(DateTime.DATE_SHORT) !== currentTime.toLocaleString(DateTime.DATE_SHORT)) {
		return false;
	}

	if (sessionStartTime.minus({minutes: checkInBeforeStart}) > currentTime || sessionEndTime.plus({minutes: checkInAfterEnd}) < currentTime) {
		return false;
	}

	return true;
}

export function isLivePollOpen(data, selectedOffice) {
	const timezone = selectedOffice?.timezone;
	const currentTime = DateTime.now().setZone(timezone);
	const sessionStartTime = DateTime.fromISO(data?.start).setZone(timezone);
	const sessionEndTime = DateTime.fromISO(data?.end).setZone(timezone);
	const pollExists = data?.cgiMeterPollUrl;

	if (!pollExists) return false;

	if (sessionEndTime < currentTime) {
		return false;
	}
	if (sessionStartTime > currentTime) {
		return false;
	}

	return true;
}

export function isQandAOpen(data, selectedOffice) {
	const timezone = selectedOffice?.timezone;
	const currentTime = DateTime.now().setZone(timezone);
	const sessionStartTime = DateTime.fromISO(data?.start).setZone(timezone);
	const sessionEndTime = DateTime.fromISO(data?.end).setZone(timezone);
	const enableQandA = data?.enableQandA;

	if (!enableQandA) return false;

	if (sessionEndTime < currentTime) {
		return false;
	}
	if (sessionStartTime > currentTime) {
		return false;
	}

	return true;
}

export default SessionMenu;
