import {forwardRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {handleFocus} from '../../../functions/handleFocus';
/**
 *
 * @param {*} maximumCharacterLimit = max chars that can be added in text area
 * @param {*} cssClass = classname used for textarea wrapper
 * @param {*} defaultValue = value of textarea
 * @param {*} labelKey = label text used for textarea label
 * @param {*} disabled = if textarea is disabled
 * @param {*} cols = columns number for textarea
 * @param {*} rows = rows number for textarea
 * @param {*} id = id of textarea input
 * @param {*} maxLength = max length  of textarea
 * @param {*} onFocus = callback used when textarea is focused
 * @param {*} onBlur = callback used when focus is moved from textarea
 * @param {*} required = if input is required
 * @param {*} changeHandler = callback when textarea value is changed
 * @param {*} placeholder = text shown as placeholder in textarea
 * @returns Component that renders text area component
 */
export const Textarea = forwardRef(
	(
		{
			maximumCharacterLimit = '',
			cssClass = '',
			defaultValue,
			labelKey,
			disabled,
			cols,
			rows,
			id,
			maxLength,
			onFocus,
			onBlur,
			required,
			changeHandler,
			placeholder,
		},
		ref,
	) => {
		const {t} = useTranslation();
		const [inputValue, setInputValue] = useState(defaultValue);

		let [numberOfCharsLeft, setNumberOfCharsLeft] = useState(
			inputValue ? Math.abs(maximumCharacterLimit - inputValue?.length) : maximumCharacterLimit,
		);
		let numberOfCharsText = t('characterHint');

		if (disabled) cssClass += ' disabled';

		let requiredMark = required ? '*' : '';

		const handleChange = (newValue) => {
			changeHandler(newValue);

			setInputValue(newValue);

			if (!maximumCharacterLimit) {
				return;
			}

			if (maximumCharacterLimit - newValue.length > 0) {
				setNumberOfCharsLeft(maximumCharacterLimit - newValue.length);
			} else {
				setNumberOfCharsLeft(0);
			}
		};

		return (
			<div id={labelKey} className={'textarea-holder ' + cssClass}>
				{labelKey ? <label className={'primary-input-label'}>{t(labelKey) + requiredMark}</label> : null}
				{maximumCharacterLimit ? (
					<span className={'primary-input-hint-text'}>{' (' + numberOfCharsLeft + ' ' + numberOfCharsText + ')'}</span>
				) : null}
				<textarea
					className={'textarea'}
					placeholder={t(placeholder)}
					onChange={(e) => handleChange(e.target.value)}
					disabled={disabled}
					cols={cols}
					rows={rows}
					maxLength={maxLength}
					id={id}
					value={inputValue}
					onFocus={() => {
						if (onFocus) {
							onFocus();
						}
						handleFocus(labelKey ?? id, {block: 'start'})}
					}
					onBlur={onBlur}
					ref={ref}
				/>
			</div>
		);
	},
);
