import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';

import {ROUTES} from '../../../../constants/routes';
import FormattedDateTime from '../../../Common/DateTimePicker/FormattedDateTime';
import {updateReadOfIssueReport} from '../../../../api/reportProblem';
import {loadIssueReports} from '../../../../store/reportProblem';
import {useDispatch} from 'react-redux';
import {Typography} from '../../../Common/Typography';


function ReportItem({reportData}) {
	const {t} = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const unreadUpdates = reportData.history?.filter((historyItem) => {
		return historyItem?.origin?.user && historyItem?.comment && !historyItem?.read?.readBy?.member;
	});
	const nbOfUnreadUpdates = unreadUpdates?.length;
	const status = reportData?.status;
	const date = reportData?.createdAt;
	const name = reportData?.description;

	const handleReportItemClick = async () => {
		dispatch(loadIssueReports(true));
		history.push(ROUTES.REPORT_PROBLEM + ROUTES.REPORT_DETAILS, {issueReport: reportData});
	};

	return (
		<div className="report-item" onClick={handleReportItemClick}>
			<div className='report-item-wrapper'>
				<div className="report-item-content-wrapper">
					<Typography className="name bold ellipsis">{name}</Typography>
					<div></div>
					<div className='report-item-location-time-wrapper'>
						{/* <p className="location">{location}</p> */}
						<p className="date">
							<FormattedDateTime dateTime={date} />
						</p>
					</div>
					<div className={`report-item-label report-item-label-${status}`}>{t(`settings.reportProblem.reportItemStatus.${status}`)}</div>
				</div>
				{nbOfUnreadUpdates > 0 && <div className="new-updates">{nbOfUnreadUpdates}</div>}
			</div>
		</div>
	);
}

export default ReportItem;
