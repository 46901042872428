

import {useTranslation} from 'react-i18next';
import BeatLoader from 'react-spinners/BeatLoader';
import Icon from '../Icon';
/**
 *
 * @param {string} props.cssClass = css class added to the button element ( can be used to define if the button has 'accent' tones - eg. different colors)
 * @param {boolean} props.disabled = if the button is disabled or not
 * @param {string} props.variant = the type of button to be shown ( primary, secondary, tertiary, empty, negative => defaults to primary)
 * @param {string} props.width = width of the button ( full, half or variable = content + 40px padding-right-left => deafults to full)
 * @param {string} props.height = height of the button ( huge - 56px, regular - 48px , small - 40px => defaults to regular)
 * @param {string} props.iconName = name of the icon
 * @param {string} props.iconColor = color of the icon
 * @param {string} props.iconPlacement = where to put the icon? left of the text or right of the text ( defaults to right ) ( accepts 'left' or 'right' )
 * @param {function} props.onClick = callback for button click
 * @param {string} props.translationKey = translation key for button text
 * @param {boolean} props.loading = if this flag is true, the button shows a loading animation
 *
 * @returns
 */
function Button(props) {
	const {t} = useTranslation();

	const {
		cssClass,
		disabled = false,
		variant = 'primary',
		width = 'full',
		height = 'regular',
		iconName,
		iconColor,
		iconPlacement = 'right',
		onClick,
		translationKey,
		loading,
		roundCorners = false,
	} = props;

	let iconStyle = {};
	let buttonStyle = {};
	// use specific styles when we have or not an icon/text and the button has a variable width/height
	if (!translationKey) {
		if (height !== 'small') {
			buttonStyle.width = '36px';
		}
	} else {
		iconStyle = iconPlacement === 'right' ? {marginLeft: '8px'} : {marginRight: '8px'};
		if (height !== 'small' && iconName && iconPlacement === 'left' && width === 'variable') {
			buttonStyle.paddingLeft = '12px';
		}
	}

	const contentArray = [
		<span key={'text-button-key'}>
			{' '}
			{loading ? <BeatLoader size={10} color={variant === 'primary' ? 'white' : 'black'} /> : t(translationKey)}{' '}
		</span>,
		<Icon key={'icon-button-key'} iconName={iconName} iconColor={iconColor} style={iconStyle}></Icon>,
	];
	return (
		<button
			disabled={disabled}
			onClick={onClick}
			style={buttonStyle}
			className={`button ${variant} ${width}-width ${height}-height ${roundCorners && 'round-corners'} ${cssClass}`}>
			{iconPlacement === 'right' ? contentArray : contentArray.reverse()}
		</button>
	);
}

export default Button;
