import {combineReducers} from 'redux';
import authReducer from './auth';
import cardsReducer from './cards';
import reportProblemReducer from './reportProblem';
import settingsReducer from './settings';
import profilePageReducer from './profile';
import mapViewReducer from './bookingProcess/mapView';
import personalizedStats from './personalizedStats';
import badges from './badges';
import eventsReducer from './events';

export default combineReducers({
	auth: authReducer,
	cards: cardsReducer,
	reportProblem: reportProblemReducer,
	settings: settingsReducer,
	profile: profilePageReducer,
	mapView: mapViewReducer,
	personalizedStats: personalizedStats,
	badges: badges,
	events: eventsReducer,
});
