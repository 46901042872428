import {t} from 'i18next';
import {Component} from 'react';
import {addErrorToServer} from './api/additionals';

import Button from './components/Common/Button';
import {ROUTES} from './constants/routes';
import ErrorImage from './image/error-page-crashed.svg';

export class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = {hasError: false};
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		console.log(error);
		return {hasError: true};
	}

	componentDidCatch(error, errorInfo) {
		var caller_line = error.stack.split('\n')[1];
		var index = caller_line.indexOf('at ');
		var clean = caller_line.slice(index + 2, caller_line.length);
		addErrorToServer(`${error.message} at ${clean}`);
	}

	returnHome() {
		window.location.assign(ROUTES.DEFAULT);
	}

	redirectToReportProblem() {
		window.location.assign(ROUTES.REPORT_PROBLEM + ROUTES.APP_TECHNICAL_PROBLEM);
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<div className="error-page">
					<img alt="error" src={ErrorImage} />
					<h3>{t('errorMessages.crashedPageError')}</h3>
					<h4>{t('errorMessages.crashedPageErrorInfo')}</h4>
					<Button variant={'primary'} height={'regular'} width={'full'} translationKey={'common.goHome'} onClick={this.returnHome} />
					<Button
						variant={'secondary'}
						height={'regular'}
						width={'full'}
						translationKey={'common.reportTheProblem'}
						onClick={this.redirectToReportProblem}
					/>
				</div>
			);
		}

		return this.props.children;
	}
}
