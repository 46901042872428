
import {useSelector} from 'react-redux';

import CardPlaceholder from './Common/CardPlaceholder';
import SuccessCard from './Common/SuccessCard';
import ErrorCard from './Common/ErrorCard';
import ReportProblem from './Common/ReportProblem';
import MemberCard from './MemberBadge/MemberCard';
import MemberCardPlaceholder from './MemberBadge/MemberCardPlaceholder';
import QuickTourInfo from './Common/QuickTourInfo';
import BroadcastNotificationCard from './Common/BroadcastNotificationCard';
import UnlockNukiCard from './Common/UnlockNukiCard';
import ConfirmActionCard from './Common/ConfirmAction/ConfirmActionCard';
import BoothAlreadyScannedCard from './Common/BoothAlreadyScannedCard';

export const cardMap = {
	reportProblem: ReportProblem,
	cardPlaceholder: CardPlaceholder,
	memberBadgePlaceholder: MemberCardPlaceholder,
	memberBadge: MemberCard,
	mainSuccess: SuccessCard,
	mainError: ErrorCard,
	quickTourInfo: QuickTourInfo,
	broadcastNotificationCard: BroadcastNotificationCard,
	unlockNukiCard: UnlockNukiCard,
	confirmAction: ConfirmActionCard,
	boothAlreadyScanned: BoothAlreadyScannedCard,
};

const Card = () => {
	const cardToShow = useSelector((state) => state.cards.cardToShow);
	const show = useSelector((state) => state.cards.showCard);

	const showCard = show ? 'modal-card show' : 'modal-card';
	const CardComponent = cardMap[cardToShow];

	if (CardComponent === cardMap.mainSuccess || CardComponent === cardMap.mainError) {
		return <CardComponent />
	}

	return (
		<div className={showCard}>
			<CardComponent />
		</div>
	);
};

export default Card;
