import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import {enableCard} from '../../../store/cards';
import {errors} from '../../../constants/errors';
import {Typography} from '../../Common/Typography';
import Icon from '../../Common/Icon';
import {useEffect, useState} from 'react';


function ErrorCard(props) {
	const {t} = useTranslation();
	const dispatch = useDispatch();

	const errorCardProps = useSelector((state) => state.cards.errorCardProps);
	const iconColor = errorCardProps.iconColor ? errorCardProps.iconColor : 'icon-red';
	const iconName = errorCardProps.iconName ? errorCardProps.iconName : 'exclamation-circle';
	let icon = <Icon cssClass={iconColor} iconName={iconName} />;
	const errorHeader = errors[errorCardProps?.name]
		? errors[errorCardProps?.name]?.header
			? errors[errorCardProps?.name].header
			: errors[errorCardProps?.name]
		: errorCardProps?.header;
	const errorBody = errors[errorCardProps?.name] ? (errors[errorCardProps?.name]?.body ? errors[errorCardProps?.name].body : null) : null;

	//hidden state for animation when closing;
	const [hidden, setHidden] = useState(false);

	const closeCard = () => {
		if (hidden)
			return;

		setHidden(true);
		setTimeout(() => {
			dispatch(enableCard(false));
		}, 1000)
	};

	useEffect(() => {
		//close card after 4 seconds
		setTimeout(() => {
			closeCard();
		}, 4000);
	}, [])

	return (
		<div className={"feedback-banner feedback-banner-error " + (hidden ? 'hidden' : '')}>
			<div className='feedback-banner-content'>
				<div className="icon-circle">
					{icon}
				</div>
				<div className="info">
					<Typography variant={'title-medium'} className={'bold'}>{t(errorHeader)}</Typography>
					<Typography variant={'title-small'}>{t(errorBody)}</Typography>
				</div>
			</div>
			<div className="icon-close" onClick={closeCard}>
				<Icon iconName={'close'} iconColor={'icon-black'} />
			</div>
		</div >
	);
}

export default ErrorCard;
