function shouldBeHidden(item: any, form: any, data: any) {
	const rules = item.visibilityRules;
	if (!rules || rules.length === 0) {
		return false;
	}

	for (const rule of rules) {
		const matchingBlock = form.items.find((block: any) => block.uuid === rule.block);

		if (!matchingBlock) {
			return true;
		}

		const blockValue = data.find((dataItem: any) => dataItem.key === matchingBlock.uuid)?.value;
		const ruleValues = parseRuleValue(rule.value);

		let conditionMet = false;
		switch (rule.operator) {
			case '==':
				conditionMet = ruleValues.some((value) => isEqual(blockValue, value));
				break;
			case '!=':
				conditionMet = ruleValues.every((value) => !isEqual(blockValue, value));
				break;
			case '>':
				conditionMet = ruleValues.some((value) => isGreaterThan(blockValue, value));
				break;
			case '>=':
				conditionMet = ruleValues.some((value) => isGreaterThanOrEqual(blockValue, value));
				break;
			case '<':
				conditionMet = ruleValues.some((value) => isLessThan(blockValue, value));
				break;
			case '<=':
				conditionMet = ruleValues.some((value) => isLessThanOrEqual(blockValue, value));
				break;
			case 'contains':
				conditionMet = ruleValues.some((value) => contains(blockValue, value));
				break;
			case 'notContains':
				conditionMet = ruleValues.every((value) => !contains(blockValue, value));
				break;
			case 'startsWith':
				conditionMet = ruleValues.some((value) => startsWith(blockValue, value));
				break;
			case 'endsWith':
				conditionMet = ruleValues.some((value) => endsWith(blockValue, value));
				break;
			default:
				conditionMet = false;
				break;
		}

		if (!conditionMet) {
			return true;
		}
	}

	return false;
}

function parseRuleValue(value: any): string[] {
	if (typeof value === 'string') {
		return value.split(',').map((v) => v.trim());
	} else if (Array.isArray(value)) {
		// if empty array return ""
		if (value.length === 0) {
			return [''];
		}

		return value.map((v) => v.toString());
	}
	return [''];
}

function parseNumberOrString(value: any): number | string | any[] {
	const isArray = Array.isArray(value);
	if (isArray) {
		return value;
	}

	const isNumber = value && !isNaN(value);
	if (isNumber) {
		return parseFloat(value);
	}

	return value;
}

function isGreaterThan(a: any, b: any): boolean {
	const parsedA = parseNumberOrString(a);
	const parsedB = parseNumberOrString(b);

	if (typeof parsedA === 'number' && typeof parsedB === 'number') {
		return parsedA > parsedB;
	}
	if (typeof parsedA === 'string' && typeof parsedB === 'string') {
		return parsedA.localeCompare(parsedB, undefined, {sensitivity: 'base'}) > 0;
	}
	return false;
}

function isEqual(a: any, b: any): boolean {
	const parsedA = parseNumberOrString(a);
	const parsedB = parseNumberOrString(b);

	if (Array.isArray(a)) {
		return a.some((item) => isEqual(item, b));
	}
	if (typeof parsedA === 'number' && typeof parsedB === 'number') {
		return parsedA === parsedB;
	}
	if (typeof parsedA === 'string' && typeof parsedB === 'string') {
		return parsedA.localeCompare(parsedB, undefined, {sensitivity: 'base'}) === 0;
	}
	return false;
}

function isGreaterThanOrEqual(a: any, b: any): boolean {
	return isGreaterThan(a, b) || isEqual(a, b);
}

function isLessThan(a: any, b: any): boolean {
	const parsedA = parseNumberOrString(a);
	const parsedB = parseNumberOrString(b);

	if (Array.isArray(a)) {
		return a.some((item) => isLessThan(item, b));
	}
	if (typeof parsedA === 'number' && typeof parsedB === 'number') {
		return parsedA < parsedB;
	}
	if (typeof parsedA === 'string' && typeof parsedB === 'string') {
		return parsedA.localeCompare(parsedB, undefined, {sensitivity: 'base'}) < 0;
	}
	return false;
}

function isLessThanOrEqual(a: any, b: any): boolean {
	return isLessThan(a, b) || isEqual(a, b);
}

function contains(a: any, b: string): boolean {
	if (typeof a === 'string') {
		return a.toLowerCase().includes(b.toLowerCase());
	}
	if (Array.isArray(a)) {
		return a.some((item) => contains(item, b));
	}
	return false;
}

function startsWith(a: any, b: string): boolean {
	if (typeof a === 'string') {
		return a.toLowerCase().startsWith(b.toLowerCase());
	}
	if (Array.isArray(a) && a.length > 0) {
		return startsWith(a[0], b);
	}
	return false;
}

function endsWith(a: any, b: string): boolean {
	if (typeof a === 'string') {
		return a.toLowerCase().endsWith(b.toLowerCase());
	}
	if (Array.isArray(a) && a.length > 0) {
		return endsWith(a[a.length - 1], b);
	}
	return false;
}

export default shouldBeHidden;
