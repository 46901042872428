import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {enableBottomMenu} from '../../store/auth';
import CGIEventsHeader from '../Common/Header/CGIEventsHeader';
import {Typography} from '../Common/Typography';
import {t} from 'i18next';

import ProfileItem from '../ProfilePage/ProfileItem';
import MyReportsSection from '../ProfilePage/ReportProblem/MyReports/MyReportsSection';
import {loadIssueReports} from '../../store/reportProblem';
import RefreshingContent from '../Common/RefreshingContent';
import PullToRefresh from 'react-simple-pull-to-refresh';

export function getPropertyTranslation(property, language) {
	const displayFrench = language === 'fr';
	const translation = displayFrench && property?.fr ? property?.fr :
		property?.en ? property?.en : '';

	return translation;
}

function EventSupport() {
	const dispatch = useDispatch();
	const event = useSelector((state) => state.events.selectedEvent);
	const userData = useSelector((state) => state.auth.data.userData);
	const faqLinks = event?.options?.appLinks?.filter(link => link.type === 'faq');

	useEffect(() => {
		dispatch(enableBottomMenu(true));
	}, []);

	return (

		<div className="event-support-page">
			<CGIEventsHeader />
			<div className="event-support-page-content">
				<Typography variant="headline-medium">{t('common.support')}</Typography>

				<MyReportsSection />
				{faqLinks?.length > 0 &&
					<div className='support-section'>
						<h4>{t('profile.headers.help')}</h4>

						{faqLinks?.map(faqLink => {
							const title = getPropertyTranslation(faqLink?.title, userData?.language);
							const link = getPropertyTranslation(faqLink?.link, userData?.language);
							const icon = faqLink?.icon;
							return (
								<ProfileItem
									heading={title}
									iconName={icon}
									onClick={() => {window.open(link, '_blank');}}
									enabledExclamationIcon={false}
								/>
							)
						})}
					</div>
				}
			</div>
		</div>
	);
}
export default EventSupport;
