import {BeatLoader} from 'react-spinners';

/**
 *
 * @param {String} className = className of the refreshing content wrapper div
 * @param {string} loaderColor = color of loading animation
 * @returns Simple React component that holds the common beat loader shown when pulling a page for refresh
 */
function RefreshingContent({className = 'refreshing-content', loaderColor = 'black'}) {
	return (
		<div className={className}>
			<BeatLoader size={10} color={loaderColor} />
		</div>
	);
}

export default RefreshingContent;
