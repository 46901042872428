import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import {enableCard} from '../../../store/cards';
import Button from '../../Common/Button';

function QuickTourInfo(props) {
	const {t} = useTranslation();
	const dispatch = useDispatch();

	const successMessageKey = useSelector((state) => state.cards.successCardProps.successKey);

	const closeCard = () => {
		window.localStorage.setItem('experienceTourShown', true);
		dispatch(enableCard(false));
	};

	return (
		<div className={'card'}>
			<div className={'close-card'}>
				<span className="close" onClick={closeCard}>
					&times;
				</span>
			</div>
			<div className="confirmation-wrapper">
				<p className="success-text header">{t(successMessageKey)}</p>
				<div className={'buttons-wrapper'}>
					<Button variant={'primary'} height={'regular'} width={'full'} onClick={closeCard} translationKey={'common.okButton'} />
				</div>
			</div>
		</div>
	);
}

export default QuickTourInfo;
