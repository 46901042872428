import {useTranslation} from 'react-i18next';

function EventCountdown({timeLeft, title}) {
	const {t} = useTranslation();
	return (timeLeft.days > 0 || timeLeft.hours > 0 || timeLeft.minutes > 0) && (
		<div className="event-countdown-wrapper">
			{title}
			<div className="countdown-container">
				<div className="box">
					<p className="time-left">{timeLeft.days < 10 ? `0${timeLeft.days}` : timeLeft.days}</p> <p>{t('common.days')}</p>
				</div>
				<p className="dots-divider">:</p>
				<div className="box">
					<p className="time-left">{timeLeft.hours < 10 ? `0${timeLeft.hours}` : timeLeft.hours}</p>
					<p>{t('common.hours')}</p>
				</div>
				<p className="dots-divider">:</p>
				<div className="box">
					<p className="time-left">{timeLeft.minutes < 10 ? `0${timeLeft.minutes}` : timeLeft.minutes}</p>
					<p>{t('common.minutes')}</p>
				</div>
			</div>
		</div>
	);
}

export default EventCountdown
