import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {ROUTES} from '../../../constants/routes';
import {DateTime} from 'luxon';
import {enableBottomMenu, updateActionSheet, updateActionSheetData} from '../../../store/auth';
import Button from '../../Common/Button';
import Icon from '../../Common/Icon';
import {ACTIONSHEET} from '../../../constants/actionSheets';
import {
	addBoothsForSelectedEvent,
	checkIfUserIsCheckedIntoEvent,
	loadEventBanner,
	loadEventForms,
	loadEventSessionsOfUser,
	setProfileCompleted,
} from '../../../store/events';
import infoImage from '../../../image/eventSupportImage.svg';
import FormattedTime from '../../Common/DateTimePicker/FormattedTime';
import {ClipLoader} from 'react-spinners';
import {updateEventBadges} from '../../../store/badges';
import CGIEventsHeader from '../../Common/Header/CGIEventsHeader';
import {Typography} from '../../Common/Typography';
import FormattedDate from '../../Common/DateTimePicker/FormattedDate';
import CheckinIntoEvent from './CheckIntoTheEvent';
import EventHeaderContent from './EventHeaderContent';
import {loadBroadcastNotification} from '../../../store/cards';
import ProfileItem from '../../ProfilePage/ProfileItem';
import {getPropertyTranslation} from '../EventSupport';
import colorBasedOnTheme from '../../../functions/colorBasedOnTheme';
import {getFormPropertyTranslation, saveFormDataToLocalStorage} from '../../EventForm';

export function getSessionLabel(startTime, endTime) {
	const now = DateTime.now().toUTC();
	const sessionStart = DateTime.fromISO(startTime).toUTC();
	const sessionEnd = DateTime.fromISO(endTime).toUTC();
	const sessionLabels = {
		live: {
			type: 'live',
			translationKey: 'event.sessionLabels.live',
		},
		next: {
			type: 'next',
			translationKey: 'event.sessionLabels.next',
		},
	};

	if (sessionEnd > now && sessionStart < now) {
		return sessionLabels.live;
	}

	if (now > sessionStart) {
		return;
	}

	if (now.plus({minutes: 60}) >= sessionStart) {
		return sessionLabels.next;
	}
	return;
}

export function getSessionTitle(session, language) {
	const displayFrench = language === 'fr';
	const sessionTitle = displayFrench && session?.name?.fr ? session?.name?.fr : session?.name?.en ? session?.name?.en : session?.title;

	return sessionTitle;
}

function EventPage() {
	const dispatch = useDispatch();
	const history = useHistory();
	const {t} = useTranslation();
	const event = useSelector((state) => state.events.selectedEvent);
	const isUserCheckedIntoEvent = useSelector((state) => state.events.userCheckedIntoEvent);
	const selectedOffice = useSelector((state) => state.auth.data.selectedOffice);
	const timezone = selectedOffice?.timezone;
	const eventLaunched = event?.biro?.enabled;
	const attendeeData = useSelector((state) => state.events.attendeeData);
	const eventOver = event?.biro?.promptPostEventFeedback;
	const switchingOfficeLoader = useSelector((state) => state.auth.ui.switchingOfficeLoader);
	const loadingEventData = useSelector((state) => state.events.loadingData);
	const loading = switchingOfficeLoader || loadingEventData;
	const showEventLeaderboard = event?.leaderboard?.enabled;
	const showEventAchievements = event?.achievements ? Object.values(event?.achievements).some((achievement) => achievement.enabled) : false;
	const now = DateTime.now().setZone(timezone);
	const startDateEvent = DateTime.fromISO(event?.start).setZone(timezone);
	const eventForms = useSelector((state) => state.events.forms);
	const registrationForm = eventForms?.find((form) => form._id === event?.options?.registrationForm);
	const profileCompleted = useSelector((state) => state.events.profileCompleted);
	const difference = startDateEvent.diff(now, ['days', 'hours', 'minutes']);
	const [eventBanner, setEventBanner] = useState(null);
	const [timeLeft, setTimeLeft] = useState({
		days: difference?.values?.days,
		hours: difference?.values?.hours,
		minutes: Math.floor(difference?.values?.minutes),
	});
	const [lastSubmission, setLastSubmission] = useState();
	const intervalRef = useRef(); // Add a ref to store

	useEffect(() => {
		dispatch(loadBroadcastNotification());

		intervalRef.current = setInterval(() => {
			const difference = startDateEvent.diff(DateTime.now().setZone(timezone), ['days', 'hours', 'minutes']);
			setTimeLeft({
				days: difference?.values?.days,
				hours: difference?.values?.hours,
				minutes: Math.floor(difference?.values?.minutes),
			});
		}, 60000);
		return () => clearInterval(intervalRef.current);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Add a listener to `timeLeft`
	useEffect(() => {
		if (timeLeft.days <= 0 && timeLeft.hours <= 0 && timeLeft.minutes <= 0) {
			clearInterval(intervalRef.current);
		}
	}, [timeLeft]);
	useEffect(async () => {
		if (!event) history.push(ROUTES.DEFAULT);
		if (attendeeData?._id) {
			dispatch(updateEventBadges(attendeeData?._id));
		}
		dispatch(checkIfUserIsCheckedIntoEvent(event));
		dispatch(loadEventSessionsOfUser(event?._id));
		dispatch(loadEventForms(event?._id));
		const eventBanner = await dispatch(loadEventBanner(event?._id));
		setEventBanner(`url(${eventBanner})`);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if ((eventLaunched && isUserCheckedIntoEvent) || attendeeData?.checkInsThroughApp?.enabled) {
			dispatch(enableBottomMenu(true));
		} else {
			dispatch(enableBottomMenu(false));
		}
	}, [isUserCheckedIntoEvent, attendeeData]);

	useEffect(async () => {
		const lastSubmission = await dispatch(setProfileCompleted());
		setLastSubmission(lastSubmission);
	}, [eventForms]);

	function navigateToRegistrationForm() {
		if (lastSubmission) {
			saveFormDataToLocalStorage(registrationForm, lastSubmission?.values);
			history.push(ROUTES.FORM, {eventForm: registrationForm, editSubmissionId: lastSubmission._id});
		} else {
			history.push(ROUTES.FORM, {eventForm: registrationForm});
		}
	}

	function getContent() {
		if (!eventLaunched) return;
		if (eventOver) {
			return <EventOverFeedback eventFeedbackFormUrl={event?.biro?.feedbackFormUrl} />;
		}
		if (loading) {
			return (
				<div className="loading-wrapper">
					<ClipLoader size={50} />
				</div>
			);
		}
		if (!isUserCheckedIntoEvent) {
			return <CheckinIntoEvent eventId={event?._id} />;
		}

		return (
			<PersonalAgendaList
				showEventAchievements={showEventAchievements}
				showEventLeaderboard={showEventLeaderboard}
				ssid={event?.wifi?.ssid}
				password={event?.wifi?.password}
			/>
		);
	}
	return (
		<>
			<CGIEventsHeader />
			<div className="event-page">
				{profileCompleted === false && (
					<div className="event-page-warning-wrapper" onClick={navigateToRegistrationForm}>
						<div className="icon-text">
							<Icon iconName="exclamation-circle" cssClass={'warning-icon'} />
							<Typography>{t('event.completeRegistration')}</Typography>
						</div>
						<Icon iconName="fa-chevron-right" cssClass="icon-gray warning-chevron" />
					</div>
				)}
				<div className={'event-page-header ' + (!eventLaunched && 'event-page-header-full')} style={{'background-image': eventBanner}}>
					<EventHeaderContent timeLeft={timeLeft} title={event?.name} eventForm={registrationForm} />
				</div>
				{getContent()}
			</div>
		</>
	);
}
function PersonalAgendaList({showEventAchievements, showEventLeaderboard, ssid, password}) {
	const history = useHistory();
	const {t} = useTranslation();
	const sessions = useSelector((state) => state.events.sessions);
	const eventInvitations = useSelector((state) => state.events.eventInvitations);
	const userData = useSelector((state) => state.auth.data.userData);
	const dispatch = useDispatch();
	let sortedSessions = sessions?.slice();
	sortedSessions = sortedSessions?.filter((s) => DateTime.fromISO(s.end).plus({minutes: 15}) > DateTime.now().toUTC());
	sortedSessions = sortedSessions?.sort((a, b) => DateTime.fromISO(a?.start) - DateTime.fromISO(b?.start));
	const colors = colorBasedOnTheme();
	const event = useSelector((state) => state.events.selectedEvent);
	const qrEnabled = event?.options?.qrCode?.enabled;
	const floorplanEnabled = event?.options?.floorplan?.enabled;
	const helpfulLinks = event?.options?.appLinks?.filter((link) => link.type === 'helpful');
	const eventForms = useSelector((state) => state.events.forms);
	const eventPageEventForms = eventForms?.filter(
		(form) => form._id !== event?.options?.registrationForm && form._id !== event?.options?.declineForm,
	);

	function redirectToEventAttendees() {
		history.push(ROUTES.EVENT_ATTENDEES);
	}

	function redirectToMapView() {
		dispatch(addBoothsForSelectedEvent(event?._id));
		history.push(ROUTES.MAP_VIEW);
	}

	function redirectToDJ() {
		history.push(ROUTES.INVITATIONS);
	}

	function redirectToConferenceBadge() {
		history.push(ROUTES.PROFILE_PAGE + ROUTES.BADGE);
	}

	function redirectToForm(eventForm) {
		if (eventForm.submitted) {
			history.push(ROUTES.FORM_DETAILS, {eventForm});
		} else {
			history.push(ROUTES.FORM, {eventForm});
		}
	}

	function getSessionItems() {
		if (!sortedSessions?.length) {
			return (
				<div className="no-sessions">
					<p>{t('event.noSessions')}</p>
				</div>
			);
		}

		const sessionsToShow = sortedSessions.slice(0, 3);
		const sessionItems = [];
		const now = DateTime.now();
		for (let index = sessionsToShow.length - 1; index >= 0; index--) {
			const session = sessionsToShow[index];
			if (session) {
				const sessionStartTime = DateTime.fromISO(session?.start);

				sessionItems.push(
					<PersonalAgendaItem
						key={session?.id}
						session={session}
						location={session?.wengeBooth?.name}
						startTime={session.start}
						endTime={session.end}
						eventId={event?._id}
					/>,
				);
				if (sessionStartTime.day !== DateTime.fromISO(sessionsToShow[index - 1]?.start)?.day) {
					if (sessionStartTime.day === now.day) {
						sessionItems.push(<p className="start-date">{t('common.today')}</p>);
					} else if (sessionStartTime.day === now.day + 1) {
						sessionItems.push(<p className="start-date">{t('common.tomorrow')}</p>);
					} else {
						sessionItems.push(
							<p className="start-date">
								<FormattedDate date={session.start} />
							</p>,
						);
					}
				} else if (index === 0 && sessionStartTime.day !== DateTime.fromISO(sessionsToShow[index + 1]?.start)?.day) {
					if (sessionStartTime.day === now.day) {
						sessionItems.push(<p className="start-date">{t('common.today')}</p>);
					} else if (sessionStartTime.day === now.day + 1) {
						sessionItems.push(<p className="start-date">{t('common.tomorrow')}</p>);
					} else {
						sessionItems.push(
							<p className="start-date">
								<FormattedDate date={session.start} />
							</p>,
						);
					}
				}
			}
		}

		return sessionItems.reverse();
	}
	return (
		<div className="event-page-content">
			<div className="event-page-content-headerContainer">
				<Typography variant={'title-large'} className="event-page-content-header">
					{t('event.upcoming')}
				</Typography>
				<div className="btn-wrapper">
					<Button
						cssClass={'show-all'}
						variant={'tertiary'}
						height={'regular'}
						width={'variable'}
						onClick={() => history.push(ROUTES.EVENT_AGENDA)}
						translationKey={'common.seeAll'}
						iconName={'fas fa-angles-right'}
						iconPlacement={'right'}
					/>
				</div>
			</div>
			<div className="session-items">{getSessionItems()}</div>
			<div className="event-page-content-other-cards">
				{floorplanEnabled && (
					<>
						<Typography variant={'title-medium'} className="event-page-content-header">
							{t('common.areas')}
						</Typography>
						<div className="card-list">
							<ProfileItem
								heading={'event.floorplan'}
								iconName="floorPlan"
								iconColor={colors.colorMainTheme}
								onClick={redirectToMapView}
							/>
						</div>
					</>
				)}
				<Typography variant={'title-medium'} className="event-page-content-header">
					{t('event.connections')}
				</Typography>
				<div className="card-list">
					{qrEnabled && (
						<ProfileItem
							heading={'event.qrCode'}
							iconName="qrCode"
							iconColor={colors.colorMainTheme}
							onClick={redirectToConferenceBadge}
						/>
					)}
					<ProfileItem
						heading={'event.attendees'}
						iconName="contactList"
						iconColor={colors.colorMainTheme}
						onClick={redirectToEventAttendees}
					/>
					{eventInvitations?.length > 1 && (
						<ProfileItem
							heading={'eventInvitations.invitation'}
							iconName="far fa-envelope"
							iconColor={colors.colorMainTheme}
							onClick={redirectToDJ}
						/>
					)}
					{eventPageEventForms?.length > 0 &&
						eventPageEventForms.map((form) => {
							const title = getFormPropertyTranslation(form.title, userData.language);
							return (
								<ProfileItem heading={title} iconName="form" iconColor={colors.colorMainTheme} onClick={() => redirectToForm(form)} />
							);
						})}
				</div>
				{helpfulLinks?.length > 0 && (
					<>
						<Typography variant={'title-medium'} className="event-page-content-header">
							{t('event.helpfulLinks')}
						</Typography>
						<div className="card-list-horizontal">
							{helpfulLinks?.map((helpfulLink) => {
								const title = getPropertyTranslation(helpfulLink?.title, userData?.language);
								const link = getPropertyTranslation(helpfulLink?.link, userData?.language);
								const icon = helpfulLink?.icon;
								return (
									<a className="card-list-horizontal-item" href={link} target="_blank">
										<div className="icon-wrapper">
											<Icon cssClass="icon-white" iconName={icon} iconColor="white" />
										</div>
										<Typography variant={'title-medium'}>{title}</Typography>
									</a>
								);
							})}
						</div>
					</>
				)}
			</div>
		</div>
	);
}

function PersonalAgendaItem({session, location, startTime, endTime, eventId}) {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const label = getSessionLabel(startTime, endTime);
	const userData = useSelector((state) => state.auth.data.userData);
	const sessionTitle = getSessionTitle(session, userData?.language);

	function openToastMenu() {
		const attendeeObj = session?.attendees[0];
		const userCheckedIn = attendeeObj?.attendedAt;
		const userAlreadyRated = session?.feedback;
		dispatch(updateActionSheet(ACTIONSHEET.SESSION_MENU));
		dispatch(updateActionSheetData({...session, wengeEvent: eventId, userCheckedIn, userAlreadyRated}));
	}
	return (
		<div className={`personal-agenda-list-item ${label?.type === 'live' ? 'personal-agenda-list-item-live' : ''}`} onClick={openToastMenu}>
			<p className="time">
				<FormattedTime time={startTime} />
			</p>
			<p className="name">{sessionTitle}</p>
			{label?.type ? (
				<div className={`session-status ${label?.type}`}>
					<Typography variant="body-large" className={`session-status-label`}>
						{t(label?.translationKey)}
					</Typography>
				</div>
			) : (
				<div />
			)}

			<p className="location">{location}</p>
		</div>
	);
}

function EventOverFeedback({eventFeedbackFormUrl}) {
	const {t} = useTranslation();
	return (
		<div className="event-page-info">
			<div className="image-wrapper">
				<img alt="info" src={infoImage} />
			</div>
			<div className="event-info">
				<p>{t('event.eventOver')}</p>
				<p>{t('event.provideFeedback')}</p>
			</div>
			<Button
				cssClass={'show-all'}
				variant={'primary'}
				height={'regular'}
				width={'variable'}
				onClick={() => window.open(eventFeedbackFormUrl, '_blank')}
				translationKey={'event.feedback'}
			/>
		</div>
	);
}

export default EventPage;
