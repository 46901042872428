import {useSelector} from 'react-redux';
import {DateTime} from 'luxon';

/**
 * Component that retrieves a formatted date time string used in the app. It holds functionality for 12/24 hours format. It changes day names based on user language
 *
 * @param {iso} time = date time in iso format
 * @param {string} timezone = timezone of the shown date time string
 * @returns
 */
function FormattedDateTime({dateTime, timezone, format}) {
	const selectedOffice = useSelector((state) => state.auth.data.selectedOffice);
	const userData = useSelector((state) => state.auth.data.userData);
	const locale = userData.language === 'fr' ? 'fr-FR' : userData.language === 'en' ? 'en-US' : 'de-DE';
	const formattedDateTime = DateTime.fromISO(dateTime)
		.setZone(timezone ?? selectedOffice?.timezone)
		.setLocale(locale)
		.toLocaleString(format ?? DateTime.DATETIME_MED);

	return formattedDateTime;
}

export default FormattedDateTime;
