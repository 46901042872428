
import {enableCard} from '../../../store/cards';
import {useDispatch} from 'react-redux';

function CardPlaceholder(props) {
	const dispatch = useDispatch();

	const closeCard = () => {
		dispatch(enableCard(false));
	};

	return (
		<div className={'card'}>
			<div className={'close-card'}>
				<span className="close" onClick={closeCard}>
					&times;
				</span>
			</div>
			<div className={'card-content'}>
				<div className={'placeholder-container'}>
					<div className={'ph-item'}>
						<div className={'ph-row'}>
							<div className={'ph-col-12'}></div>
						</div>
						<div className={'ph-row ph-section'}>
							<div className={'ph-col-12'}></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CardPlaceholder;
