import {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {loadAnimation} from 'lottie-web';
import {enableCard} from '../../../store/cards';
import Button from '../../Common/Button';

function UnlockNukiCard(props) {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const lottieRef = useRef();

	const closeCard = () => {
		dispatch(enableCard(false));
	};

	useEffect(() => {
		const instance = loadAnimation({
			container: lottieRef.current, // the dom element that will contain the animation
			renderer: 'svg',
			loop: false,
			autoplay: true,
			animationData: require('../../../image/unlockedDoor.json'),
		});

		return () => instance.destroy();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={'card'}>
			<div className={'close-card'}>
				<span className="close" onClick={closeCard}>
					&times;
				</span>
			</div>
			<div className="confirmation-wrapper">
				<p className="success-text header">{t('qrScanner.doorUnlocked')}</p>
				<div ref={lottieRef} />
				<div className={'buttons-wrapper'}>
					<Button variant={'primary'} height={'regular'} width={'full'} onClick={closeCard} translationKey={'common.okButton'} />
				</div>
			</div>
		</div>
	);
}

export default UnlockNukiCard;
