
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Icon from '../../Common/Icon';
import {enableCard} from '../../../store/cards';
import {Typography} from '../../Common/Typography';
import {useEffect, useState} from 'react';

function SuccessCard(props) {
	const {t} = useTranslation();
	const dispatch = useDispatch();

	const successMessageKey = useSelector((state) => state.cards.successCardProps.successKey);
	const successText = useSelector((state) => state.cards.successCardProps.successText);
	const iconName = useSelector((state) => state.cards.successCardProps.iconName);
	const iconColor = useSelector((state) => state.cards.successCardProps.iconColor);
	const icon = <Icon cssClass={iconColor ? iconColor : 'icon-white'} iconName={iconName ? iconName : 'check-circle'} />;

	//hidden state for animation when closing;
	const [hidden, setHidden] = useState(false);

	const closeCard = () => {
		if (hidden)
			return;

		setHidden(true);
		setTimeout(() => {
			dispatch(enableCard(false));
		}, 1000)
	};

	useEffect(() => {
		//close card after 4 seconds
		setTimeout(() => {
			closeCard();
		}, 4000);
	}, [])

	return (
		<div className={"feedback-banner " + (hidden ? 'hidden' : '')}>
			<div className='feedback-banner-content'>
				<div className="icon-circle">
					{icon}
				</div>
				<div className="info">
					<Typography variant={'title-medium'} className={'bold'}>{t(successMessageKey)}</Typography>
					<Typography variant={'title-small'}>{t(successText)}</Typography>
				</div>
			</div>
			<div className="icon-close" onClick={closeCard}>
				<Icon iconName={'close'} iconColor={'icon-black'} />
			</div>
		</div >
	);
}

export default SuccessCard;
