import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import Icon from '../../Common/Icon';
import {enableCard} from '../../../store/cards';
import Button from '../../Common/Button';

function BoothAlreadyScannedCard(props) {
	const {t} = useTranslation();
	const dispatch = useDispatch();

	const closeCard = () => {
		dispatch(enableCard(false));
	};

	return (
		<div className={'card'}>
			<div className={'close-card'}>
				<span className={'close'} onClick={closeCard}>
					&times;
				</span>
			</div>
			<div className="confirmation-data-privacy-icon-wrapper">
				<Icon cssClass={'icon-yellow'} iconName={'fa-triangle-exclamation'} />
			</div>
			<div className={'confirmation-wrapper'}>
				<p className={'error-text body'}>{t('qrScanner.boothAlreadyScanned')}</p>
				<div className={'buttons-wrapper'}>
					<Button variant={'primary'} height={'regular'} width={'full'} onClick={closeCard} translationKey={'common.okay'} />
				</div>
			</div>
		</div>
	);
}

export default BoothAlreadyScannedCard;
