export const CARD = {
	CHECKIN: 'checkInCard',
	REPORT_PROBLEM: 'reportProblem',
	PLACEHOLDER: 'cardPlaceholder',
	MAIN_SUCCESS: 'mainSuccess',
	MAIN_ERROR: 'mainError',
	MEMBER_BADGE: 'memberBadge',
	MEMBER_BADGE_PLACEHOLDER: 'memberBadgePlaceholder',
	USER_REVIEW: 'userReview',
	RECURRING_BOOKING_SETTINGS: 'recurringBookingSettings',
	CONFIRM_RECURRING_CONFLICTS: 'confirmRecurringConflicts',
	QUICK_TOUR_INFO: 'quickTourInfo',
	CEYLON_INFO: 'ceylonInfo',
	CEYLON_PROCESS: 'ceylonProcess',
	CEYLON_FAILED_VALIDATION: 'ceylonFailedValidation',
	CONFIRM_CHECKIN: 'confirmCheckin',
	BROADCAST_NOTIFICATION_CARD: 'broadcastNotificationCard',
	NOT_FOUND_CARD: 'notFoundCard',
	EMERGENCY_CARD: 'emergencyCard',
	PAYMENT_INTENT: 'paymentIntent',
	PAYMENT_REDIRECT: 'paymentRedirect',
	UNLOCK_NUKI: 'unlockNukiCard',
	VIEW_OFFICE_HOURS: 'viewOfficeHours',
	HANDLE_NOT_FITTING_CAPACITY: 'handleNotFittingCapacity',
	CHECKIN_DISABLED: 'checkinDisabled',
	CONFIRM_ACTION: 'confirmAction',
	BOOTH_ALREADY_SCANNED: 'boothAlreadyScanned',
};
