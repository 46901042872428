import {createSlice} from '@reduxjs/toolkit';
import {createSelector} from 'reselect';
import {DateTime} from 'luxon';

import {findBookingsInRange} from '../api';
import {CARD} from '../constants/cards';
import {getBroadcastNotification, getEmergencyNotification} from '../api/offices';
import {changeDisabledDashboardState, updateActionSheet} from './auth';
import {getAspenBroadcastNotifications} from '../api/member';

const slice = createSlice({
	name: 'cards',
	initialState: {
		previousCardToShow: CARD.PLACEHOLDER,
		cardToShow: CARD.PLACEHOLDER,
		showCard: false,
		data: null,
		emergencyNotification: null,
		errorCardProps: {
			header: null,
			body: null,
			iconName: 'exclamation-circle',
			iconColor: 'icon-red',
			translationBtnKey: 'common.okButton',
			statusCode: null,
			name: null,
			traceKey: null,
		},
		successCardProps: {
			iconName: 'check-circle',
			iconColor: 'icon-green',
			successKey: null,
			successText: null,
		},
	},
	reducers: {
		newCardToShowReceived: (cards, action) => {
			cards.previousCardToShow = cards.cardToShow;
			cards.cardToShow = action.payload;
		},
		dataReceived: (cards, action) => {
			cards.data = action.payload;
		},
		bookingReceived: (cards, action) => {
			cards.data = action.payload;
		},
		enableCardReceived: (cards, action) => {
			cards.showCard = action.payload;
		},
		errorDataReceived: (cards, action) => {
			cards.errorCardProps.statusCode = action.payload.status;
			cards.errorCardProps.name = action.payload.name;
			cards.errorCardProps.traceKey = action.payload.traceKey;
			cards.errorCardProps.header = action.payload.headerKey;
		},
		successDataReceived: (cards, action) => {
			cards.successCardProps.iconName = action.payload.iconName;
			cards.successCardProps.iconColor = action.payload.iconColor;
			cards.successCardProps.successKey = action.payload.successKey;
			cards.successCardProps.successText = action.payload.successText;
		},
		emergencyNotificationReceived: (cards, action) => {
			cards.emergencyNotification = action.payload;
		},
	},
});

export const {
	newCardToShowReceived,
	enableCardReceived,
	dataReceived,
	bookingReceived,
	errorDataReceived,
	successDataReceived,
	emergencyNotificationReceived,
} = slice.actions;

export default slice.reducer;

export const addDataToCard = (data) => (dispatch, getState) => {
	dispatch({type: dataReceived.type, payload: data});
};

export const updateCard = (card) => (dispatch, getState) => {
	dispatch({type: newCardToShowReceived.type, payload: card});
};

export const updateCardAndData = (card, data) => (dispatch, getState) => {
	const enabledCard = getState().cards.showCard;
	if (!enabledCard) dispatch({type: enableCardReceived.type, payload: true});
	dispatch({type: dataReceived.type, payload: data});
	dispatch({type: newCardToShowReceived.type, payload: card});
};

export const updateSuccessCard = (card, successKey, successText, iconName, iconColor) => (dispatch, getState) => {
	dispatch({type: newCardToShowReceived.type, payload: card});

	dispatch({
		type: successDataReceived.type,
		payload: {
			iconName,
			iconColor,
			successKey,
			successText,
		},
	});
};

export const updateErrorCard = (card, headerKey, error) => (dispatch, getState) => {
	dispatch({type: newCardToShowReceived.type, payload: card});

	dispatch({
		type: errorDataReceived.type,
		payload: {
			headerKey: headerKey ? headerKey : 'errorMessages.general',
			status: error?.response?.data?.httpStatus ? error?.response?.data?.httpStatus : null,
			name: error?.response?.data?.errorName ? error?.response?.data?.errorName : error?.response?.data?.name,
			traceKey: error?.response?.data?.traceKey ? error?.response?.data?.traceKey : null,
		},
	});
};

//translationKeys = {headerKey, infoKey, confirmButtonKey}
export const updateConfirmationCard = (confirmAction, translationKeys, data) => (dispatch, getState) => {
	const isCardEnabled = getState().cards.showCard;

	console.log(confirmAction);
	if (!isCardEnabled) dispatch({type: enableCardReceived.type, payload: true});
	dispatch({type: newCardToShowReceived.type, payload: CARD.CONFIRM_ACTION});
	dispatch({type: dataReceived.type, payload: {...data, ...translationKeys, confirmAction}});
};

export const enableCard = (show) => (dispatch, getState) => {
	if (!show) dispatch({type: newCardToShowReceived.type, payload: CARD.PLACEHOLDER});
	if (show && getState().auth.ui.actionSheetOpen) dispatch(updateActionSheet(null));
	dispatch({type: enableCardReceived.type, payload: show});
};

export const loadBooking = (booking, card) => async (dispatch, getState) => {
	dispatch({type: enableCardReceived.type, payload: true});

	dispatch({type: newCardToShowReceived.type, payload: CARD.PLACEHOLDER});

	try {
		dispatch({type: newCardToShowReceived.type, payload: CARD.PLACEHOLDER});
		let data = {};

		const bookingsForEntityNext24hoursResponse = await findBookingsInRange(
			DateTime.fromISO(booking.start.time).toUTC().toISO(),
			DateTime.fromISO(booking.start.time).plus({days: 1}).toUTC().toISO(),
			{
				teakEntity: booking.teakEntity._id,
			},
		);
		const bookingsForEntityNext24hours = bookingsForEntityNext24hoursResponse.data;

		bookingsForEntityNext24hours.sort((a, b) => {
			return DateTime.fromISO(a.start.time) - DateTime.fromISO(b.start.time);
		});

		const bookingsForEntityNext24hoursFiltered = bookingsForEntityNext24hours.filter((reservation) => reservation._id !== booking._id);

		data.entity = {...booking.teakEntity};
		if (bookingsForEntityNext24hoursFiltered?.length > 0) {
			data.entity.nextReservation = bookingsForEntityNext24hoursFiltered[0];
		}
		data.booking = booking;
		dispatch({type: bookingReceived.type, payload: data});
	} catch (error) {
		console.log(error);
		dispatch({
			type: errorDataReceived.type,
			payload: {
				status: error?.response?.data?.httpStatus ? error.response.data.httpStatus : null,
				name: error?.response?.data?.name ? error?.response?.data?.name : null,
				traceKey: error?.response?.data?.traceKey ? error?.response?.data?.traceKey : null,
			},
		});
	}

	dispatch({type: newCardToShowReceived.type, payload: card});
};

export const getAreaNameBasedOnId = (areaId) => {
	return createSelector(
		(state) => state.auth.data,
		(authData) => {
			const areas = authData.areas;
			const areaNameBasedOnId = areas.find((area) => area._id === areaId);
			return areaNameBasedOnId?.name;
		},
	);
};

export const showMemberCard = (memberData) => async (dispatch, getState) => {
	dispatch({type: dataReceived.type, payload: memberData});
	dispatch({type: newCardToShowReceived.type, payload: CARD.MEMBER_BADGE});
};

export const showConfirmInvitationDelete = (pineVisitId) => async (dispatch, getState) => {
	dispatch({type: enableCardReceived.type, payload: true});
	dispatch({type: newCardToShowReceived.type, payload: CARD.PLACEHOLDER});
	let data = {pineVisitId};
	dispatch({type: dataReceived.type, payload: data});
	dispatch({
		type: newCardToShowReceived.type,
		payload: CARD.CONFIRM_INVITATION_DELETE,
	});
};

export const loadBroadcastNotification = (officeId) => async (dispatch, getState) => {
	try {
		const currentOfficeId = officeId ?? getState().auth.data.selectedOffice._id;
		const broadcastNotificationsData = await getBroadcastNotification(currentOfficeId);
		const notificationsList = broadcastNotificationsData?.data;
		if (notificationsList?.length > 0) {
			dispatch(enableCard(true));
			dispatch(updateCardAndData(CARD.BROADCAST_NOTIFICATION_CARD, notificationsList));
		}
	} catch (error) {
		console.log('error:', error);
	}
};

export const checkEmergency = (officeId) => async (dispatch, getState) => {
	try {
		const currentOfficeId = officeId ?? getState().auth.data.selectedOffice._id;
		const receivedData = await getEmergencyNotification(currentOfficeId);
		const notificationData = receivedData?.data ? receivedData?.data : [];
		const userData = getState().auth.data.userData;
		const member = notificationData.members.find((m) => m.member === userData._id);
		const emergencyNotification = {
			id: notificationData._id,
			cause: notificationData?.cause,
			message: notificationData.office?.oak?.emergencyMessage?.biro,
			response: member?.response,
			latestLocation: member?.latestLocation,
		};
		dispatch(setEmergencyNotification(emergencyNotification));

		dispatch(changeDisabledDashboardState(true));
	} catch (error) {
		if (error.response.status === 404) dispatch(changeDisabledDashboardState(false));
		console.log('error:', error);
	}
};

export const setEmergencyNotification = (emergencyNotification) => async (dispatch, getState) => {
	dispatch({
		type: emergencyNotificationReceived.type,
		payload: emergencyNotification,
	});
};
//selectors
