import axios from 'axios';
import apiUrl from './index';

const source = axios.CancelToken.source();
let config = {cancelToken: source.token};

//INCLUDES API CALLS THAT ARE ADDITIONAL AND DONT NEED A PROPER FILE. IF SOME PATHS ARE EXTENDED WITH ADDITIONAL CALLS, A PROPER FILE FOR THE PATH CAN BE CREATED

export async function getManyTeakTypes(officeId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.teakType + apiUrl.path.findMany;

	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				filters: {
					office: officeId,
				},
			},
		},
		config,
	);
}

export async function getBadgeVerificationCode() {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.badge + apiUrl.path.verificationCode;

	return axios(
		{
			method: 'get',
			url: requestUrl,
		},
		config,
	);
}

export async function validateBadgeVerificationCode(verificationCode, wengeEvent) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.badge + apiUrl.path.validateVerificationCode;

	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {verificationCode, wengeEvent},
		},
		config,
	);
}

export async function checkInByRefCode(verificationCode, wengeEvent, wengeSession, refCode) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.badge + apiUrl.path.verificationCodeCheckIn;

	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {verificationCode, wengeEvent, wengeSession, refCode},
		},
		config,
	);
}

export async function confirmHealthQuestionnaireCompletion() {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const memberId = window.localStorage.getItem('memberID');

	let requestUrl = hostUrl + apiUrl.path.member + '/' + memberId + apiUrl.path.confirmHealthQuestionnaireCompletion;

	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {},
		},
		config,
	);
}

export async function healthQuestionnaireLastCompletion() {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const memberId = window.localStorage.getItem('memberID');

	let requestUrl = hostUrl + apiUrl.path.member + '/' + memberId + apiUrl.path.healthQuestionnaireLastCompletion;

	return axios(
		{
			method: 'get',
			url: requestUrl,
		},
		config,
	);
}

export async function getOrganization(orgId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.organization + '/' + orgId;

	return axios(
		{
			method: 'get',
			url: requestUrl,
		},
		config,
	);
}

export async function getOrganizationLogo(orgId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.organization + '/' + orgId + apiUrl.path.logo + '?variation=400';

	return axios(
		{
			method: 'get',
			url: requestUrl,
			responseType: 'arraybuffer',
		},
		config,
	);
}

export async function submitUserFeedback(rating, feedbackText, email) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.userFeedback;

	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				rating: rating,
				comment: feedbackText,
				email: email,
			},
		},
		config,
	);
}

/**
 *
 * @param {number} rating = rating 1-10
 * @param {string} feedbackText = comments added by the user
 * @param {string} wengeSession = id of session for which the user added feedback
 * @param {string} wengeEvent = id of event where the session is
 * The route posts a feedback to a specific session
 * @returns
 */
export async function submitSessionFeedback(rating, comment, wengeSession, wengeEvent) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.userFeedback;

	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				rating,
				comment,
				wengeSession,
				wengeEvent,
			},
		},
		config,
	);
}

export async function postponeUserFeedback() {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.userFeedback + apiUrl.path.postpone;

	return axios(
		{
			method: 'post',
			url: requestUrl,
		},
		config,
	);
}

export async function registerCertificate(validUntilDate) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.ceylon + apiUrl.path.registerCertificate;

	let validUntil = validUntilDate?.toUTC().toISO();
	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				validUntil,
			},
		},
		config,
	);
}

export async function validateCertificate() {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.ceylon + apiUrl.path.validateCertificate;

	return axios(
		{
			method: 'get',
			url: requestUrl,
		},
		config,
	);
}

export async function completePlusStatus() {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.ceylon + apiUrl.path.completePlusStatus;

	return axios(
		{
			method: 'post',
			url: requestUrl,
		},
		config,
	);
}

export async function getPlusStatus() {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.ceylon + apiUrl.path.getPlusStatus;

	return axios(
		{
			method: 'get',
			url: requestUrl,
		},
		config,
	);
}

/**
 *
 * @param {string} queryString = query string that comes from the strategy callback. Usually has a code that needs to be validated by BE.
 * @param {string} strategy = strategy used to log in
 * @param {string} organization = organization id that comes from the getAuthContext request.
 * @returns
 */
export async function validateAuthCode(queryString, strategy, organization) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.auth + apiUrl.path.strategy + '/' + strategy + '/callback' + queryString + `&organization=${organization}`;

	return axios(
		{
			method: 'get',
			url: requestUrl,
		},
		config,
	);
}

export async function processTeakEntityToFavorites(favoriteTeakEntity) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const memberId = window.localStorage.getItem('memberID');

	let requestUrl = hostUrl + apiUrl.path.member + '/' + memberId + apiUrl.path.favoriteTeakEntity;

	return axios(
		{
			method: 'put',
			url: requestUrl,
			data: {
				favoriteTeakEntity,
			},
		},
		config,
	);
}

/**
 *
 * @param {string} organization = organization id, usually coming from scanning a qr code.
 * @param {string} identityDomain = identity domain of the email added to the login input.
 * The route request either an organization or identityDomain!
 * @returns
 */
export function getAuthContext(email, organization, identityDomain) {
	const spacestationUrl = process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = spacestationUrl + apiUrl.path.auth + apiUrl.path.context;
	if (organization) requestUrl = `${requestUrl}?organization=${organization}`;
	if (identityDomain) requestUrl = `${requestUrl}${organization ? '&' : '?'}identityDomain=${identityDomain}`;

	return axios({
		method: 'post',
		url: requestUrl,
		data: {
			email,
		},
	});
}

export function getLoginUrl(strategy, email) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const loginStrategyBaseURL = hostUrl + apiUrl.path.auth + apiUrl.path.strategy + '/';

	let requestUrl = loginStrategyBaseURL + strategy + apiUrl.path.url + '?loginHint=' + email;

	return axios(
		{
			method: 'get',
			url: requestUrl,
		},
		config,
	);
}

export function loginWithPassword(queryString) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const loginStrategyBaseURL = hostUrl + apiUrl.path.auth + apiUrl.path.strategy + '/passcode' + apiUrl.path.validate + queryString;

	return axios(
		{
			method: 'get',
			url: loginStrategyBaseURL,
		},
		config,
	);
}

export function getLoginStrategies(email) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.auth + apiUrl.path.strategies + '?email=' + email;

	return axios(
		{
			method: 'get',
			url: requestUrl,
		},
		config,
	);
}

export function reportSickStatus(validDate) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const memberId = window.localStorage.getItem('memberID');
	let requestUrl = hostUrl + apiUrl.path.member + '/' + memberId + apiUrl.path.reportSick;

	return axios(
		{
			method: 'put',
			url: requestUrl,
			data: {
				positiveTestDate: validDate,
			},
		},
		config,
	);
}

export function findColleagueFromEmail(email, start, end, office) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const memberId = window.localStorage.getItem('memberID');
	let requestUrl = hostUrl + apiUrl.path.member + '/' + memberId + apiUrl.path.findColleague;

	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				email,
				start,
				end,
				office,
			},
		},
		config,
	);
}

export function findMyColleagueCalendarView(start, end, office) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const memberId = window.localStorage.getItem('memberID');
	const requestUrl = hostUrl + apiUrl.path.member + '/' + memberId + apiUrl.path.findColleagueCalendarView;

	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				start,
				end,
				office,
			},
		},
		config,
	);
}

/**
 *
 * @param {ISO} start = start of range
 * @param {ISO} end = end of range
 * @param {string} office = office id to search in
 * @param {number} skip = number of colleagues to skip, starts with 0 and adds 10
 * @param {number} pageSize = number of colleagues to get
 * @returns -> retrieves other colleagues that have a booking in the specified range
 */
export function findOtherColleaguesCalendarView(start, end, office, skip, pageSize) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const memberId = window.localStorage.getItem('memberID');
	const requestUrl = hostUrl + apiUrl.path.member + '/' + memberId + apiUrl.path.findOtherColleaguesCalendarView;

	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				start,
				end,
				office,
				skip,
				pageSize,
			},
		},
		config,
	);
}

export function getReleaseNotesStatus() {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.releaseNotes;

	return axios(
		{
			method: 'get',
			url: requestUrl,
		},
		config,
	);
}

export function setReleaseNotesStatus(releaseId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.releaseNotes + '/' + releaseId;

	return axios(
		{
			method: 'post',
			url: requestUrl,
		},
		config,
	);
}

export function getCountOfBookings(officeId, start, end) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + '/maples' + apiUrl.path.countInRange + `?start=${start}&end=${end}&office=${officeId}`;

	return axios(
		{
			method: 'get',
			url: requestUrl,
		},
		config,
	);
}

/**
 * API Endpoint to get updated stats
 *
 * @param {number} officeId pass the office ID
 * @returns {array} Returns an array of objects with all teak types with there stats
 * @example
 *	availableTeakEntities<number>
 *  _aggregations: activeTeakEntities<number>, totalTeakEntities<number>, teakBookings: hourly<array>, currentCount<number>
 *  _id<string>
 */
export function getUpdatedStatistics(officeId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + '/maples' + apiUrl.path.updatedStatistics;

	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				filters: {
					office: officeId,
				},
			},
		},
		config,
	);
}

export function loginViaTeams(teamsAccessToken) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const loginTeamsBaseURL = hostUrl + apiUrl.path.auth + apiUrl.path.strategy + apiUrl.path.teamsLogin;

	let requestUrl = loginTeamsBaseURL + '?key=' + teamsAccessToken;

	return axios(
		{
			method: 'get',
			url: requestUrl,
		},
		config,
	);
}

export function getFeatureTranslations() {
	const requestUrl = apiUrl.staticAssetsURL + apiUrl.path.featureNamesTranslations;

	return axios({
		method: 'get',
		url: requestUrl,
		withCredentials: false,
		responseType: 'json',
	});
}

export async function unlockNukiLock(id) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.nukiLock(id);

	return axios({
		method: 'post',
		url: requestUrl,
	});
}
/**
 *
 * @param {string} errorMessage = error message to be added to the logs in gcp
 * @returns -> Request which adds the error message to the server. Used when ErrorBoundary is mounted.
 */
export async function addErrorToServer(errorMessage) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = `${hostUrl}/maples/clientError/addClientError`;

	return axios({
		method: 'post',
		url: requestUrl,
		data: {
			errorMessage,
		},
	});
}
