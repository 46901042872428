import {useTranslation} from 'react-i18next';

import {useSelector} from 'react-redux';
import colorBasedOnTheme from '../../functions/colorBasedOnTheme';
import {ClipLoader} from 'react-spinners';
import CGIEventsHeader from './Header/CGIEventsHeader';

function LoadingScreen(props) {
	const {t} = useTranslation();
	const colors = colorBasedOnTheme();
	const majorErrorMessage = useSelector((state) => state.auth.ui.majorErrorMessage);

	return (
		<div className={'loading-screen-page'}>
			<CGIEventsHeader />
			<div className="loading-logo-wrapper">
				{majorErrorMessage ? (
					<>
						<p>{t(majorErrorMessage)}</p>
						<p>{t('errorMessages.general')}</p>
					</>
				) : (
					<>
						<ClipLoader width={150} size={100} color={colors.colorMainTheme} loading={true} speedMultiplier={1} />
					</>
				)}
			</div>
			<Copyright />
		</div>
	);
}

export const Copyright = ({additionalElement}) => {
	const {t} = useTranslation();
	const year = new Date().getFullYear();
	return (
		<div className="copyright-section">
			{additionalElement}
			<p>
				{t('loginPage.infoText.intro.copyright.symbol')} {year} {t('loginPage.infoText.intro.copyright.text')}
			</p>
		</div>
	);
};

export default LoadingScreen;
