import {t} from 'i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {ROUTES} from '../../constants/routes';
import {updateConfirmationCard} from '../../store/cards';
import {confirmActionsMap} from '../Cards/Common/ConfirmAction/confirmActions';

const AgendaItemMenu = (props) => {
	const dispatch = useDispatch();
	const data = useSelector((state) => state.auth.data.actionSheetData);
	const history = useHistory();

	const redirectToEditItem = (e) => {
		e.stopPropagation();
		props.closeActionSheet();
		history.push(ROUTES.PROFILE_PAGE + ROUTES.ADD_AGENDA_ITEM, {agendaItem: data.agendaItem});
	};

	const cancelItem = (e) => {
		e.stopPropagation();
		props.closeActionSheet();
		dispatch(
			updateConfirmationCard(
				confirmActionsMap.DELETE_AGENDA_ITEM,
				{
					headerKey: 'agenda.deleteItemHeader',
					infoKey: 'agenda.deleteItemInfo',
				},
				data,
			),
		);
	};
	return (
		<>
			<div className="action-sheet-option" onClick={redirectToEditItem}>
				{t('agenda.editAgendaItem')}
			</div>
			<div className="action-sheet-option" onClick={cancelItem}>
				{t('agenda.deleteAgendaItem')}
			</div>
		</>
	);
};

export default AgendaItemMenu;
