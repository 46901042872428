import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import {lazy} from 'react';
import {ROUTES} from '../constants/routes';
import App from '../App';
import LoginPage from '../components/LoginPage';
import {useDispatch} from 'react-redux';
import {startBackgroundTasks} from '../functions/backgroundTasks';
import OrganizationSwitcher from '../components/OrganizationSwitcher';

function AppRoutes() {
	function lazyRetry(componentImport) {
		return new Promise((resolve, reject) => {
			// check if the window has already been refreshed
			const hasRefreshed = JSON.parse(window.sessionStorage.getItem('retry-lazy-refreshed') || 'false');
			//try to import the component
			componentImport()
				.then((component) => {
					window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
					resolve(component);
				})
				.catch((error) => {
					if (!hasRefreshed) {
						// not been refreshed yet
						window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
						return window.location.reload(); // refresh the page
					}
					reject(error);
				});
		});
	}
	const QRScanner = lazy(() => lazyRetry(() => import('../components/QRScanner')));
	const ProfilePage = lazy(() => lazyRetry(() => import('../components/ProfilePage')));
	const BadgePage = lazy(() => lazyRetry(() => import('../components/ProfilePage/BadgePage')));
	const ReportProblem = lazy(() => lazyRetry(() => import('../components/ProfilePage/ReportProblem')));
	const ColleagueList = lazy(() => lazyRetry(() => import('../components/ProfilePage/ColleagueList/ColleagueList')));
	const MapViewPage = lazy(() => lazyRetry(() => import('../components/MapView/MapViewPage')));
	const VerifyOTPPage = lazy(() => lazyRetry(() => import('../components/VerifyOTP/VerifyOTP')));
	const ProfileCompletion = lazy(() => lazyRetry(() => import('../components/VerifyOTP/ProfileCompletion')));
	const ErrorTeamsLogin = lazy(() => lazyRetry(() => import('../components/ErrorPage/ErrorTeamsLogin')));
	const EventSupport = lazy(() => lazyRetry(() => import('../components/Event/EventSupport')));
	const EventAgenda = lazy(() => lazyRetry(() => import('../components/Event/EventAgenda')));
	const EventIntro = lazy(() => lazyRetry(() => import('../components/Event/EventIntro')));
	const EventPage = lazy(() => lazyRetry(() => import('../components/Event/EventPage/EventPage')));
	const ExternalLink = lazy(() => lazyRetry(() => import('../components/Event/ExternalLink')));
	const SessionDetails = lazy(() => lazyRetry(() => import('../components/Event/Session/SessionDetails')));
	const AskQuestion = lazy(() => lazyRetry(() => import('../components/Event/Session/AskQuestion')));
	const MemberProfile = lazy(() => lazyRetry(() => import('../components/ProfilePage/MemberProfile/MemberProfile')));
	const MemberProfileEdit = lazy(() => lazyRetry(() => import('../components/ProfilePage/MemberProfile/MemberProfileEdit')));
	const EventAttendees = lazy(() => lazyRetry(() => import('../components/Event/EventAttendees/EventAttendees')));
	const BookableSessionPage = lazy(() => lazyRetry(() => import('../components/Event/BookableSessionPage')));
	const LeadershipBoard = lazy(() => lazyRetry(() => import('../components/Event/LeadershipBoard')));
	const OfficeElement = lazy(() => lazyRetry(() => import('../components/OfficeElement/OfficeElement')));
	const EventInvitations = lazy(() => lazyRetry(() => import('../components/EventInvitations')));
	const RegistrationApproved = lazy(() => lazyRetry(() => import('../components/EventInvitations/RegistrationApproved')));
	const RegistrationDeclined = lazy(() => lazyRetry(() => import('../components/EventInvitations/RegistrationDeclined')));
	const RateSession = lazy(() => lazyRetry(() => import('../components/Event/Session/RateSession')));
	const AttendeePage = lazy(() => lazyRetry(() => import('../components/Event/EventAttendees/AttendeePage')));
	const SpeakerPage = lazy(() => lazyRetry(() => import('../components/Event/Session/SpeakerPage')));
	const EventForm = lazy(() => lazyRetry(() => import('../components/EventForm')));
	const EventFormDetails = lazy(() => lazyRetry(() => import('../components/EventForm/EventFormDetails')));

	const dispatch = useDispatch();
	startBackgroundTasks(dispatch);

	return (
		<Router>
			<Switch>
				<Route
					path={ROUTES.LOGIN}
					render={(props) => (
						<App {...props} pageContent={ROUTES.LOGIN}>
							<LoginPage />
						</App>
					)}
				/>
				<Route
					exact
					path={'/organizationSwitcher'}
					render={(props) => (
						<App {...props} pageContent={'/organizationSwitcher'}>
							<OrganizationSwitcher />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.VERIFY_OTP}
					render={(props) => (
						<App {...props} pageContent={ROUTES.VERIFY_OTP}>
							<VerifyOTPPage />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.PROFILE_COMPLETION}
					render={(props) => (
						<App {...props} pageContent={ROUTES.PROFILE_COMPLETION}>
							<ProfileCompletion />
						</App>
					)}
				/>
				<Route exact path={ROUTES.TEAMS} render={() => <App pageContent={ROUTES.TEAMS} />} />
				<Route
					path={ROUTES.QR_SCANNER}
					render={(props) => (
						<App {...props} pageContent={ROUTES.QR_SCANNER}>
							<QRScanner />
						</App>
					)}
				/>
				<Route
					path={ROUTES.PROFILE_PAGE}
					render={(props) => (
						<App {...props} pageContent={ROUTES.PROFILE_PAGE}>
							<Switch>
								<Route exact path={ROUTES.PROFILE_PAGE}>
									<ProfilePage />
								</Route>

								<Route path={ROUTES.PROFILE_PAGE + ROUTES.BADGE}>
									<BadgePage />
								</Route>
								<Route path={ROUTES.PROFILE_PAGE + ROUTES.COLLEAGUE_LIST}>
									<ColleagueList />
								</Route>
							</Switch>
						</App>
					)}
				/>
				<Route
					path={ROUTES.REPORT_PROBLEM}
					render={(props) => (
						<App {...props} pageContent={ROUTES.REPORT_PROBLEM}>
							<ReportProblem />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.MAP_VIEW}
					render={(props) => (
						<App {...props} pageContent={ROUTES.MAP_VIEW}>
							<MapViewPage />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.EVENT_INTRO}
					render={(props) => (
						<App {...props} pageContent={ROUTES.EVENT_INTRO}>
							<EventIntro />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.INVITATIONS}
					render={(props) => (
						<App {...props} pageContent={ROUTES.INVITATIONS}>
							<EventInvitations />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.REGISTRATION_APPROVED}
					render={(props) => (
						<App {...props} pageContent={ROUTES.REGISTRATION_APPROVED}>
							<RegistrationApproved />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.REGISTRATION_DECLINED}
					render={(props) => (
						<App {...props} pageContent={ROUTES.REGISTRATION_DECLINED}>
							<RegistrationDeclined />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.EVENT}
					render={(props) => (
						<App {...props} pageContent={ROUTES.EVENT}>
							<EventPage />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.SESSION_DETAILS}
					render={(props) => (
						<App {...props} pageContent={ROUTES.SESSION_DETAILS}>
							<SessionDetails />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.ASK_QUESTION}
					render={(props) => (
						<App {...props} pageContent={ROUTES.ASK_QUESTION}>
							<AskQuestion />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.RATE_SESSION}
					render={(props) => (
						<App {...props} pageContent={ROUTES.RATE_SESSION}>
							<RateSession />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.MEMBER_PROFILE}
					render={(props) => (
						<App {...props} pageContent={ROUTES.MEMBER_PROFILE}>
							<MemberProfile />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.EDIT_MEMBER_PROFILE}
					render={(props) => (
						<App {...props} pageContent={ROUTES.EDIT_MEMBER_PROFILE}>
							<MemberProfileEdit />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.EVENT_AGENDA}
					render={(props) => (
						<App {...props} pageContent={ROUTES.EVENT_AGENDA}>
							<EventAgenda />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.EVENT_SUPPORT}
					render={(props) => (
						<App {...props} pageContent={ROUTES.EVENT_SUPPORT}>
							<EventSupport />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.EXTERNAL_LINK}
					render={(props) => (
						<App {...props} pageContent={ROUTES.EXTERNAL_LINK}>
							<ExternalLink />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.EVENT_ATTENDEES}
					render={(props) => (
						<App {...props} pageContent={ROUTES.EVENT_ATTENDEES}>
							<EventAttendees />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.EVENT_ATTENDEE_PAGE}
					render={(props) => (
						<App {...props} pageContent={ROUTES.EVENT_ATTENDEE_PAGE}>
							<AttendeePage />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.SPEAKER_PAGE}
					render={(props) => (
						<App {...props} pageContent={ROUTES.SPEAKER_PAGE}>
							<SpeakerPage />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.FORM}
					render={(props) => (
						<App {...props} pageContent={ROUTES.FORM}>
							<EventForm />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.FORM_DETAILS}
					render={(props) => (
						<App {...props} pageContent={ROUTES.FORM_DETAILS}>
							<EventFormDetails />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.BOOKABLE_SESSION}
					render={(props) => (
						<App {...props} pageContent={ROUTES.BOOKABLE_SESSION}>
							<BookableSessionPage />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.OFFICE_ELEMENT}
					render={(props) => (
						<App {...props} pageContent={ROUTES.OFFICE_ELEMENT}>
							<OfficeElement />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.LEADERSHIP_BOARD}
					render={(props) => (
						<App {...props} pageContent={ROUTES.LEADERSHIP_BOARD}>
							<LeadershipBoard />
						</App>
					)}
				/>
				<Route exact path={ROUTES.ERROR_TEAMS_LOGIN} render={(props) => <ErrorTeamsLogin />} />
				<Redirect from="/" to={ROUTES.INVITATIONS + window.location.search} />
			</Switch>
		</Router>
	);
}

export default AppRoutes;
