import axios from 'axios';

const source = axios.CancelToken.source();
let config = {cancelToken: source.token};

const badgesUrls = {
	badges: '/maples/achievements',
	updateLastNotifiedLevel: '/maples/achievements/updateLastNotifiedLevel',
};

export async function getBadgesData(wengeAttendee) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + badgesUrls.badges;

	return axios(
		{
			method: 'POST',
			url: requestUrl,
			data: {
				wengeAttendee,
			},
		},
		config,
	);
}

export async function updateLastNotifiedLevel(badge, wengeAttendee) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + badgesUrls.updateLastNotifiedLevel;

	return axios(
		{
			method: 'POST',
			url: requestUrl,
			data: {
				wengeAttendee,
				badgeName: badge.name,
				level: badge.level,
			},
		},
		config,
	);
}
