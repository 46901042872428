import {createSlice} from '@reduxjs/toolkit';
import {DateTime} from 'luxon';

import {getMapOutline} from '../../api/index';
import {getListOfAllOfficeElements} from '../../api/offices';
import {CARD} from '../../constants/cards';
import sortAlphabetically from '../../functions/sortAlphabetically';

import {updateAreaMap} from '../auth';
import {dataReceived, enableCardReceived, newCardToShowReceived} from '../cards';

const slice = createSlice({
	name: 'mapView',
	initialState: {
		backgroundImage: 'no-map',
		teakTypeToShow: null,
		nbOfAvailableEntities: 0,
		showStatusForEntities: true,
		mapMenuOpen: false,
		searchValueOfficeElement: null,
		searchPageOpen: false,
		officeElements: null,
		lastOfficeElementsRequest: null,
		officeElementsToHighlight: [],
		officeElementCategoryToHighlight: null,
		bottomCard: null,
		bottomCardData: null,
		highlightBoothData: null,
		ui: {
			mapLoading: false,
			loadingOfficeElements: false,
		},
		errorMessage: null,
	},
	reducers: {
		backgroundImageUpdated: (mapView, action) => {
			mapView.backgroundImage = action.payload;
		},
		mapLoadingReceived: (mapView, action) => {
			mapView.ui.mapLoading = action.payload;
		},
		nbOfAvailableEntitiesChanged: (mapView, action) => {
			mapView.nbOfAvailableEntities = action.payload;
		},
		showStatusForEntitiesChanged: (mapView, action) => {
			mapView.showStatusForEntities = action.payload;
		},
		mapMenuOpenChanged: (mapView, action) => {
			mapView.mapMenuOpen = action.payload;
		},
		searchValueOfficeElementChanged: (mapView, action) => {
			mapView.searchValueOfficeElement = action.payload;
		},
		searchPageOpenChanged: (mapView, action) => {
			mapView.searchPageOpen = action.payload;
		},
		officeElementsChanged: (mapView, action) => {
			mapView.officeElements = action.payload.elements;
			mapView.lastOfficeElementsRequest = action.payload.timestamp;
		},
		officeElementsToHighlightChanged: (mapView, action) => {
			mapView.officeElementsToHighlight = action.payload;
		},
		officeElementCategoryToHighlightChanged: (mapView, action) => {
			mapView.officeElementCategoryToHighlight = action.payload;
		},
		loadingOfficeElementsChanged: (mapView, action) => {
			mapView.ui.loadingOfficeElements = action.payload;
		},
		bottomCardChanged: (mapView, action) => {
			mapView.bottomCard = action.payload;
		},
		bottomCardDataChanged: (mapView, action) => {
			mapView.bottomCardData = action.payload;
		},
		updateHighlightBoothChanged: (mapView, action) => {
			mapView.highlightBoothData = action.payload;
		},
	},
});

export const {
	backgroundImageUpdated,
	mapLoadingReceived,
	nbOfAvailableEntitiesChanged,
	showStatusForEntitiesChanged,
	mapMenuOpenChanged,
	searchValueOfficeElementChanged,
	searchPageOpenChanged,
	officeElementsToHighlightChanged,
	officeElementsChanged,
	officeElementCategoryToHighlightChanged,
	loadingOfficeElementsChanged,
	bottomCardChanged,
	bottomCardDataChanged,
	updateHighlightBoothChanged,
} = slice.actions;

export default slice.reducer;

export const loadBackgroundImage = (areaId) => async (dispatch, getState) => {
	dispatch({type: mapLoadingReceived.type, payload: true});
	const areasFromStore = getState().auth.data.areas;
	const selectedArea = areasFromStore.find((area) => area._id === areaId);
	let mapUrl = 'no-map';

	const finishLoadMap = () => {
		dispatch(updateAreaMap(selectedArea._id, mapUrl));
		dispatch({type: backgroundImageUpdated.type, payload: mapUrl});
		dispatch({type: mapLoadingReceived.type, payload: false});
	};

	if (selectedArea?._id && !selectedArea?.mapUrl) {
		getMapOutline(selectedArea._id)
			.then((response) => {
				if (response.status === 204) {
					throw Error('not found');
				}
				mapUrl = window.URL.createObjectURL(new Blob([response.data]));
				finishLoadMap();
			})
			.catch((error) => {
				mapUrl = 'no-map';
				finishLoadMap();
			});
	} else if (selectedArea?.mapUrl && selectedArea?.mapUrl !== 'no-map') {
		mapUrl = selectedArea.mapUrl;
		finishLoadMap();
	} else if (selectedArea?.mapUrl === 'no-map') {
		finishLoadMap();
	}
};

export const updateMapLoading = (newValue) => (dispatch, getState) => {
	if (newValue === getState().mapView.ui.mapLoading) return;
	dispatch({type: mapLoadingReceived.type, payload: newValue});
};

export const addPhotoToFindColleagueData = (img) => (dispatch, getState) => {
	const findColleagueData = getState().mapView?.bottomCardData;
	if (!findColleagueData) return;
	let newFindColleagueData = JSON.parse(JSON.stringify(findColleagueData));
	newFindColleagueData.profilePhoto = img;

	dispatch(updateBottomCardData(newFindColleagueData));
};

export const updateNbOfAvailableEntities = (nb) => (dispatch, getState) => {
	dispatch({type: nbOfAvailableEntitiesChanged.type, payload: nb});
};

export const showStatusForEntitiesOnMap = (show) => (dispatch, getState) => {
	if (show === getState().mapView.showStatusForEntities) return;
	dispatch({type: showStatusForEntitiesChanged.type, payload: show});
};

export const setMenuOpen = (show) => (dispatch, getState) => {
	if (show === getState().mapView.openMenu) return;
	dispatch({type: mapMenuOpenChanged.type, payload: show});
};

export const setSearchValueForOfficeElements = (value) => (dispatch, getState) => {
	if (value === getState().mapView.searchValueOfficeElement) return;
	dispatch({type: searchValueOfficeElementChanged.type, payload: value});
};

export const setSearchPageOpen = (show) => (dispatch, getState) => {
	if (show === getState().mapView.searchPageOpen) return;
	dispatch({type: searchPageOpenChanged.type, payload: show});
};

export const setOfficeElementsToHighlight = (element, mapEmptyMode) => (dispatch, getState) => {
	if (element === null) {
		dispatch({
			type: searchValueOfficeElementChanged.type,
			payload: null,
		});
		dispatch({
			type: officeElementCategoryToHighlightChanged.type,
			payload: null,
		});
		dispatch({
			type: officeElementsToHighlightChanged.type,
			payload: null,
		});
		return;
	}
	const selectedAreaId = getState().entitiesBooking.selectedAreaId;
	const isElementInThisArea = element?.areas?.find((area) => area._id === selectedAreaId);

	const elementWasNotFound = (element) => {
		const selectedArea = getState().auth.data.areas.find((area) => area._id === selectedAreaId);
		dispatch({type: enableCardReceived.type, payload: true});
		dispatch({
			type: dataReceived.type,
			payload: {
				elementName: element?.name,
				areaName: selectedArea?.name,
				element: element,
				mapEmptyMode: mapEmptyMode,
			},
		});
		dispatch(enableBottomCard(false));
		dispatch({
			type: newCardToShowReceived.type,
			payload: CARD.NOT_FOUND_CARD,
		});
	};

	const highlightElementOnMap = (element, elementsToHighlight) => {
		const elementId = element?._id ? element._id : element.id;
		dispatch({
			type: officeElementCategoryToHighlightChanged.type,
			payload: elementId,
		});
		dispatch({type: searchPageOpenChanged.type, payload: false});
		dispatch({
			type: searchValueOfficeElementChanged.type,
			payload: element.name,
		});
		dispatch({
			type: officeElementsToHighlightChanged.type,
			payload: elementsToHighlight,
		});
		dispatch({
			type: bottomCardChanged.type,
			payload: 'officeElement',
		});
		dispatch({
			type: bottomCardDataChanged.type,
			payload: {officeElement: elementsToHighlight[0]},
		});
	};

	if (!isElementInThisArea && element?.parent) {
		elementWasNotFound(element);
		return;
	}

	if (!element?.parent) {
		if (isElementInThisArea) {
			highlightElementOnMap(element, [element]);
			return;
		}

		//check if one of the children is on the selected map
		const childrenOnSelectedMap = element?.officeEquipments?.filter((equipment) => {
			const isEquipmentOnAnyMap = equipment?.areas?.filter((area) => area._id === selectedAreaId);
			return isEquipmentOnAnyMap?.length > 0;
		});

		if (childrenOnSelectedMap.length > 0) {
			highlightElementOnMap(element, childrenOnSelectedMap);
		} else {
			elementWasNotFound(element);
		}
		return;
	}

	const currentHighlightedElements = getState().mapView.officeElementsToHighlight;
	dispatch({type: searchPageOpenChanged.type, payload: false});
	dispatch({
		type: officeElementCategoryToHighlightChanged.type,
		payload: element?.parent,
	});
	dispatch({
		type: searchValueOfficeElementChanged.type,
		payload: element?.category ? element.category : element.officeEquipmentCategory,
	});
	const newOfficeElementsToHighlight = currentHighlightedElements ? [...currentHighlightedElements, element] : [element];
	dispatch({
		type: officeElementsToHighlightChanged.type,
		payload: newOfficeElementsToHighlight,
	});
};

export const removeOfficeElementFromHighlight = (element) => (dispatch, getState) => {
	const currentHighlightedElements = getState().mapView.officeElementsToHighlight;
	let currentElementsArray = currentHighlightedElements.slice();
	let index = currentElementsArray.findIndex((el) => {
		const removeElementId = element?._id ? element._id : element.id;
		return el?._id === removeElementId || el?.id === removeElementId;
	});

	if (index > -1) {
		currentElementsArray.splice(index, 1);
	}
	dispatch({
		type: officeElementsToHighlightChanged.type,
		payload: currentElementsArray,
	});
};

export const loadOfficeElements = (officeId, forceReload) => async (dispatch, getState) => {
	const lastRequestTimestamp = getState().mapView.lastOfficeElementsRequest;
	if (
		!officeId ||
		(!forceReload && lastRequestTimestamp && DateTime.now().toUTC() < DateTime.fromISO(lastRequestTimestamp).toUTC().plus({minutes: 5}))
	) {
		return;
	}
	dispatch({type: loadingOfficeElementsChanged.type, payload: true});

	try {
		const officeElementsResponse = await getListOfAllOfficeElements(officeId);

		const checkIfElementIsOnMap = (element) => {
			if (element?.parent || element?.officeEquipments?.length === 0) return element?.areas?.length > 0;

			let childFoundOnMap = false;
			element.officeEquipments.forEach((equipment) => {
				if (equipment?.areas?.length > 0) {
					childFoundOnMap = true;
				}
			});

			return childFoundOnMap;
		};

		let elementsArray = [];
		if (officeElementsResponse.data?.length > 0) {
			const options = officeElementsResponse.data.map((element) => {
				const isElementOnMap = checkIfElementIsOnMap(element);
				if (isElementOnMap) {
					let array = [];
					if (element.officeEquipmentCategory) {
						array.push({...element, name: element.officeEquipmentCategory});
					}
					if (element.officeEquipments?.length > 0) array.push(...element.officeEquipments);

					return array;
				}
			});
			elementsArray = sortAlphabetically([].concat(...options), 'name').filter((el) => el); // 2d array to 1d and sort it alphabetically;
		}
		dispatch({
			type: officeElementsChanged.type,
			payload: {
				elements: elementsArray,
				timestamp: DateTime.now().toUTC().toISO(),
			},
		});
		dispatch({type: loadingOfficeElementsChanged.type, payload: false});
	} catch (error) {
		dispatch({type: loadingOfficeElementsChanged.type, payload: false});
	}
};

export const enableBottomCard = (newBottomCard) => async (dispatch, getState) => {
	if (getState().mapView.bottomCard === newBottomCard) return;

	if (!newBottomCard) {
		dispatch({type: bottomCardDataChanged.type, payload: null});
		dispatch({type: updateHighlightBoothChanged.type, payload: null});
	}
	dispatch({type: bottomCardChanged.type, payload: newBottomCard});
};

/**
 * Data needs to be in this format
 * data: {
 * 	entity: teakEntityData,
 *  booking: teakBookingData,
 *  colleague: colleagueData (only used when highlighting colleague's booking)
 *  officeElement: officeElementData (only used when clicking on an officeElement)
 * }
 */
export const updateBottomCardData = (newBottomCardData) => async (dispatch, getState) => {
	if (getState().mapView.bottomCardData === newBottomCardData) return;

	dispatch({type: bottomCardDataChanged.type, payload: newBottomCardData});
};

export const updateHighlightBoothData = (data) => async (dispatch, getState) => {
	dispatch({type: updateHighlightBoothChanged.type, payload: data});
};

//selectors
