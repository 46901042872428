import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DateTime} from 'luxon';
import {useHistory} from 'react-router';
import {locale} from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/en-ca';
import {app, authentication} from '@microsoft/teams-js';

import Page from './components/Page';
import {addAuthData, addFeatureTranslationsToStore, addUserData, enableLoadingScreen, verifyAuthCode} from './store/auth';
import LoadingScreen from './components/Common/LoadingScreen';
import {ROUTES} from './constants/routes';
import {enableCard, updateCardAndData} from './store/cards';
import {CARD} from './constants/cards';
import {getMember, loginViaTeams, validateBadgeVerificationCode} from './api';
import {addReportProblemTranslationsToStore} from './store/reportProblem';
import useBadgeNotification from './hooks/useBadgeNotification';
import processBoothScanned from './functions/processBoothScanned';
import {loadEventList} from './store/events';
import useQuery from './hooks/useQuery';
import {CommunicationStatus} from './constants/attendeeCommunicationStatus';

const forceSWupdate = () => {
	if ('serviceWorker' in navigator) {
		console.log('forceswupdate');
		navigator.serviceWorker.getRegistrations().then(function (registrations) {
			for (let registration of registrations) {
				registration.update();
			}
		});
	}
};

function App({pageContent, children}) {
	const dispatch = useDispatch();
	const history = useHistory();
	const query = useQuery();
	const userData = useSelector((state) => state.auth.data.userData);
	const colorTheme = useSelector((state) => state.auth.ui.theme);
	const attendeeData = useSelector((state) => state.events.attendeeData);
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const authCode = urlParams.get('code');
	const paymentRedirectStatus = urlParams.get('redirect_status');
	const paymentIntent = urlParams.get('payment_intent');
	const boothIdentifier = urlParams.get('booth');
	const eventId = urlParams.get('event');
	const isSuccessPaymentRedirect = window.location.pathname === ROUTES.SUCCESS_PAYMENT_REDIRECT;
	const showLoadingScreen = useSelector((state) => state.auth.ui.showLoadingScreen);
	const calendarFormatMonFirst = useSelector((state) => state.auth.data.calendarFormatMonFirst);
	useBadgeNotification();

	window.addEventListener('scroll', (e) => {
		e.preventDefault();
		window.scrollTo(0, 0);
	});

	const loginThroughMicrosoftTeams = () => {
		// After receiving the teams token, login via this token at spacestation
		const successLogin = async (result) => {
			const loginResponse = await loginViaTeams(result);
			if (loginResponse.data.member) {
				window.localStorage.setItem('memberID', loginResponse.data.member);
				window.localStorage.setItem('loggedIn', 'true');
			}
			dispatch(enableLoadingScreen(false));
			window.location.assign(ROUTES.DEFAULT);
		};
		// If we face an error during teams login, show error page with option to try again.
		const errorLogin = (error) => {
			console.error('Failure Auth: ' + error);
			dispatch(enableLoadingScreen(false));
			history.push(ROUTES.ERROR_TEAMS_LOGIN);
		};

		// Try to initialize Teams app...if successful, log in via Teams token
		// Otherwise show error page with option to try again.
		try {
			app.initialize()
				.then(() => {
					authentication.initialize();
					authentication.getAuthToken().then(successLogin).catch(errorLogin);
				})
				.catch(errorLogin);
		} catch (err) {
			errorLogin(err);
		}
	};

	const checkIfUserIsLoggedIn = async () => {
		const memberId = localStorage.getItem('memberID');
		const strategy = window.localStorage.getItem('loginStrategy');

		// if no memberId is found, the user is not logged in.
		if (!memberId) {
			if (pageContent === ROUTES.TEAMS) {
				loginThroughMicrosoftTeams();
				return;
			}

			if (!authCode) {
				dispatch(enableLoadingScreen(false));
				if (window.location.pathname !== ROUTES.LOGIN) window.localStorage.setItem('redirectUriLogin', window.location);
				history.push(ROUTES.LOGIN + queryString);
				return;
			} else {
				// if authCode coming from Microsoft login callback is found, try to verify the code.
				if (strategy === null) {
					dispatch(enableLoadingScreen(false));
					history.push(ROUTES.LOGIN + queryString);
					return;
				} else {
					const organization = window.localStorage.getItem('organization');
					if (!organization) {
						dispatch(enableLoadingScreen(false));
						history.push(ROUTES.LOGIN + queryString);
						return;
					}
					dispatch(verifyAuthCode(queryString, strategy, organization));
					return;
				}
			}
		}

		try {
			const memberResponse = await getMember(memberId);
			dispatch(addUserData(memberResponse.data));
			if (pageContent === ROUTES.LOGIN || pageContent === ROUTES.TEAMS) {
				history.push(ROUTES.DEFAULT);
			}
		} catch (error) {
			console.log('error: ', error);
			localStorage.removeItem('memberID');
			if (pageContent === ROUTES.TEAMS) {
				loginThroughMicrosoftTeams();
				return;
			}
			dispatch(enableLoadingScreen(false));
			history.push(ROUTES.LOGIN + queryString);
		}
	};

	const checkIfUserIsInEventFromQuery = () => {
		const vCode = query.get('vCode');

		// if an event id is in the url set it as the selectedEvent
		if (eventId) {
			dispatch(loadEventList(eventId, (event) => {
				if (!event) {
					history.push(ROUTES.INVITATIONS)
					return;
				}
			}));
			return;
		} else if (vCode) {
			checkVCode(vCode);
			return;
		} else {
			history.push(ROUTES.INVITATIONS)
			return;
		}
	}

	function checkVCode(vCode) {
		// If badge has been scanned, go to attendee page
		dispatch(loadEventList(null, async (events) => {
			for (const event of events) {
				try {
					const validateBadgeCodeResponse = await validateBadgeVerificationCode(vCode, event._id);
					const attendee = validateBadgeCodeResponse.data;
					history.push(ROUTES.EVENT_ATTENDEE_PAGE, {attendee});
					return;
				} catch { }
			}
		}));
	}


	useEffect(() => {
		if (!userData) {
			checkIfUserIsLoggedIn();
			return;
		}
		const strategy = window.localStorage.getItem('loginStrategy');

		if (strategy && strategy.toLowerCase() === 'otp') {
			const isProfileUpdated = localStorage.getItem('profile_completion_visited');
			if (isProfileUpdated === 'true') {
				history.push(ROUTES.OFFICE_SELECTION);
			} else {
				history.push(ROUTES.PROFILE_COMPLETION);
			}
			dispatch(enableLoadingScreen(false));
			return;
		}

		dispatch(addAuthData(userData, history));

		let newVersion = 'V1.0.40';
		let currentVersion = localStorage.getItem('currentVersion');

		if (currentVersion !== newVersion) {
			localStorage.setItem('currentVersion', newVersion);
			localStorage.setItem('lastVersionUpdate', DateTime.now().toUTC().toLocaleString(DateTime.DATETIME_FULL));
			forceSWupdate();
		}

		if (colorTheme !== null) {
			if (!document.body.classList.contains(colorTheme)) document.body.classList.add(colorTheme);
		}

		if (isSuccessPaymentRedirect && paymentRedirectStatus === 'succeeded') {
			const entityId = window.localStorage.getItem('paymentIntentEntityID');
			const startTime = window.localStorage.getItem('paymentIntentBookingStartTime');
			const endTime = window.localStorage.getItem('paymentIntentBookingEndTime');
			const amount = window.localStorage.getItem('paymentAmount');
			dispatch(enableCard(true));
			dispatch(
				updateCardAndData(CARD.PAYMENT_REDIRECT, {
					amount,
					entityId,
					startTime,
					endTime,
					paymentIntent,
				}),
			);
		}

		dispatch(addFeatureTranslationsToStore());
		dispatch(addReportProblemTranslationsToStore());

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userData]);

	useEffect(() => {
		if (boothIdentifier) {
			processBoothScanned(dispatch, boothIdentifier);
		}

		const loggedIn = localStorage.getItem('memberID');
		if (loggedIn) {
			checkIfUserIsInEventFromQuery();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	useEffect(() => {
		if (!calendarFormatMonFirst) {
			locale('en-ca');
		} else {
			locale('en-gb');
		}
	}, [calendarFormatMonFirst]);

	if (showLoadingScreen) {
		return <LoadingScreen />;
	}

	return <Page pageContent={pageContent}>{children}</Page>;
}

export default App;
