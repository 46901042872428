import { useTranslation } from 'react-i18next';

import Icon from '../Common/Icon';
import colorBasedOnTheme from '../../functions/colorBasedOnTheme';

function ProfileItem({ showExclamationIcon, iconName, iconColor, iconImage, onClick, heading, additionalIcon }) {
	const { t } = useTranslation();
	let exclamationIcon = null;
	const colors = colorBasedOnTheme();

	if (showExclamationIcon) {
		exclamationIcon = <Icon iconName="exclamation-circle" size={'sm'} cssClass={'icon-invalid-certificate icon-red'} />;
	}

	return (
		<div className={'profile-item'} onClick={onClick}>
			{
				iconImage && (
					<div className='profile-item-img-container'>
						<img src={iconImage} />
					</div>
				)

			}
			{
				iconName && (
					<div className='profile-item-img-container'>
						<Icon cssClass={'icon-main-theme-color'} iconName={iconName} iconColor={iconColor ?? colors.colorMainTheme} />
					</div>
				)

			}
			<p className={'profile-item-heading'}>
				{t(heading)}

				{exclamationIcon}
			</p>
			{additionalIcon || null}
			<Icon iconName={'angle-right'} cssClass={'profile-item-icon-arrow icon-disabled'} />
		</div>
	);
}

export default ProfileItem;
