
import {useTranslation} from 'react-i18next';

import Icon from '../../Icon';
import {Typography} from '../../Typography';
/**
 *
 * @param {*} props.cssClass = css class added to the button element
 * @param {*} props.disabled = if the button is disabled or not
 * @param {*} props.clickHandler = callback for button click
 * @param {*} props.backButtonText = text for the back button on top of the header
 * @returns
 */
function ButtonBack(props) {
	const {t} = useTranslation();
	let cssClass = 'btn-back ';
	if (props.isAreaList) {
		//The back-button in the area list is placed further to the left because the circle-shaped background is not visible on the area list background. That is why a separate class is used for the area list only.
		cssClass = 'btn-back area-list';
	}

	if (props.cssClass !== undefined) {
		cssClass += props.cssClass;
	}

	return (
		<button onClick={props.clickHandler} className={cssClass}>
			<Icon cssClass={'icon-black'} iconName={'chevron-left'} size={props.iconSize ? props.iconSize : 'xs'}></Icon>
			<Typography variant={'title-medium'}>{t(props.backButtonText.length > 0 ? props.backButtonText : 'common.backButton')}</Typography>
		</button>
	);
}

export default ButtonBack;
