import  {useState} from 'react';

import {useTranslation} from 'react-i18next';
import Button from '../Common/Button';
import {getLoginUrl} from '../../api';

function LoginStrategies(props) {
	const {t} = useTranslation();
	let googleLogin = props.strategies?.includes('google');
	let microsoftLogin = props.strategies?.includes('microsoft');
	const [loading, setLoading] = useState(false);

	const redirectToStrategy = (strategy) => {
		setLoading(true);
		window.localStorage.setItem('loginStrategy', strategy);
		getLoginUrl(strategy, props.email)
			.then((response) => {
				console.log(response);
				window.location.assign(response.data);
			})
			.catch((error) => {
				if (error.response?.data?.name) {
					props.setError(error.response?.data?.name);
				} else {
					props.setError(t('errorMessages.apiCalls.errorLoginUrl'));
				}
				setLoading(false);
			});
	};

	return (
		<>
			<div className={'buttonWrapper strategyButtons'}>
				{googleLogin ? (
					<Button
						cssClass={'loginButton'}
						variant={'primary'}
						height={'regular'}
						width={'full'}
						translationKey={t('loginPage.loginGoogle')}
						onClick={() => redirectToStrategy('google')}
						loading={loading}
					/>
				) : null}
				{microsoftLogin ? (
					<Button
						cssClass={'loginButton'}
						variant={'primary'}
						height={'regular'}
						width={'full'}
						translationKey={t('loginPage.loginMicrosoft')}
						onClick={() => redirectToStrategy('microsoft')}
						loading={loading}
					/>
				) : null}
			</div>
		</>
	);
}

export default LoginStrategies;
