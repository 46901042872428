import {updateDataPrivacyDataStorage} from '../../../../api/member';
import {deleteNotification} from '../../../../api/notifications';
import {ROUTES} from '../../../../constants/routes';
import {enableCard} from '../../../../store/cards';
import {updateNotificationDetailsData, updateNotificationsList} from '../../../../store/profile';
import {emptyReportProblemStore} from '../../../../store/reportProblem';
import {dataPrivacyBookingDataStorageUpdate, processItemInList} from '../../../../store/settings';
import {bookSession} from '../../../../api/event';
import {loadEventSessionsOfUser} from '../../../../store/events';
import {deleteAgendaItem} from '../../../../api/agenda';

export const confirmActionsMap = {
	CANCEL_REPORT_PROBLEM: 'reportProblemDelete',
	CANCEL_ATTENDANCE: 'cancelAttendance',
	DELETE_NOTIFICATION: 'deleteNotification',
	DELETE_DATA_STORAGE_OPTION: 'deleteDataStorageOption',
	DELETE_ATTENDEE: 'deleteAttendee',
	EDIT_ATTENDEE_FOR_BOOKING: 'editAttendeeForBooking',
	BOOK_SESSION: 'bookSession',
	DELETE_AGENDA_ITEM: 'deleteAgendaItem',
};
// Confirm Actions map holds all the actions that can be triggered from the confirm action card.
// Each function returns a promise that resolves with the key of the success message to be shown and a callback function to be executed after the success message is shown.
const confirmActionToFunctions = [
	[confirmActionsMap.CANCEL_REPORT_PROBLEM, cancelReportProblem],
	[confirmActionsMap.DELETE_NOTIFICATION, deleteNotificationOfUser],
	[confirmActionsMap.DELETE_DATA_STORAGE_OPTION, deleteDataStorageOption],
	[confirmActionsMap.DELETE_ATTENDEE, deleteAttendee],
	[confirmActionsMap.BOOK_SESSION, confirmSessionBooking],
	[confirmActionsMap.DELETE_AGENDA_ITEM, confirmDeleteAgendaItem],
];
export const confirmActionToFunctionsMap = new Map(confirmActionToFunctions);

function cancelReportProblem(dispatch, data, history) {
	return new Promise((resolve, reject) => {
		resolve({
			successKey: undefined,
			callback: () => {
				const path = window.location.href;
				dispatch(emptyReportProblemStore());
				dispatch(enableCard(false));

				if (path.includes('profile')) {
					history.push(ROUTES.PROFILE_PAGE + ROUTES.REPORT_PROBLEM);
				} else {
					history.goBack();
				}
			},
		});
	});
}

function deleteNotificationOfUser(dispatch, data, history) {
	return new Promise((resolve, reject) => {
		const notificationIds = data?.notificationIds;
		const userData = data?.userData;
		deleteNotification(notificationIds, userData._id)
			.then((response) => {
				resolve({
					successKey: notificationIds.length > 1 ? 'notifications.notificationsDeleteSuccess' : 'notifications.notificationDeleteSuccess',
					callback: () => {
						dispatch(updateNotificationsList(userData._id));
						dispatch(updateNotificationDetailsData(null));
					},
				});
			})
			.catch((error) => {
				dispatch(updateNotificationDetailsData(null));
				reject({
					...error,
					errorTranslationKey:
						notificationIds.length > 1 ? 'notifications.notificationsDeleteError' : 'notifications.notificationDeleteError',
				});
			});
	});
}

function deleteDataStorageOption(dispatch, data, history) {
	return new Promise((resolve, reject) => {
		const userData = data?.userData;
		updateDataPrivacyDataStorage(userData?._id, false)
			.then((response) => {
				resolve({
					successKey: undefined,
					callback: () => {
						dispatch(dataPrivacyBookingDataStorageUpdate());
						dispatch(enableCard(false));
					},
				});
			})
			.catch((error) => {
				dispatch(dataPrivacyBookingDataStorageUpdate());
				dispatch(enableCard(false));
				reject(error);
			});
	});
}

function deleteAttendee(dispatch, data, history) {
	return new Promise((resolve, reject) => {
		const removedAttendee = data;
		resolve({
			successKey: undefined,
			callback: () => {
				dispatch(processItemInList(removedAttendee));
				dispatch(enableCard(false));
			},
		});
	});
}

function confirmSessionBooking(dispatch, data, history) {
	return new Promise((resolve, reject) => {
		const {event, _id} = data;

		bookSession(event, _id)
			.then((response) => {
				resolve({
					successKey: 'event.sessionBookingSuccess',
					callback: () => {
						dispatch(loadEventSessionsOfUser(event));
						history.goBack();
					},
				});
			})
			.catch((error) => {
				dispatch(loadEventSessionsOfUser(event));
				reject({...error, errorTranslationKey: 'errorMessages.apiCalls.sessionBookingFailed'});
			});
	});
}
function confirmDeleteAgendaItem(dispatch, data, history) {
	return new Promise((resolve, reject) => {
		const {agendaItem, userData} = data;
		deleteAgendaItem(userData._id, agendaItem?._id, agendaItem?.office)
			.then((response) => {
				resolve({
					successKey: 'agenda.successDeleteItem',
				});
			})
			.catch((error) => {
				reject({...error, errorTranslationKey: 'errorMessages.general'});
			});
	});
}
