import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';

import Button from '../../../Common/Button';
import {ROUTES} from '../../../../constants/routes';
import {useDispatch, useSelector} from 'react-redux';
import {getReportItemsCategorized} from './MyReportsPage';
import useTimer from '../../../../hooks/useTimer';
import {loadIssueReports} from '../../../../store/reportProblem';
import {useEffect} from 'react';
import Icon from '../../../Common/Icon';
import {Typography} from '../../../Common/Typography';
import colorBasedOnTheme from '../../../../functions/colorBasedOnTheme';


function MyReportsSection() {
	const {t} = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const issueReports = useSelector((state) => state.reportProblem.issueReports);
	const color = colorBasedOnTheme();

	const redirectToReportsPage = () => {
		history.push(ROUTES.REPORT_PROBLEM + ROUTES.MY_REPORTS, {issueReports});
	};

	useEffect(() => {
		dispatch(loadIssueReports(true));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// * every 1 min load the reports data again
	useTimer(60000, () => {
		dispatch(loadIssueReports());
	});

	function redirectToReportProblem() {
		history.push(ROUTES.REPORT_PROBLEM);
	}

	if (!issueReports || issueReports.length < 1) return (
		<div className='support-section'>
			<div className="my-reports-header">
				<h4>{t('personalStats.stats.reportedProblems')}</h4>
			</div>
			<div className='empty-state'>
				<Icon iconName="bug" cssClass="bug-icon" iconColor={color.colorMainTheme}/>
				<Typography variant="title-medium label">{t('settings.reportProblem.noProblemsReported')}</Typography>
				<Button
					variant={'primary'}
					height={'regular'}
					width={'variable'}
					translationKey={'common.buttons.reportProblem'}
					onClick={redirectToReportProblem}
					roundCorners={true}
					cssClass='button'
				/>
			</div>
		</div>);

	return (
		<>
			<div className='support-section'>
				<div className="my-reports-header">
					<h4>{t('personalStats.stats.reportedProblems')}</h4>
					<Button
						cssClass={'show-all'}
						variant={'tertiary bold'}
						height={'regular'}
						width={'variable'}
						onClick={redirectToReportsPage}
						translationKey={'common.seeAll'}
					/>
				</div>
				<div className="my-reports-container-items">{getReportItemsCategorized(issueReports.slice(0, 3))}</div>
				<Button
					variant={'primary'}
					height={'regular'}
					width={'variable'}
					translationKey={'common.buttons.reportProblem'}
					onClick={redirectToReportProblem}
					roundCorners={true}
					cssClass={'report-a-problem-button'}
				/>
			</div>
		</>
	);
}

export default MyReportsSection;
