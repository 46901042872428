import {useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import {styled} from '@mui/material/styles';
import {ROUTES} from '../../../constants/routes';
import {submitSessionFeedback} from '../../../api/additionals';
import {CARD} from '../../../constants/cards';
import {enableCard, updateCard, updateErrorCard, updateSuccessCard} from '../../../store/cards';
import Button from '../../Common/Button';
import {Textarea} from '../../Common/Textareas/Textarea';
import {loadEventSessionsOfUser} from '../../../store/events';
import colorBasedOnTheme from '../../../functions/colorBasedOnTheme';
import SecondaryHeader from '../../Common/Header/SecondaryHeader';
import {Typography} from '../../Common/Typography';
import SessionInfo from './SessionInfo';

function RateSession(props) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const data = useSelector((state) => state.cards.data);
    const [feedbackText, setFeedbackText] = useState('');
    const contentRef = useRef();
    const [cssForHiddenDiv, setCssForHiddenDiv] = useState(false); //the div is used to make the content scrollable when the virtual keyboard is on
    const [rating, setRating] = useState(1);
    const [session, setSession] = useState();
    const location = useLocation();
    const userData = useSelector((state) => state.auth.data.userData);
    const displayFrench = userData.language === 'fr';

    const eventRatingKey = displayFrench && session?.ratingQuestion?.fr ? session?.ratingQuestion?.fr :
        session?.ratingQuestion?.en ? session?.ratingQuestion?.en : 'event.rating';

    const eventFeedbackKey = displayFrench && session?.commentRequest?.fr ? session?.commentRequest?.fr :
        session?.commentRequest?.en ? session?.commentRequest?.en : 'event.feedback';

    const submitFeedback = async () => {
        dispatch(enableCard(true));
        dispatch(updateCard(CARD.PLACEHOLDER));

        try {
            await submitSessionFeedback(rating, feedbackText, session?._id, session?.wengeEvent);
            dispatch(updateSuccessCard(CARD.MAIN_SUCCESS, 'userReviewCard.successSubmission'));
            dispatch(loadEventSessionsOfUser(data?.wengeEvent));
        } catch (error) {
            dispatch(updateErrorCard(CARD.MAIN_ERROR, 'userReviewCard.failedSubmission', error));
        }
        history.goBack();
    };

    useEffect(() => {
        if (!location?.state?.session) {
            history.push(ROUTES.EVENT);
            return;
        }
        setSession(location.state.session);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleBlur = () => {
        setCssForHiddenDiv('-hidden');
    };

    return (
        <div className={'rate-session-page'}>
            <SecondaryHeader
                backButtonText={'common.backButton'}
                backButtonClickHandler={() => history.goBack()}
                headerTranslationKey={'event.rating'}
            />
            <div className={'rate-session-page-content'} ref={contentRef}>
                <Typography variant="headline-medium">{t('event.rating')}</Typography>
                <div className='rate-session-page-content-details'>
                    <SessionInfo session={session} />
                </div>
                <div className={'stars-wrapper'}>
                    <Typography variant="label-large">{t(eventRatingKey)}</Typography>
                    <RatingSlider rating={rating} setRating={setRating} min={1} max={10} />
                </div>
                <div className="rate-session-page-content-input">
                    <Typography variant="label-large">{t(eventFeedbackKey)}</Typography>
                    <Textarea
                        cssClass={`rate-session-textarea`}
                        cols="30"
                        rows="10"
                        id="rate-session"
                        defaultValue={feedbackText}
                        changeHandler={setFeedbackText}
                        placeholder={'event.writeFeedbackPlaceholder'}
                        onBlur={handleBlur}
                    />
                </div>
                {rating !== 0 && (
                    <Button
                        variant={'primary'}
                        height={'regular'}
                        width={'full'}
                        cssClass='confirm-details'
                        onClick={submitFeedback}
                        translationKey={'common.buttons.send'}
                        roundCorners={true}
                    />
                )}
                <div className={'placeholderForVirtualKeyboard' + cssForHiddenDiv}></div>
            </div>

        </div>
    );
}

export function RatingSlider({rating, setRating, min, max, labelKey, required, step}) {
    if (!min) min = 0;
    if (!max) max = min + 10;
    if (!step) step = 1;
    let requiredMark = required ? '*' : '';
    const {t} = useTranslation();
    const colors = colorBasedOnTheme();
    function handleSliderChange(e, newRating) {
        setRating(newRating);
    }

    let marks;
    const markLength = max - min + 1;
    if (markLength > 10) {
        marks = [
            {
                value: min,
                label: `${min}`,
            },
            {
                value: max,
                label: `${max}`,
            }
        ]
    } else {
        marks = Array.from({length: max - min + 1}, (_, index) => {
            const value = min + index;
            return {
                value: value,
                label: `${value}`,
            };
        });
    }

    const FeedbackSlider = styled(Slider)({
        color: colors.colorMainTheme,
        '& .MuiSlider-valueLabel': {
            backgroundColor: colors.colorMainTheme,
            color: '#fff',
        },
    });

    return (
        <>
            {labelKey && <label className={'primary-input-label'}>{t(labelKey) + requiredMark}</label>}
            <Box sx={{width: 300, paddingLeft: '10px', alignSelf: 'center'}}>
                <FeedbackSlider onChangeCommitted={handleSliderChange} min={min} max={max} defaultValue={rating} step={step} marks={marks} valueLabelDisplay="auto" />
            </Box>
        </>
    );
}
export default RateSession;
