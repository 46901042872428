import {useTranslation} from 'react-i18next';

/**
 * @param {Object[]} options - Array of checkbox options
 * @param {string} labelKey - Translation key for the list label
 * @param {boolean} hasError - Whether the list has an error
 * @param {string} errorMessage - Error message to display
 * @param {boolean} required - Whether the list is required
 * @returns A custom checkbox list component
 */
function CheckboxList({options, labelKey, hasError, errorMessage, required}) {
	const {t} = useTranslation();
	const requiredMark = required ? '*' : '';

	const handleCheckboxChange = (option, event) => {
		if (!option.disabled) {
			option.onChange(event);
		}
	};

	return (
		<>
			<div className={`checkbox-list${hasError ? ' error' : ''}`}>
				{labelKey && <label className="checkbox-list-label">{t(labelKey) + requiredMark}</label>}
				{options.map((option, index) => (
					<div key={index} className="checkbox-wrapper">
						<input
							className="input-checkbox"
							type="checkbox"
							name={option.id}
							id={option.id}
							onChange={(event) => handleCheckboxChange(option, event)}
							checked={option.checked}
							disabled={option.disabled}
						/>
						<label className="input-checkbox-label" htmlFor={option.id}>
							{t(option.text)}
						</label>
					</div>
				))}
			</div>
			{hasError && <p className="checkbox-list-error-message">{t(errorMessage)}</p>}
		</>
	);
}

export default CheckboxList;
