import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';

import infoImage from '../../../image/eventSupportImage.svg';
import {ROUTES} from '../../../constants/routes';
import Button from '../../Common/Button';

export default function CheckinIntoEvent({eventId}) {
	const history = useHistory();
	const {t} = useTranslation();

	function redirectToQR() {
		history.push(ROUTES.PROFILE_PAGE + ROUTES.BADGE);
	}

	return (
		<>
			<div className="event-page-info">
				<div className="image-wrapper">
					<img src={infoImage} />
				</div>
				<div className="event-info">
					<p>{t('event.eventCheckinInfo')}</p>
				</div>
				<Button
					cssClass={'show-all'}
					variant={'primary'}
					height={'regular'}
					width={'variable'}
					onClick={redirectToQR}
					translationKey={'event.qrCode'}
				/>
			</div>
		</>
	);
}
