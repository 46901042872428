export const handleFocus = (elId: string, arg?: ScrollIntoViewOptions) => {
	const element = document.getElementById(elId);
	if (!element) return;

	const rect = element.getBoundingClientRect();
	const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

	// Check if the element is in the bottom half of the viewport
	if (rect.top >= viewportHeight / 2) {
		setTimeout(() => {
			const defaultOptions: ScrollIntoViewOptions = {behavior: 'smooth', block: 'center'};
			const options = {...defaultOptions, ...arg};
			element.scrollIntoView(options);
		}, 0);
	}
};
