import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';

import {ROUTES} from '../../constants/routes';
import Icon from '../Common/Icon';
import colorBasedOnTheme from '../../functions/colorBasedOnTheme';

function BottomMenu({page}) {
	const history = useHistory();
	const {t} = useTranslation();
	const color = colorBasedOnTheme();
	const showCard = useSelector((state) => state.cards.showCard);
	const cardToShow = useSelector((state) => state.cards.cardToShow);
	const selectedEvent = useSelector((state) => state.events.selectedEvent);
	const qrEnabled = selectedEvent?.options?.qrCode?.enabled;
	const [selectedOption, setSelectedOption] = useState(1);

	useEffect(() => {
		let newSelectedOption = 1;
		switch (page) {
			case ROUTES.EVENT_AGENDA:
				newSelectedOption = 2;
				break;
			case ROUTES.REPORT_PROBLEM:
				newSelectedOption = 3;
				break;
			case ROUTES.EVENT_SUPPORT:
				newSelectedOption = 3;
				break;
			case ROUTES.QR_SCANNER:
				newSelectedOption = 4;
				break;
			default:
				newSelectedOption = 1;
				break;
		}

		if (newSelectedOption !== selectedOption) setSelectedOption(newSelectedOption);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	const firstOptionClickHandler = () => {
		history.push(ROUTES.EVENT);
	};

	const secondOptionClickHandler = () => {
		history.push(ROUTES.EVENT_AGENDA);
	};

	const thirdOptionClickHandler = () => {
		history.push(ROUTES.EVENT_SUPPORT);
	};

	const qrCodeOptionClickHandler = () => {
		history.push(ROUTES.QR_SCANNER);
	};


	let pageCss = showCard && ((cardToShow !== 'mainSuccess') && (cardToShow !== 'mainError')) ? 'page-bottom-menu blurred' : 'page-bottom-menu ';

	return (
		<div className={pageCss}>
			<div className={'options-wrapper'}>
				<div className="option home-option" onClick={firstOptionClickHandler}>
					<div className={'purple-bar ' + (selectedOption === 1 ? 'active-bar' : '')}></div>
					<Icon iconName="house" size={'lg'} iconColor={selectedOption === 1 ? color.colorMainTheme : color.colorNeutral80} />
					<p className={selectedOption === 1 ? 'active' : 'disabled'}>{t('dashboard.homeOption')}</p>
				</div>
				<div className="option bookings-option" onClick={secondOptionClickHandler}>
					<div className={'purple-bar ' + (selectedOption === 2 ? 'active-bar' : '')}></div>
					<Icon iconName={'agenda'} size={'lg'} type={'far'} iconColor={selectedOption === 2 ? color.colorMainTheme : color.colorNeutral80} />
					<p className={selectedOption === 2 ? 'active' : 'disabled'}>{t('common.agenda')}</p>
				</div>
				<div className="option reportProblem-option" onClick={thirdOptionClickHandler}>
					<div className={'purple-bar ' + (selectedOption === 3 ? 'active-bar' : '')}></div>
					<Icon iconName={'supportAgent'} size={'lg'} iconColor={selectedOption === 3 ? color.colorMainTheme : color.colorNeutral80} />
					<p className={selectedOption === 3 ? 'active' : 'disabled'}>{t('common.support')}</p>
				</div>
				{qrEnabled &&
					<div className={'option qr-code-option'} onClick={qrCodeOptionClickHandler}>
						<div className={'purple-bar ' + (selectedOption === 4 ? 'active-bar' : '')}></div>
						<div className="qr-icon">
							<Icon iconName="qrCodeWrapped" size={'lg'} cssClass={'icon-white'} />
						</div>
					</div>
				}
			</div>
		</div>
	);
}

export default BottomMenu;
