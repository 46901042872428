import SecondaryHeader from '../Common/Header/SecondaryHeader';
import {Typography} from '../Common/Typography';
import {useHistory, useLocation} from 'react-router-dom';
import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ROUTES} from '../../constants/routes';
import Button from '../Common/Button';
import {editEventForm, submitEventForm} from '../../api/event';
import {enableCard, updateCard, updateErrorCard, updateSuccessCard} from '../../store/cards';
import {CARD} from '../../constants/cards';
import get from 'lodash/get';
import {loadEventSessionsOfUser, setEventAttendee} from '../../store/events';
import {
	FormTextInput,
	FormNumberInput,
	FormDropdownInput,
	FormCheckboxInput,
	FormTextareaInput,
	FormRatingInput,
	FormDividerInput,
	FormHtmlInput,
	FormSessionInput,
} from './FormInputs';
import shouldBeHidden from './VisibilityRuleParser';

export const nonEditableFieldTypes = ['html', 'divider'];

export function getFormPropertyTranslation(languageArray, language) {
	if (!languageArray || languageArray?.length === 0) return '';

	const foundTranslation = languageArray.find((key) => key.langKey === language);
	if (foundTranslation) return foundTranslation.text;

	const englishTranslation = languageArray.find((key) => key.langKey === 'en');
	if (englishTranslation) return englishTranslation.text;

	return languageArray[0].text;
}

export function saveFormDataToLocalStorage(eventForm, formData) {
	if (!eventForm || !formData) return;

	localStorage.setItem(`eventFormData_${eventForm._id}`, JSON.stringify(formData));
}

export function loadFormDataFromLocalStorage(eventForm) {
	const savedData = localStorage.getItem(`eventFormData_${eventForm._id}`);
	return savedData ? JSON.parse(savedData) : null;
}

export function clearFormDataFromLocalStorage(eventForm) {
	localStorage.removeItem(`eventFormData_${eventForm._id}`);
}

export const isFormDataValid = (eventForm, data) => {
	for (const item of eventForm.items) {
		const itemIndex = data.findIndex((dataItem) => dataItem.key === item.uuid);
		const itemValue = itemIndex !== -1 ? data[itemIndex].value : '';

		const isHidden = shouldBeHidden(item, eventForm, data);

		if (!isHidden && item.required && !item.readonly && (itemValue === null || itemValue === '' || itemValue?.length === 0)) {
			return false; // Return false if any required input is empty
		}
	}
	return true; // Return true if all required inputs have a value
};

function EventForm() {
	const history = useHistory();
	const contentRef = useRef();
	const location = useLocation();
	const dispatch = useDispatch();
	const userData = useSelector((state) => state.auth.data.userData);
	const selectedEvent = useSelector((state) => state.events.selectedEvent);
	const attendeeData = useSelector((state) => state.events.attendeeData);
	const eventForm = location?.state?.eventForm;
	const [data, setData] = useState([]);
	const [selectedSessions, setSelectedSessions] = useState([]);
	const isRegistrationForm = eventForm?._id === selectedEvent?.options?.registrationForm;
	const isDeclineForm = eventForm?._id === selectedEvent?.options?.declineForm;
	const formDataValid = isFormDataValid(eventForm, data);

	//if editSubmissionId is set, edit mode is activated and submitting will edit this submission
	const editSubmissionId = location?.state?.editSubmissionId;

	useEffect(() => {
		if (!eventForm) {
			history.push(ROUTES.EVENT);
			return;
		}

		const savedData = loadFormDataFromLocalStorage(eventForm);
		const newData = eventForm.items
			.filter((item) => !nonEditableFieldTypes.includes(item.type)) // Filter out non-editable fields
			.map((item) => {
				// If item has a mapped value use it
				if (item.mappedValue) {
					let value = get(attendeeData, item.mappedValue);
					if (value) {
						return {
							key: item.uuid,
							value: item.type === 'checkbox' ? [...value] : value,
						};
					}

					//Check if mappedValue is in customData
					value = attendeeData.customData?.find((data) => data.key === item.mappedValue)?.value;
					if (value) {
						return {
							key: item.uuid,
							value: item.type === 'checkbox' ? [...value] : value,
						};
					}
				}

				// Check if the item exists in savedData
				const existingItem = savedData ? savedData.find((dataItem) => dataItem.key === item.uuid) : null;

				// If the item exists, retain its value
				if (existingItem) {
					return existingItem;
				}

				// If item doesn't exist, initialize it
				return {
					key: item.uuid,
					value: item.type === 'checkbox' ? [] : '',
				};
			});

		setData(newData);
	}, [eventForm]);

	useEffect(() => {
		const sessionItemExistsInForm = eventForm.items.some((item) => item.type === 'session');
		if (sessionItemExistsInForm) {
			dispatch(loadEventSessionsOfUser(selectedEvent._id));
		}
	}, [eventForm]);

	useEffect(() => {
		updateSelectedSessions();
	}, [data]);

	async function sendEventFormSubmission() {
		dispatch(enableCard(true));
		dispatch(updateCard(CARD.PLACEHOLDER));

		try {
			const filteredData = getDataWithoutHiddenValues();
			await submitEventForm(selectedEvent._id, eventForm._id, attendeeData._id, filteredData);
			dispatch(updateSuccessCard(CARD.MAIN_SUCCESS, 'eventForm.formSubmitted'));
			clearFormDataFromLocalStorage(eventForm); // Clear saved data

			if (eventForm.valueMapping) {
				await dispatch(setEventAttendee(selectedEvent));
			}
			if (isRegistrationForm && !editSubmissionId) {
				history.push(ROUTES.REGISTRATION_APPROVED);
			} else if (isDeclineForm) {
				history.push(ROUTES.REGISTRATION_DECLINED);
			} else {
				history.push(ROUTES.EVENT);
			}
		} catch (error) {
			dispatch(updateErrorCard(CARD.MAIN_ERROR, 'eventForm.error', error));
		}
	}

	async function editEventFormSubmission() {
		dispatch(enableCard(true));
		dispatch(updateCard(CARD.PLACEHOLDER));

		try {
			const filteredData = getDataWithoutHiddenValues();
			await editEventForm(selectedEvent._id, editSubmissionId, filteredData);
			dispatch(updateSuccessCard(CARD.MAIN_SUCCESS, 'eventForm.formEdited'));
			clearFormDataFromLocalStorage(eventForm); // Clear saved data

			if (eventForm.valueMapping) {
				await dispatch(setEventAttendee(selectedEvent));
			}
			history.goBack();
		} catch (error) {
			dispatch(updateErrorCard(CARD.MAIN_ERROR, 'eventForm.editError', error));
		}
	}

	function getDataWithoutHiddenValues() {
		return data.filter((dataItem) => {
			const item = eventForm.items.find((item) => item.uuid === dataItem.key);
			if (!item) return false;
			return !shouldBeHidden(item, eventForm, data);
		});
	}

	const handleCheckboxChange = (index, option) => {
		// Create a copy of the current data
		const newData = [...data];

		// Find the current item and its value array
		const currentItem = newData[index];
		const valueArray = currentItem.value;

		const optionIndex = valueArray.indexOf(option.value);

		if (optionIndex !== -1) {
			// If the option is already in the array, remove it
			valueArray.splice(optionIndex, 1);
		} else {
			// If the option is not in the array, add it
			valueArray.push(option.value);
		}

		// Update the state with the new data
		setData(newData);
		saveFormDataToLocalStorage(eventForm, newData);
	};

	function updateFormData(dataIndex, newValue) {
		setData((cur) => {
			const newData = [...cur];
			newData[dataIndex].value = newValue;
			saveFormDataToLocalStorage(eventForm, newData);
			return newData;
		});
	}

	function updateSelectedSessions() {
		const currentlySelectedSessions = [];
		data.forEach((dataItem) => {
			const eventFormItem = eventForm.items.find((item) => item.uuid === dataItem.key);
			if (eventFormItem.type === 'session' && dataItem.value) {
				return currentlySelectedSessions.push(dataItem.value);
			}
		});
		setSelectedSessions(currentlySelectedSessions);
	}

	const renderFormItem = (item, dataItemIndex, dataItem) => {
		const isHidden = shouldBeHidden(item, eventForm, data);
		if (isHidden) return <></>;
		switch (item.type) {
			case 'text':
				return (
					<FormTextInput
						item={item}
						dataItem={dataItem}
						dataItemIndex={dataItemIndex}
						updateFormData={updateFormData}
						userData={userData}
					/>
				);
			case 'number':
				return (
					<FormNumberInput
						item={item}
						dataItem={dataItem}
						dataItemIndex={dataItemIndex}
						updateFormData={updateFormData}
						userData={userData}
					/>
				);
			case 'dropdown':
				return (
					<FormDropdownInput
						item={item}
						dataItem={dataItem}
						dataItemIndex={dataItemIndex}
						updateFormData={updateFormData}
						userData={userData}
					/>
				);
			case 'checkbox':
				return (
					<FormCheckboxInput
						item={item}
						dataItemIndex={dataItemIndex}
						data={data}
						handleCheckboxChange={handleCheckboxChange}
						userData={userData}
					/>
				);
			case 'textarea':
				return (
					<FormTextareaInput
						item={item}
						dataItem={dataItem}
						dataItemIndex={dataItemIndex}
						updateFormData={updateFormData}
						userData={userData}
					/>
				);
			case 'rating':
				return (
					<FormRatingInput
						item={item}
						dataItem={dataItem}
						dataItemIndex={dataItemIndex}
						updateFormData={updateFormData}
						userData={userData}
					/>
				);
			case 'divider':
				return <FormDividerInput item={item} userData={userData} />;
			case 'html':
				return <FormHtmlInput item={item} userData={userData} />;
			case 'session':
				return (
					<FormSessionInput
						item={item}
						dataItem={dataItem}
						dataItemIndex={dataItemIndex}
						updateFormData={updateFormData}
						userData={userData}
						selectedSessions={selectedSessions}
					/>
				);
			default:
				return null;
		}
	};

	return (
		<div className={'registration-form-page'}>
			<SecondaryHeader
				backButtonText={'common.backButton'}
				backButtonClickHandler={() => history.goBack()}
				headerTranslationKey={getFormPropertyTranslation(eventForm?.title, userData.language)}
				disableBoxShadow={false}
			/>
			<div className="registration-form-content" ref={contentRef}>
				<Typography variant={'headline-large'}>{getFormPropertyTranslation(eventForm?.title, userData.language)}</Typography>
				<Typography variant={'body-medium'}>{getFormPropertyTranslation(eventForm?.description, userData.language)}</Typography>

				{data.length > 0 &&
					eventForm.items?.map((item) => {
						const dataItemIndex = data.findIndex((data) => data.key === item.uuid);
						const dataItem = data[dataItemIndex];
						return renderFormItem(item, dataItemIndex, dataItem);
					})}

				<div className={'placeholderForVirtualKeyboard'}></div>
			</div>
			<div className="button-fixed-at-bottom-static">
				<Button
					variant={'primary'}
					height={'regular'}
					width={'full'}
					translationKey={editSubmissionId ? 'eventForm.edit' : 'eventRegistrationForm.submit'}
					loading={false}
					onClick={editSubmissionId ? editEventFormSubmission : sendEventFormSubmission}
					disabled={!formDataValid}
					roundCorners={true}
				/>
			</div>
		</div>
	);
}

export default EventForm;
