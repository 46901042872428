import {useEffect, useState} from 'react';

import {Trans, useTranslation} from 'react-i18next';
import PrimaryInput from '../Common/Inputs/PrimaryInput';
import Icon from '../Common/Icon';
import Button from '../Common/Button';

//Help desk
const HelpLink = 'https://help.doorjames.com/index.php?a=add&category=14';

export const EnterEmail = (props) => {
	const privacyPolicyUrl = 'https://statics.infra.doorjames.com/documents/privacy_policy_demo.pdf';
	const {t} = useTranslation();
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const showPrivacyCheckbox = urlParams.get('showPrivacyCheckbox');
	const [isPrivacyRead, setIsPrivacyRead] = useState(false);

	const {error, setError} = props;

	const handleLogin = () => {
		setError('');
		props.setLoading(true);
		window.localStorage.setItem('loginEmail', props.email.toLocaleLowerCase());
		props.showLoginStrategies();
	};

	const checkEmptyString = (value) => {
		const whiteSpaceRegex = /\s/g;
		if (value === '') return true;
		return whiteSpaceRegex.test(value);
	};

	const isValidEmail = (value) => {
		if (value === null) return false;
		const emailRegex = /\S+@\S+\.\S+/;
		return emailRegex.test(value.toLocaleLowerCase());
	};

	const checkEmailAddress = (value) => {
		if (checkEmptyString(value)) {
			return false;
		} else if (isValidEmail(value)) {
			return true;
		} else {
			return false;
		}
	};

	let disabledButton = checkEmailAddress(props.email) ? false : true;
	if (!disabledButton && showPrivacyCheckbox) {
		disabledButton = !isPrivacyRead;
	}

	useEffect(() => {
		if (window.localStorage.getItem('loginEmail')) {
			props.setEmail(window.localStorage.getItem('loginEmail').toLocaleLowerCase());
		}
		if (!props.isPasswordLogin && window.localStorage.getItem('satelliteUrl')) {
			window.localStorage.removeItem('satelliteUrl');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleKeyPress = (key, input) => {
		if (key === 'Enter' && checkEmailAddress(input)) {
			setError('');
			handleLogin();
		}
	};

	const redirectToPrivacy = (e) => {
		e.stopPropagation();
		window.location.assign(privacyPolicyUrl);
	};

	const handleChange = (value) => {
		setError('');
		checkEmailAddress(value);
		props.setEmail(value);
	};

	return (
		<>
			<PrimaryInput
				id={'loginEmail'}
				type={'email'}
				cssClass={' emailWrapper'}
				placeholderKey={t('common.inputPlaceholder.email')}
				clickHandler={handleChange}
				defaultValue={props.email}
				inputCheck={(value) => checkEmailAddress(value)}
				required={true}
				onKeyPress={handleKeyPress}
				labelKey={t('common.inputPlaceholder.emailLabel')}
				hasError={error.length > 0}
				errorMessage={error}
			/>
			{props.isPasswordLogin && (
				<PrimaryInput
					id={'loginPasscode'}
					type={'password'}
					cssClass={' emailWrapper'}
					placeholderKey={t('common.inputPlaceholder.password')}
					clickHandler={(value) => props.setPassword(value)}
					defaultValue={props.password}
					required={true}
					onKeyPress={handleKeyPress}
					labelKey={t('common.inputPlaceholder.password')}
				/>
			)}
			{/* {error.length > 0 && (
				<div className={'error-container'}>
					<Icon cssClass={'error-icon-size icon-error'} iconName={'exclamation-circle'} />
					<p className={'error-info'}>{error}</p>
				</div>
			)} */}
			<div className={'buttonWrapper'}>
				{showPrivacyCheckbox === 'true' ? (
					<div className="checkbox-privacy-wrapper">
						<input
							type={'checkbox'}
							className={'input-checkbox'}
							id={'checkbox-privacy'}
							checked={isPrivacyRead}
							onChange={() => setIsPrivacyRead(!isPrivacyRead)}
						/>
						<label className={'input-checkbox-label'} htmlFor={'checkbox-privacy'}>
							<p>
								<Trans
									i18nKey="loginPage.privacyCheckbox"
									components={{
										span: (
											<span onClick={redirectToPrivacy} className="hyperlink">
												{' '}
											</span>
										),
									}}
								/>
							</p>
						</label>
					</div>
				) : null}
				<div className={'buttonWrapper'}>
					<Button
						cssClass={'loginButton'}
						variant={'primary'}
						width={'full'}
						translationKey={t('loginPage.loginButton')}
						onClick={handleLogin}
						disabled={disabledButton}
						loading={props.loading}
					/>
				</div>
			</div>
			<div className="page-login-not-login">
				<p>{t('loginPage.infoText.intro.notAbleToLogin')}</p>
				<a id="clickHereLink" href={HelpLink} target="_blank">
					{t('loginPage.infoText.intro.helpButtonText')}
				</a>
			</div>
		</>
	);
};
