
import ReactDOMServer from 'react-dom/server';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Icons} from '../../../constants/customIcons';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import {library} from '@fortawesome/fontawesome-svg-core';
library.add(fas, far);
/**
 *
 *  This component renders CUSTOM ICONS AND FONTAWESOME ICONS. CUSTOM ICONS ARE SAVED IN /CONSTANTS/CUSTOMICONS.js.
 *
 * @param {*} props.cssClass = Css class that will be added only to the FontAwesome Icon. Usually, this props is used to define the color of the icon. The classes that can be used are initialized in scss/elements/icons.scss
 * @param {*} props.iconColor = This holds the iconColor only for CUSTOM Icons. Can be HEX or normal css colors.
 * @param {*} props.iconName = The icon name to be shown. For custom icons, specify the name of the custom icons ( saved in customIcons.js ). For font awesome icons ONLY use the name of the icon. (e.g case: <i class="fa-solid fa-xmark"></i> iconName: 'xmark')
 * @param {*} props.type = The type of the fontawesome icon. (e.g ['far', 'fal', 'fas']). For more types update the fontawesomeTypeEnum
 * @param {*} props.size = The size of the icon, only used for fontawesome icons.
 * @param {*} props.clickHandler = Callback triggered by clicking on the icon.
 * @returns
 */
function Icon(props) {
	let cssClass = 'icon';

	const { style } = props;
	const customIcons = new Icons(props.iconColor ?? 'black').iconConfigTeakEntityFeatures;

	if (props.cssClass !== undefined) {
		cssClass += ' ' + props.cssClass;
	}

	const fontawesomeTypeEnum = ['far', 'fal', 'fas'];
	let iconName = props.type ? [props.type, props.iconName] : props.iconName;

	// please check the link https://fontawesome.com/v5.15/how-to-use/on-the-web/using-with/react
	// in future this can be changed to something smarter ;)
	if (!iconName) return null;
	if (iconName?.includes('fab')) {
		iconName = [props.iconName.substring(0, 3), props.iconName.substring(7)];
	} else if (fontawesomeTypeEnum.includes(props.iconName.substring(0, 3))) {
		iconName = [props.iconName.substring(0, 3), props.iconName.substring(7)];
	}

	if (customIcons[props.iconName]?.custom) {
		return (
			<div
				className={'custom-icon ' + cssClass}
				style={style}
				dangerouslySetInnerHTML={{
					__html: customIcons[props.iconName].custom,
				}}
				onClick={props.clickHandler}
			/>
		);
	} else if (customIcons[props.iconName]?.fontawesome) {
		return (
			<FontAwesomeIcon
				className={'custom-icon ' + cssClass}
				style={style}
				icon={customIcons[props.iconName]?.fontawesome ? customIcons[props.iconName]?.fontawesome : 'bullseye'}
				size={props.size}
				onClick={props.clickHandler}
			/>
		);
	}

	if (
		props.iconName === 'small' ||
		props.iconName === 'medium' ||
		props.iconName === 'large' ||
		props.iconName === 's' ||
		props.iconName === 'm' ||
		props.iconName === 'l'
	) {
		return <div className={cssClass + ' size-icon'}>{props.iconName.charAt(0).toUpperCase()}</div>;
	} else {
		return (
			<FontAwesomeIcon
				className={cssClass}
				style={style}
				icon={iconName === 'none' ? 'bullseye' : iconName} // some icons have iconName 'none' in DB
				size={props.size}
				onClick={props.clickHandler}></FontAwesomeIcon>
		);
	}
}

//check if the icon can be rendered or not
export function isIconValid(iconName) {
	return Boolean(ReactDOMServer.renderToStaticMarkup(<Icon iconName={iconName} />));
}

export function getTeakTypeIconName(iconName) {
	if (isIconValid(iconName)) {
		return iconName;
	} else {
		return 'customTeakType';
	}
}
export default Icon;
