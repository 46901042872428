import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Typography} from '../../Common/Typography';
import {DateTime} from 'luxon';
import {useTranslation} from 'react-i18next';
import FormattedDateTime from '../../Common/DateTimePicker/FormattedDateTime';
import FormattedTime from '../../Common/DateTimePicker/FormattedTime';
import {getSessionTitle} from '../EventPage/EventPage';

function SessionInfo({session}) {
    const userData = useSelector((state) => state.auth.data.userData);
	const sessionTitle = getSessionTitle(session, userData?.language)
    return (
        <div className='session-info-container'>
            <Typography variant="body-medium bold">{sessionTitle}</Typography>
            <Typography variant="body-medium">
                <FormattedDateTime dateTime={session?.start} /> - <FormattedTime time={session?.end} />
            </Typography>
        </div>
    )
}
export default SessionInfo