import { useRef, useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { getLoginUrl } from '../../api';
import { EnterEmail } from './EnterEmail';
import LoginStrategies from './LoginStrategies';
import { ROUTES } from '../../constants/routes';
import { getAuthContext, loginWithPassword } from '../../api/additionals';
import { isPhoneMode } from '../../functions/isPhoneMode';
import { enableBottomMenu } from '../../store/auth';
import { Copyright } from '../Common/LoadingScreen';
import CGIEventsHeader from '../Common/Header/CGIEventsHeader';

function LoginPage(props) {
	const { t, i18n } = useTranslation(); 
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const [loginStrategies] = useState([]);
	const [email, setEmail] = useState(null);
	const [password, setPassword] = useState(null);
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);
	const [isPasswordLogin, setIsPasswordLogin] = useState(location?.state?.isPasswordLogin);
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const organizationFromURL = urlParams.get('organization');
	const loginPageRef = useRef();

	useEffect(() => {
		dispatch(enableBottomMenu(false));
		passLanguageCodeToNativeApp(i18n.language)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const passLanguageCodeToNativeApp = (langCode) => {
		if (window.ReactNativeWebView) {
			window.ReactNativeWebView.postMessage(
				JSON.stringify({
					locale: langCode,
				}),
			);
		}
	};

	const loginUserWithPassword = async () => {
		setLoading(true);
		const organization = window.localStorage.getItem('organization');
		const queryString = `?email=${email.toLocaleLowerCase()}&organization=${organization}&passcode=${password}`;
		try {
			const response = await loginWithPassword(queryString);
			window.localStorage.setItem('memberID', response.data?.memberDoc?._id);
			window.location.assign(ROUTES.DEFAULT);
		} catch (error) {
			console.log(error);
			if (error.response?.status === 404) {
				setError('errorMessages.invalidEmail');
			} else {
				setError('errorMessages.genericErrorMessage');
			}
			setLoading(false);
		}
	};

	const getLoginStrategy = async () => {
		try {
			const authContext = await getAuthContext(email.toLocaleLowerCase(), organizationFromURL, email.toLocaleLowerCase().split('@')[1]);
			const data = authContext.data;
			if (data?.length > 1) {
				history.push({
					pathname: '/organizationSwitcher',
					state: { organizations: data, email: email.toLocaleLowerCase() },
				});
				return;
			}
			const organizationData = data[0];
			window.localStorage.setItem('loginStrategy', organizationData?.strategy);
			if (organizationData.satelliteUrl) window.localStorage.setItem('satelliteUrl', organizationData.satelliteUrl);
			if (organizationData.strategy === 'passcode') {
				window.localStorage.setItem('organization', organizationData._id);
				setLoading(false);
				setIsPasswordLogin(true);
				return;
			}

			if (organizationData.strategy === 'otp') {
				///Redirect to the OTP View
				history.push({
					pathname: ROUTES.VERIFY_OTP,
					state: {
						organization: organizationData._id,
					},
				});
			} else {
				try {
					const loginUrlResponse = await getLoginUrl(organizationData.strategy, email.toLocaleLowerCase());
					window.localStorage.setItem('organization', organizationData._id);
					window.location.assign(loginUrlResponse.data);
				} catch (error) {
					if (error.response?.data?.name) {
						setError(error.response?.data?.name);
					} else {
						setError('errorMessages.apiCalls.errorLoginUrl');
					}
					setLoading(false);
				}
			}
		} catch (error) {
			console.log(error);
			if (error.response?.status === 406) {
				setError('errorMessages.apiCalls.authErrorEmailNotFound');
			} else if (error.response?.data?.name) {
				setError(error.response?.data?.name);
			} else {
				setError('errorMessages.invalidEmail');
			}
			setLoading(false);
		}
	};

	return (
		<>
			<CGIEventsHeader />
			<div className={'page-login'} id="pageLogin" ref={loginPageRef}>
				<div className="page-login-header-wrapper">
					<div className="page-login-banner">
						<img rel="preload" className={'page-login-banner-image'} alt={'banner'} type="image/webp" src={process.env.PUBLIC_URL + '/images/cgi_login_banner.png'} />
					</div>
					<div className={'page-login-info'}>
						<div className={'page-login-info-headline'}>{t('loginPage.infoText.intro.headline')}</div>
					</div>
				</div>
				<div className="page-login-content">
					{loginStrategies.length > 0 ? (
						<LoginStrategies setError={setError} email={email.toLocaleLowerCase()} strategies={loginStrategies} />
					) : (
						<EnterEmail
							setError={setError}
							error={error}
							loading={loading}
							setLoading={setLoading}
							email={email}
							setEmail={setEmail}
							password={password}
							setPassword={setPassword}
							showLoginStrategies={isPasswordLogin ? loginUserWithPassword : getLoginStrategy}
							isPasswordLogin={isPasswordLogin}
						/>
					)}
				</div>
				<Copyright />
				<div className={'placeholderForVirtualKeyboard'}></div>
			</div>
		</>
	);
}

export default LoginPage;
