import axios from 'axios';
import apiUrl from './index';

const source = axios.CancelToken.source();
let config = {cancelToken: source.token};

export async function getTeakFeatureById(featureId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = hostUrl + apiUrl.path.teakFeature + '/' + featureId;
	return axios(
		{
			method: 'get',
			url: requestUrl,
		},
		config,
	);
}

export async function getManyTeakFeatures(officeId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.teakFeature + apiUrl.path.findMany;

	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				filters: {
					office: officeId,
				},
			},
		},
		config,
	);
}
