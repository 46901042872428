import {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {clearFormDataFromLocalStorage, getFormPropertyTranslation, nonEditableFieldTypes, saveFormDataToLocalStorage} from '.';
import colorBasedOnTheme from '../../functions/colorBasedOnTheme';
import {useSelector} from 'react-redux';
import SecondaryHeader from '../Common/Header/SecondaryHeader';
import Icon from '../Common/Icon';
import {useTranslation} from 'react-i18next';
import Button from '../Common/Button';
import {Typography} from '../Common/Typography';
import {ROUTES} from '../../constants/routes';
import {getEventFormSubmissions} from '../../api/event';
import {ClipLoader} from 'react-spinners';
import get from 'lodash/get';
import {getPropertyTranslation} from '../Event/EventSupport';

//if value mapping is enabled use the value from the attendeeData
//otherwise use the values from the last submitted form
export function syncMappedValuesWithAttendee(formSubmission, eventForm, attendeeData) {
	formSubmission?.values.forEach((value) => {
		const item = eventForm.items?.find((item) => item.uuid === value.key);
		if (item?.mappedValue) {
			let mappedValue = get(attendeeData, item.mappedValue) ?? attendeeData?.customData?.find((data) => data.key === item.mappedValue)?.value;

			value.value = mappedValue;
		}
	});
	return formSubmission;
}

function EventFormDetails() {
	const {t} = useTranslation();
	const location = useLocation();
	const history = useHistory();
	const colors = colorBasedOnTheme();
	const userData = useSelector((state) => state.auth.data.userData);
	const selectedEvent = useSelector((state) => state.events.selectedEvent);
	const attendeeData = useSelector((state) => state.events.attendeeData);
	const eventForms = useSelector((state) => state.events.forms);
	const [formSubmission, setFormSubmission] = useState();
	const [loading, setLoading] = useState();
	const eventForm = location?.state?.eventForm;
	const isRegistrationForm = eventForm._id === selectedEvent?.options?.registrationForm;
	const declineForm = eventForms?.find((form) => form._id === selectedEvent?.options?.declineForm);
	const allSessions = useSelector((state) => state.events.allSessions);

	useEffect(async () => {
		setLoading(true);
		try {
			const formSubmissionsResponse = await getEventFormSubmissions(selectedEvent._id, eventForm._id);
			const data = formSubmissionsResponse.data;
			let lastSubmission = data[data.length - 1];
			if (eventForm.valueMapping) {
				lastSubmission = syncMappedValuesWithAttendee(lastSubmission, eventForm, attendeeData);
			}
			setFormSubmission(lastSubmission);
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	}, []);

	function navigateToEditFormPage() {
		saveFormDataToLocalStorage(eventForm, formSubmission?.values);
		history.push(ROUTES.FORM, {eventForm, editSubmissionId: formSubmission._id});
	}

	function navigateToFormPage() {
		clearFormDataFromLocalStorage(eventForm);
		history.push(ROUTES.FORM, {eventForm});
	}

	function getContent(item, value) {
		if (item.type === 'session') {
			const selectedSession = allSessions.find((session) => session._id === value);
			if (selectedSession) {
				return getPropertyTranslation(selectedSession.name, userData.language);
			}
			return '';
		}

		if (Array.isArray(value)) {
			return value
				.map((val) => {
					const option = item.options?.find((option) => option.value === val);
					return option ? getFormPropertyTranslation(option.label, userData.language) : val;
				})
				.join(', ');
		} else {
			const option = item.options?.find((option) => option.value === value);
			return option ? getFormPropertyTranslation(option.label, userData.language) : value;
		}
	}

	return loading === true ? (
		<div className={'registration-form-details-wrapper-loading'}>
			<ClipLoader size={30} />
		</div>
	) : (
		<div className={'registration-form-details-profile-page'}>
			<div className={'registration-form-details-profile-page-header'}>
				<SecondaryHeader
					backButtonClickHandler={() => history.goBack()}
					headerTranslationKey={getFormPropertyTranslation(eventForm?.title, userData.language)}
					disableBoxShadow={false}
					childNextToHeader={
						eventForm.editAfterSubmit ? (
							<div className="edit-form" onClick={navigateToEditFormPage}>
								<Icon iconName={'editIcon'} iconColor={colors.colorNeutral50} />
								<Typography variant={'title-medium'}>{t('profile.headers.edit')}</Typography>
							</div>
						) : (
							<></>
						)
					}
				/>
			</div>

			<div className={'registration-form-details-profile-page-content'}>
				<ul>
					{eventForm?.items
						.filter((item) => !nonEditableFieldTypes.includes(item.type)) // Filter out non-editable fields
						.map((item) => {
							const header = getFormPropertyTranslation(item.label, userData.language);
							const value = formSubmission?.values?.find((dataItem) => dataItem.key === item.uuid)?.value;
							const content = getContent(item, value);

							const requiredLabel = item.required ? '*' : '';
							return (
								content && (
									<li key={item.uuid}>
										<p className="header">
											{header}
											{requiredLabel}
										</p>
										<p className="content">{content}</p>
									</li>
								)
							);
						})}
				</ul>
			</div>
			<div className="button-fixed-at-bottom-static">
				{eventForm.multiSubmit && (
					<Button
						variant={'empty'}
						height={'regular'}
						width={'full'}
						translationKey={'eventForm.fillOutAgain'}
						loading={false}
						onClick={navigateToFormPage}
						roundCorners={true}
					/>
				)}
				{isRegistrationForm && declineForm && (
					<Button
						variant={'empty'}
						height={'regular'}
						width={'full'}
						translationKey={'eventRegistrationForm.iCannotAttend'}
						loading={false}
						onClick={() => history.push(ROUTES.FORM, {eventForm: declineForm})}
						roundCorners={true}
					/>
				)}
			</div>
		</div>
	);
}

export default EventFormDetails;
