export const ROUTES = {
	DEFAULT: '/',
	START_TOUR: '/startTour',
	FINISHED_TOUR: '/finishTour',
	BOOKING: '/booking',
	BOOKINGS_OVERVIEW: '/bookingsOverview',
	EDIT_BOOKING: '/editBooking',
	OFFICE_INFO_PAGE: '/officeInfo',
	SETTINGS: '/settings',
	BADGE: '/badge',
	STATS_AND_BADGES: '/statsAndBadges',
	BADGES: '/badges',
	STATS: '/stats',
	UNLOCK_NOTIFICATION: '/unlock',
	REPORT_SICK_PAGE: '/reportSick',
	COVID_STATUS_PAGE: '/covidStatus',
	PROFILE_PAGE: '/profile',
	LOGIN: '/login',
	CHANGE_LANGUAGE: '/changeLanguage',
	DESK_OVERVIEW: '/office/:officeId/teakEntity/:entityId',
	PROFILE_BADGE: '/profile/badge',
	QR_SCANNER: '/eventQRScanner',
	QUESTIONNER_PAGE: '/questionnaire',
	SHORT_URL_REDIRECT: '/teakEntity/findByIdentifier',
	PRIVACY_POLICY: '/dynamic/privacyPolicy?office',
	REPORT_PROBLEM: '/reportProblem',
	COMMON_AREA_PROBLEM: '/commonAreaProblem',
	FACILITY_PROBLEM: '/facilityProblem',
	APP_TECHNICAL_PROBLEM: '/appTechnicalProblem',
	DESK_PROBLEM: '/deskProblem',
	DESK_PROBLEM_DETAILS: '/deskProblemDetails',
	MEETING_ROOM_PROBLEM: '/meetingRoomProblem',
	MEETING_ROOM_PROBLEM_DETAILS: '/meetingRoomProblemDetails',
	REPORT_PROBLEM_QR_SCANNER: '/reportProblemQRScanner',
	OTHER_PROBLEM: '/otherProblem',
	USER_REVIEW: '/userReview',
	REPEAT_BOOKING: '/repeatBooking',
	NO_CERTIFICATE_FOR_CHECKIN: '/noCertificateAddedForCheckin',
	FAVORITES_PAGE: '/favorites',
	VISITOR: {
		NEW_MEETING: '/newMeeting',
		SHOW_ALL: '/showAllVisitors',
		EDIT_VISITOR: '/editVisitor',
		NEW_VISITOR: '/newVisitor',
		ALL_VISITORS: '/allVisitors',
		MEETING_SUMMARY: '/meetingSummary',
		UNSCHEDULED_VISITORS: '/unscheduledVisitors',
	},
	APP_SETTINGS_PAGE: '/appSettingsPage',
	HELP_SETTINGS_PAGE: '/helpSettingsPage',
	LANGUAGE_SETTINGS_PAGE: '/languageSettingsPage',
	BOOKING_SETTINGS_PAGE: '/bookingSettingsPage',
	DATA_PRIVACY_SETTINGS_PAGE: '/dataPrivacyPage',
	FAQ_SETTINGS_PAGE: '/faqSettingsPage',
	COLLEAGUE_LIST: '/colleagueList',
	NOTIFICATIONS: '/notifications',
	BOOKING_CONFIRM: '/bookingConfirm',
	SEE_DETAILS_OF_BOOKING: '/bookingDetails',
	OFFICE_SWITCHER: '/officeSwitcher',
	STATISTICS_INFO_PAGE: '/statisticsInfo',
	CHECK_AVAILABILITY: '/checkAvailability',
	MAP_VIEW: '/mapView',
	SUCCESS_PAYMENT_REDIRECT: '/successPaymentForBooking',
	VERIFY_OTP: '/verifyOTP',
	PROFILE_COMPLETION: '/profile_completion',
	OFFICE_SELECTION: '/office_selection',
	AREA_LIST: '/areaListPage',
	FEATURES_LIST: '/featuresListPage',
	BOOKING_PAGE: '/bookingPage',
	FAVORITES_BOOKING_PAGE: '/favoritesBooking',
	COLLEAGUE_PAGE: '/colleaguePage',
	TEAMS: '/teams',
	ERROR_TEAMS_LOGIN: '/errorTeamsLogin',
	REQUEST_FEATURE: '/requestFeature',
	MY_REPORTS: '/myReports',
	REPORT_DETAILS: '/reportDetails',
	ASPEN_LIST: '/aspenItemList',
	ASPEN_BOOKING: '/aspenBooking',
	ASPEN_DETAILS: '/aspenBookingDetails',
	AGENDA: '/agenda',
	ADD_AGENDA_ITEM: '/agendaItem',
	EVENT_INTRO: '/eventIntro',
	EVENT: '/event',
	EXTERNAL_LINK: '/externalLink',
	SESSION_DETAILS: '/sessionDetails',
	ASK_QUESTION: '/askQuestion',
	RATE_SESSION: '/rateSession',
	MEMBER_PROFILE: '/memberProfile',
	EDIT_MEMBER_PROFILE: '/editMemberProfile',
	EVENT_AGENDA: '/eventAgenda',
	EVENT_SUPPORT: '/eventSupport',
	EVENT_ATTENDEES: '/eventAttendees',
	EVENT_ATTENDEE_PAGE: '/attendee',
	SPEAKER_PAGE: '/speaker',
	BOOKABLE_SESSION: '/bookableSession',
	OFFICE_ELEMENT: '/oe',
	LEADERSHIP_BOARD: '/leadershipBoard',
	ENTITY_DETAILS: '/entityDetails',
	INVITATIONS: '/invitations',
	REGISTRATION_APPROVED: '/registrationApproved',
	REGISTRATION_DECLINED: '/registrationDeclined',
	FORM: '/form',
	FORM_DETAILS: '/formDetails'
};
