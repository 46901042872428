

import { useTranslation } from 'react-i18next';
import { Typography } from '../../Typography';
/**
 *
 * @param {*} props.cssClass = css class added to the button element
 * @param {*} props.disabled = if the button is disabled or not
 * @param {*} props.clickHandler = callback for button click
 * @param {*} props.closeButtonText = text for the closing icon on the top
 * @returns
 */
function ButtonClose(props) {
	const { t } = useTranslation();

	return (
		<div className={'close-button'} onClick={props.clickHandler}>
			<Typography variant={'title-medium'}>
				{t(props.closeButtonText.length > 0 ? props.closeButtonText : 'common.closeButton')}
			</Typography>
		</div>
	);
}

export default ButtonClose;
