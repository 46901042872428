import  {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import {ROUTES} from '../constants/routes';
import useCustomNavigation from '../hooks/useCustomNavigation';
import {getLoginUrl} from '../api';
import Button from './Common/Button';
import SecondaryHeader from './Common/Header/SecondaryHeader';
import djLogo from '../image/doorjames_logo.svg';

function OrganizationSwitcher() {
	const {t} = useTranslation();
	const navigation = useCustomNavigation();
	const location = useLocation();
	const [loading, setLoading] = useState(false);
	const [organization, setOrganizaiton] = useState();
	const [error, setError] = useState();
	const organizations = location.state?.organizations;
	const email = location.state?.email;

	function loginIntoOrg(organization) {
		if (loading) return;
		if (error) setError();
		setOrganizaiton(organization);
	}
	async function confirmOrganization() {
		window.localStorage.setItem('loginStrategy', organization.strategy);
		if (organization.satelliteUrl) window.localStorage.setItem('satelliteUrl', organization.satelliteUrl);

		if (organization.strategy === 'passcode') {
			window.localStorage.setItem('organization', organization._id);
			navigation.push(ROUTES.LOGIN, {isPasswordLogin: true});
			return;
		}
		if (organization.strategy === 'otp') {
			///Redirect to the OTP View
			navigation.push({
				pathname: ROUTES.VERIFY_OTP,
				state: {
					organization: organization._id,
				},
			});
		}
		setLoading(true);
		try {
			const loginUrlResponse = await getLoginUrl(organization.strategy, email);
			window.localStorage.setItem('organization', organization._id);
			window.location.assign(loginUrlResponse.data);
		} catch (error) {
			console.log(error);
			if (error.response?.data?.name) {
				setError(error.response?.data?.name);
			} else {
				setError(t('errorMessages.apiCalls.errorLoginUrl'));
			}
			setLoading(false);
		}
	}

	if (!organizations) {
		navigation.goBack();
		return null;
	}

	return (
		<div className="organization-switcher-page">
			<div className="organization-switcher-page-header">
				<SecondaryHeader backButtonClickHandler={() => navigation.goBack()} disableBoxShadow={true} />
			</div>
			<div className="organization-switcher-page-content">
				<p className="organization-switcher-page-content-title">{t('organizationSwitcher.header')}</p>
				<p className="organization-switcher-page-content-info">{t('organizationSwitcher.info')}</p>
				{organizations.map((org) => (
					<div
						key={org?._id}
						className={`organization-switcher-page-content-option ${
							organization && organization?._id === org?._id && 'organization-switcher-page-content-option-active'
						}`}
						onClick={() => loginIntoOrg(org)}>
						<img src={org?.branding?.logoUrl ? org?.branding?.logoUrl : djLogo}></img>
					</div>
				))}
			</div>
			{error && <p className="organization-login-error">{error}</p>}
			<div className="button-fixed-at-bottom-static">
				<Button
					variant={'primary'}
					height={'regular'}
					width={'full'}
					disabled={!organization}
					loading={loading}
					translationKey={'common.buttons.confirm'}
					onClick={confirmOrganization}
				/>
			</div>
		</div>
	);
}
export default OrganizationSwitcher;
