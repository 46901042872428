import {getAreasFromOffice, getAspenTypes, getManyTeakFeatures, getManyTeakTypes} from '../api';
import {errorReceived} from '../store/auth';

/**
 * This function receives as parameters data that is already in store.
 * Its scope is to get new data for the new office and add it (together with what was already in) to the store.
 *
 * @param {function} dispatch = function used to dispatch actions
 * @param {Array} areasFromStore = array of areas already in store.
 * @param {Array} areasTreeListFromStore = tree structure of areas that are already in store.
 * @param {Array} officesFromStore = array of offices already in store.
 * @param {Array} teakTypeArrayFromStore = array of teak types already in store.
 * @param {Array} teakFeaturesArrayFromStore = array of teak features already in store.
 * @param {Array} aspenTypesFromStore = array of aspen types already in store.
 * @param {id} office
 * @returns
 */
async function getDataFromAPIForOffice(
	dispatch,
	areasFromStore,
	areasTreeListFromStore,
	officesFromStore,
	teakTypeArrayFromStore,
	teakFeaturesArrayFromStore,
	aspenTypesFromStore,
	office,
) {
	let areasList = areasFromStore.slice();
	let areasTreeList = areasTreeListFromStore.slice();
	const teakTypeArray = teakTypeArrayFromStore.slice();
	let teakFeaturesArray = teakFeaturesArrayFromStore.slice();
	let officesList = officesFromStore.slice();
	let aspenTypes = aspenTypesFromStore.slice();
	const newSelectedOfficeIndex = officesList.findIndex((element) => element._id === office._id);
	try {
		const areaResponse = await getAreasFromOffice(office._id);

		areasTreeList.push({officeId: office._id, areas: areaResponse.data});

		if (areaResponse.data?.length > 0) {
			function getFlatAreaList(areas) {
				let areaFlatList = [];
				areas.forEach((area) => {
					areaFlatList.push(area);
					if (area?.children?.length > 0) {
						areaFlatList.push(...getFlatAreaList(area.children));
					}
				});

				return areaFlatList;
			}

			areasList.push(...getFlatAreaList(areaResponse.data));
		}
	} catch (error) {
		dispatch({
			type: errorReceived.type,
			payload: 'errorMessages.apiCalls.area',
		});
	}

	try {
		const manyFeaturesForOfficeResponse = await getManyTeakFeatures(office._id);
		if (manyFeaturesForOfficeResponse?.data && manyFeaturesForOfficeResponse?.data?.length > 0) {
			manyFeaturesForOfficeResponse.data.forEach((feature) => teakFeaturesArray.push({...feature, id: feature._id}));
		}
	} catch (error) {
		dispatch({
			type: errorReceived.type,
			payload: 'errorMessages.apiCalls.teakFeatures',
		});
	}

	try {
		const teakTypesPerOffice = {
			office: office._id,
			teakTypes: [],
		};

		const teakTypeResponse = await getManyTeakTypes(office._id);

		teakTypesPerOffice.teakTypes = teakTypeResponse.data;

		teakTypeArray.push(teakTypesPerOffice);
	} catch (error) {
		dispatch({
			type: errorReceived.type,
			payload: 'errorMessages.apiCalls.teakTypes',
		});
	}

	try {
		const aspenTypeResponse = await getAspenTypes(office._id);
		if (aspenTypeResponse.data)
			aspenTypes.push({
				officeId: office._id,
				types: aspenTypeResponse.data,
			});
	} catch (error) {
		console.log(error);
	}
	officesList[newSelectedOfficeIndex] = {
		...officesList[newSelectedOfficeIndex],
		hasAllData: true,
	};

	return {
		offices: officesList,
		areas: areasList,
		areasTreeList: areasTreeList,
		teakTypeArray: teakTypeArray,
		teakFeatures: teakFeaturesArray,
		aspenTypes,
	};
}
export {getDataFromAPIForOffice};
