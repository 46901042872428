import {createSlice} from '@reduxjs/toolkit';
import {
	fetchBoothsForEvent,
	getSelf,
	getEventMapOutline,
	getEventBanner,
	getEvents,
	getSessionsInEventWhereUserIsAttendee,
	getEventInvitations,
	getSessionsInEvent,
	getEventForms,
	getEventFormSubmissions,
} from '../api/event';
import {backgroundImageUpdated, enableBottomCard, mapLoadingReceived, updateBottomCardData, updateHighlightBoothData} from './bookingProcess/mapView';
import backgroundImage from '../image/event-background.png';
import {isFormDataValid} from '../components/EventForm';
import {syncMappedValuesWithAttendee} from '../components/EventForm/EventFormDetails';

const slice = createSlice({
	name: 'events',
	initialState: {
		eventList: null,
		sessions: null,
		loadingData: false,
		userCheckedIntoEvent: false,
		agendaPosition: null,
		selectedAttendeeFilters: [],
		eventInvitations: null,
		banners: {},
	},
	reducers: {
		eventsUpdated: (events, action) => {
			events.eventList = action.payload;
		},
		selectedEventUpdated: (events, action) => {
			events.selectedEvent = action.payload;
		},
		sessionsUpdated: (events, action) => {
			events.sessions = action.payload;
			events.loadingData = false;
		},
		allSessionsUpdated: (events, action) => {
			events.allSessions = action.payload;
		},
		loadingDataChanged: (events, action) => {
			events.loadingData = action.payload;
		},
		userCheckedIntoEventChanged: (events, action) => {
			events.userCheckedIntoEvent = action.payload;
		},
		agendaPositionChanged: (events, action) => {
			events.agendaPosition = action.payload;
		},
		selectedAttendeeFiltersChanged: (events, action) => {
			events.selectedAttendeeFilters = action.payload;
		},
		boothsListUpdated: (events, action) => {
			events.booths = action.payload;
		},
		attendeeDataUpdated: (events, action) => {
			events.attendeeData = action.payload;
		},
		eventInvitationsUpdated: (events, action) => {
			events.eventInvitations = action.payload;
		},
		profileCompletedUpdated: (events, action) => {
			events.profileCompleted = action.payload;
		},
		formsUpdated: (events, action) => {
			events.forms = action.payload;
		},
		bannersUpdated: (events, action) => {
			events.banners = action.payload;
		},
	},
});

export const {
	eventsUpdated,
	selectedEventUpdated,
	sessionsUpdated,
	allSessionsUpdated,
	loadingDataChanged,
	userCheckedIntoEventChanged,
	agendaPositionChanged,
	selectedAttendeeFiltersChanged,
	boothsListUpdated,
	attendeeDataUpdated,
	eventInvitationsUpdated,
	profileCompletedUpdated,
	formsUpdated,
	bannersUpdated,
} = slice.actions;

export default slice.reducer;

export const loadEventList = (eventId, callback) => async (dispatch, getState) => {
	try {
		const eventsResponse = await getEvents();
		dispatch({type: eventsUpdated.type, payload: eventsResponse.data});
		const selectedEvent = eventsResponse?.data?.find((event) => event?._id === eventId);
		if (selectedEvent) {
			dispatch(setSelectedEvent(selectedEvent, callback));
		} else {
			callback(eventsResponse?.data);
		}
	} catch (error) {
		console.log(error);
	}
};

export const setSelectedEvent = (event, callback) => async (dispatch, getState) => {
	try {
		dispatch({type: selectedEventUpdated.type, payload: event});
		await dispatch(setEventAttendee(event));
		if (callback) callback(event);
	} catch (error) {
		console.log(error);
	}
};

export const loadEventSessionsOfUser = (eventId) => async (dispatch, getState) => {
	if (!eventId) return;
	dispatch({type: loadingDataChanged.type, payload: true});
	try {
		const sessionsResponse = await getSessionsInEventWhereUserIsAttendee(eventId);
		dispatch({type: sessionsUpdated.type, payload: sessionsResponse.data});

		const allSessions = await getSessionsInEvent(eventId);
		dispatch({type: allSessionsUpdated.type, payload: allSessions.data});
	} catch (error) {
		console.log(error);
	}
};

export const loadEventForms = (eventId) => async (dispatch, getState) => {
	if (!eventId) return;
	dispatch({type: loadingDataChanged.type, payload: true});
	try {
		const formsResponse = await getEventForms(eventId);
		dispatch({type: formsUpdated.type, payload: formsResponse.data});
		return formsResponse.data;
	} catch (error) {
		console.log(error);
	}
};

export const checkIfUserIsCheckedIntoEvent = (event) => async (dispatch, getState) => {
	if (!event?._id) return;
	try {
		if (!event?.options?.checkInProcess?.enabled) {
			dispatch({type: userCheckedIntoEventChanged.type, payload: true});
			return;
		}
		const attendeeResponse = await dispatch(setEventAttendee(event));
		if (attendeeResponse?.data?.checkedIn) {
			dispatch({type: userCheckedIntoEventChanged.type, payload: true});
		} else {
			dispatch({type: userCheckedIntoEventChanged.type, payload: false});
		}
	} catch (error) {
		console.log(error);
		dispatch({type: userCheckedIntoEventChanged.type, payload: false});
	}
};

export const setAgendaPosition = (position) => async (dispatch, getState) => {
	try {
		dispatch({type: agendaPositionChanged.type, payload: position});
	} catch (error) {
		console.log(error);
	}
};

export const updateSelectedAttendeeFilters = (selectedFilters) => async (dispatch, getState) => {
	try {
		dispatch({type: selectedAttendeeFiltersChanged.type, payload: selectedFilters});
	} catch (error) {
		console.log(error);
	}
};

export const loadEventBackgroundImage = (eventId) => async (dispatch, getState) => {
	dispatch({type: mapLoadingReceived.type, payload: true});

	const selectedEvent = getState().events.selectedEvent;
	let mapUrl = 'no-map';
	const finishLoadMap = () => {
		dispatch({type: backgroundImageUpdated.type, payload: mapUrl});
		dispatch({type: mapLoadingReceived.type, payload: false});
	};

	if (!selectedEvent?.mapUrl) {
		getEventMapOutline(selectedEvent._id)
			.then((response) => {
				if (response.status === 204) {
					throw Error('not found');
				}
				mapUrl = window.URL.createObjectURL(new Blob([response.data]));
				finishLoadMap();
			})
			.catch((error) => {
				mapUrl = 'no-map';
				finishLoadMap();
			});
	} else if (selectedEvent?.mapUrl && selectedEvent?.mapUrl !== 'no-map') {
		mapUrl = selectedEvent?.mapUrl;
		finishLoadMap();
	} else if (selectedEvent?.mapUrl === 'no-map') {
		finishLoadMap();
	}
};

export const showBoothOnMap = (booth, bottomCardType) => async (dispatch, getState) => {
	const selectedEvent = getState().events.selectedEvent;
	dispatch(addBoothsForSelectedEvent(selectedEvent._id));
	dispatch(updateHighlightBoothData({entity: booth}));

	dispatch(enableBottomCard(bottomCardType));
	dispatch(updateBottomCardData({entity: booth}));
};

export const addBoothsForSelectedEvent = (eventId) => async (dispatch, getState) => {
	dispatch(loadEventBackgroundImage(eventId));
	dispatch(getBoothsForEvent(eventId));
};

export const getBoothsForEvent = (eventId) => async (dispatch, getState) => {
	try {
		const booths = await fetchBoothsForEvent(eventId);
		dispatch({type: boothsListUpdated.type, payload: booths.data});
	} catch (error) {
		console.log(error);
	}
};

export const updateEventMapUrl = (mapUrl) => async (dispatch, getState) => {
	const selectedEvent = getState().events.selectedEvent;
	const {...copyObject} = selectedEvent;
	copyObject.mapUrl = mapUrl;
	dispatch({type: selectedEventUpdated.type, payload: copyObject});
};

export const loadEventBanner = (eventId) => async (dispatch, getState) => {
    const state = getState();
    const eventInvitations = state.events.eventInvitations;
    const banners = state.events.banners;

    // Check if the event exists in the invitations
    const invitationIndex = eventInvitations.findIndex((invitation) => invitation?.wengeEvent?._id === eventId);
    if (invitationIndex === -1) return backgroundImage;

    // If the banner is already fetched or being fetched, return it
    if (banners[eventId]) {
        return banners[eventId];
    }

    try {
        const response = await getEventBanner(eventId);

        let banner;
        if (response.status === 200) {
            banner = window.URL.createObjectURL(new Blob([response.data]));
        } else {
            banner = backgroundImage;
        }

        // Get the latest state before updating
        const latestBanners = {...getState().events.banners, [eventId]: banner};
        dispatch({type: bannersUpdated.type, payload: latestBanners});

        return banner; // Return the banner URL
    } catch (error) {
        console.log(error);
        return null; // Return null in case of error
    }
};

export const setEventAttendee = (event) => async (dispatch, getState) => {
	if (event) {
		const attendeeResponse = await getSelf(event._id);
		dispatch({type: attendeeDataUpdated.type, payload: attendeeResponse.data});
		return attendeeResponse;
	} else {
		dispatch({type: attendeeDataUpdated.type, payload: null});
	}
};

export const addEventInvitations = () => async (dispatch, getState) => {
	const userData = getState().auth.data.userData;
	try {
		const eventInvitationsResponse = await getEventInvitations(userData.email);
		const invitationList = eventInvitationsResponse.data;
		dispatch({type: eventInvitationsUpdated.type, payload: invitationList});
	} catch (error) {
		console.log(error);
	}
};

export const setProfileCompleted = () => async (dispatch, getState) => {
	const eventForms = getState().events.forms;
	const selectedEvent = getState().events.selectedEvent;
	const attendeeData = getState().events.attendeeData;
	const registrationForm = eventForms?.find((form) => form._id === selectedEvent?.options?.registrationForm);
	if (!registrationForm) {
		dispatch({type: profileCompletedUpdated.type, payload: true});
		return true;
	}

	const formSubmissionsResponse = await getEventFormSubmissions(selectedEvent._id, registrationForm._id);
	const data = formSubmissionsResponse.data;
	let lastSubmission = data[data.length - 1];

	if (!lastSubmission) {
		dispatch({type: profileCompletedUpdated.type, payload: false});
		return lastSubmission;
	}

	if (registrationForm.valueMapping) {
		lastSubmission = syncMappedValuesWithAttendee(lastSubmission, registrationForm, attendeeData);
	}

	const lastSubmissionValid = isFormDataValid(registrationForm, lastSubmission.values);
	if (!lastSubmissionValid) {
		dispatch({type: profileCompletedUpdated.type, payload: false});
		return lastSubmission;
	}

	dispatch({type: profileCompletedUpdated.type, payload: true});
	return lastSubmission;
};
