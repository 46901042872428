
import {useDispatch} from 'react-redux';

import {enableCard} from '../../../store/cards';

function MemberCardPlaceholder(props) {
	const dispatch = useDispatch();

	const closeCard = () => {
		dispatch(enableCard(false));
	};

	return (
		<div className={'card'}>
			<div className={'close-card'}>
				<span className="close" onClick={closeCard}>
					&times;
				</span>
			</div>
			<div className={'card-content'}>
				<div className={'member-card-placeholder'}>
					<div className="ph-item">
						<div className="ph-picture"></div>
						<div className="ph-row">
							<div className="ph-col-12"></div>
							<div className="ph-col-12"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default MemberCardPlaceholder;
