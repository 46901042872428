import {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';

import './scss/styles.scss';
import configureStore from './store/configureStore';

import './i18n';
import AppRoutes from './routes/routes';
import {ErrorBoundary} from './ErrorBoundary';

const store = configureStore();

window.addEventListener('online', handleConnection);
window.addEventListener('offline', handleConnection);

function isServerReacheable(url) {
	return fetch(url, {method: 'HEAD', mode: 'no-cors'})
		.then(function (resp) {
			return resp && (resp.ok || resp.type === 'opaque');
		})
		.catch(function (err) {
			console.warn('[connection to server failed]:', err);
		});
}

function handleConnection() {
	if (navigator.onLine) {
		isServerReacheable(process.env.REACT_APP_ENGINE_HOST_URL).then((online) => {
			if (online) {
				console.log('online');
				ReactDOM.render(
					<Suspense
						fallback={
							<>
								<div className="loading-page">
									<div className="loading-logo-wrapper">
										<img className="logo" alt="logo" src={process.env.PUBLIC_URL + '/images/cgi_logo.svg'} />
									</div>
									<div className="cgi-logo-wrapper">
										<p className="solutionBy">A solution by</p>
										<img className="cgi-logo" alt="logo" src={process.env.PUBLIC_URL + '/images/cgi_logo.svg'} />
									</div>
									<p className="copyright-text">© 2024 CGI. All rights reserved.</p>
								</div>
							</>
						}>
						<Provider store={store}>
							<ErrorBoundary>
								<AppRoutes />
							</ErrorBoundary>
						</Provider>
					</Suspense>,
					document.getElementById('root'),
				);
			} else {
				console.log('no-server');
				ReactDOM.render(
					<div className={'loading-fallback'}>
						<p>no server connection</p>{' '}
					</div>,
					document.getElementById('root'),
				);
			}
		});
	} else {
		console.log('offline');
		ReactDOM.render(
			<div className={'loading-fallback'}>
				<p>no network connection</p>{' '}
			</div>,
			document.getElementById('root'),
		);
	}
}

handleConnection();

// Workaround do not register a service worker when on an mobile safari browser -> as this will lead to a crash
var iOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
if ('serviceWorker' in navigator && !iOS) {
	navigator.serviceWorker
		.register('/sw.js')
		.then(function (reg) {
			if (reg.installing) {
				console.log('Service worker installing');
			} else if (reg.waiting) {
				console.log('Service worker installed');
			} else if (reg.active) {
				console.log('Service worker active');
			}
		})
		.catch(function (error) {
			// registration failed
			console.log('Registration failed with ' + error);
		});
}
