import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Icon from '../../Common/Icon';
import {enableCard} from '../../../store/cards';
import Button from '../../Common/Button';
import colorBasedOnTheme from '../../../functions/colorBasedOnTheme';

function BroadcastNotificationCard(props) {
	const dispatch = useDispatch();
	const notificationList = useSelector((state) => state.cards.data);
	const [notification, setNotification] = useState();
	const header = notification?.data?.content[0]?.header;
	const body = notification?.data?.content[0].body;
	const icon = notification?.data?.icon;
	const colors = colorBasedOnTheme();

	useEffect(() => {
		setNotification({data: notificationList[0], index: 0});
	}, [notificationList]);
	const closeCard = () => {
		dispatch(enableCard(false));
	};

	function moveToNextNotification() {
		if (notification.index === notificationList.length - 1) {
			closeCard();
			return;
		}
		const nextNotificationIndex = notification.index + 1;
		setNotification({data: notificationList[nextNotificationIndex], index: nextNotificationIndex});
	}
	return (
		<div className={'card broadcast-notification'}>
			<div className={'close-card'}>
				<span className="close" onClick={closeCard}>
					&times;
				</span>
			</div>
			<div className="confirmation-wrapper">
				<div className="broadcast-notification-icon">
					{<Icon cssClass={'icon-purple'} iconColor={colors.colorMainTheme} iconName={icon} />}
				</div>
				<div className="broadcast-notification-content">
					<p className="header">{header}</p>
					<p className="text">{body}</p>
				</div>
				<div className={'buttons-wrapper'}>
					<Button
						variant={'primary'}
						height={'regular'}
						width={'full'}
						onClick={moveToNextNotification}
						translationKey={'common.okButton'}
					/>
				</div>
				{notificationList?.length > 1 && (
					<p className="broadcast-notification-counter">{`${notification?.index + 1}/${notificationList?.length}`}</p>
				)}
				{/* {!eventNotification && <p className={'grayed-text'}>{t('common.adminInfoText')}</p>} */}
			</div>
		</div>
	);
}

export default BroadcastNotificationCard;
