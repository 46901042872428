import {useTranslation} from 'react-i18next';
import LanguageSwitcher from '../../ProfilePage/Settings/AppSettings/LanguageSwitcher';
import {Typography} from '../Typography';
import {useSelector} from 'react-redux';
import {ROUTES} from '../../../constants/routes';
import {useHistory} from 'react-router';
import Icon from '../Icon';
/**
 * @param leftComponent = custom component which will be placed left of CGI Logo
 * @param rightComponent = custom component which will be placed right of CGI Logo
 * @param middleComponent = custom component which can replace CGI Logo. Default is always CGI Logo.
 * @returns CGI events header
 */

function CGIEventsHeader({leftComponent, middleComponent = null, rightComponent}) {
	const {t} = useTranslation();
	const history = useHistory();
	const event = useSelector((state) => state.events.selectedEvent);
	const profileCompleted = useSelector((state) => state.events.profileCompleted);
	const profilePhoto = useSelector((state) => state.auth?.data?.userData?.photo);
	const memberId = localStorage.getItem('memberID');
	const isInsideEventRoute = history.location.pathname.startsWith('/event');

	const redirectToProfilePage = () => {
		history.push(ROUTES.PROFILE_PAGE);
	};

	return (
		<div className="cgi-events-header">
			{leftComponent}
			{middleComponent == null ? (
				<div className="cgi-events-header-logo-wrapper">
					<img className={'logo'} alt={'logo'} src={process.env.PUBLIC_URL + '/images/cgi_logo_ls.svg'} />
					<div className="vertical-line"></div>
					<Typography variant="title-medium event-title">{t('event.header')}</Typography>
				</div>
			) : (
				<>
					{middleComponent}
				</>
			)}
			<div className="profile-container">
				<div className="cgi-event-header-language-switcher">
					<LanguageSwitcher />
				</div>
				{(memberId && profilePhoto && event && isInsideEventRoute) &&
					<>
						<div className="vertical-line"></div>
						<div className="header-profile">
							<div className="profile-button" onClick={redirectToProfilePage}>
								{profileCompleted === false &&
									<Icon iconName="exclamation-circle" cssClass={'warning-icon red-dot'} />
								}
								<img src={profilePhoto} />
							</div>
						</div>
					</>
				}
				{rightComponent}
			</div>

		</div>
	);
}

export default CGIEventsHeader;
