import {useTranslation} from 'react-i18next';
import Select, {components} from 'react-select';
import colorBasedOnTheme from '../../../functions/colorBasedOnTheme';
import Icon from '../Icon';
import {useState} from 'react';
import {handleFocus} from '../../../functions/handleFocus';
/**
 *
 * @param {Object} options = options shown in the dropdown
 * @param {Object} value = current value selected -> needs to be one of the options
 * @param {string} labelKey = translation key for label
 * @param {boolean} isSearchable = if the dropdown is searchable
 * @param {function} onChange = callback when a new option is selected
 * @param {string} placeholder = translation key for the placeholder => only if there is no preselected value
 * @param {boolean} disabled = if the dropdown is disabled or not
 * @param {id} id = id added to the wrapper of the dropdown
 * @param {string} className = className added to the wrapper of the dropdown
 * @param {boolean} required = if the * is shown after label
 * @returns
 */
function Dropdown({
	options,
	value,
	labelKey,
	isSearchable = false,
	onChange,
	placeholder = '',
	disabled = false,
	id = '',
	className = '',
	required = false,
	hasError,
	styles = smallCGIDropdownStyle(hasError),
	bold = true,
	errorMessage,
}) {
	const [inputValue, setInputValue] = useState(value);
	const {t} = useTranslation();
	const CustomDropdownIndicator = (props) => {
		return <components.DropdownIndicator {...props}>{props.selectProps.menuIsOpen ? '▴' : '▾'}</components.DropdownIndicator>;
	};
	const ErrorIndicator = (props) => {
		return (
			<div>
				<Icon cssClass={'icon-error'} iconName={'exclamation-circle'}></Icon>
			</div>
		);
	};

	const getClassName = (bold, hasError) => {
		switch (
			true // Using 'true' for more flexible conditionals
		) {
			case bold:
				return 'dropdown-label dropdown-label-bold';
			case hasError:
				return 'dropdown-label-error dropdown-label-bold';
			default:
				return 'dropdown-label';
		}
	};

	const handleChange = (value) => {
		setInputValue(value);
		onChange(value);
	};

	return (
		<div className={`dropdown-wrapper ${className}`} id={id}>
			{labelKey && (
				<label className={getClassName(bold, hasError)}>
					{t(labelKey)}
					{required && '*'}
				</label>
			)}
			<Select
				id={'dropdown-input'}
				className={hasError ? 'dropdown-input-error' : 'dropdown-input'}
				options={options}
				value={inputValue}
				isSearchable={isSearchable}
				onChange={handleChange}
				placeholder={placeholder}
				controlWidth={'100%'}
				menuWidth={'100%'}
				containerWidth={'100%'}
				containerMargin={'0 auto'}
				noOptionsMessage={() => t('common.noOptionsMessage')}
				isDisabled={disabled}
				styles={styles}
				onFocus={() => handleFocus(id)}
				components={{DropdownIndicator: CustomDropdownIndicator, IndicatorSeparator: hasError ? ErrorIndicator : null}}
			/>
			{hasError ? <p className="error dropdown-input-message-error ">{errorMessage}</p> : null}
		</div>
	);
}

export default Dropdown;

const colors = colorBasedOnTheme();
// overriding default component according to current design system

const smallCGIDropdownStyle = (hasError) => {
	return {
		indicatorsContainer: (styles) => ({
			...styles,
			color: 'black',
			marginRight: '16px',
		}),

		valueContainer: (styles) => ({
			...styles,
			marginLeft: '16px',
			padding: '2px 8px',
		}),

		singleValue: () => ({
			color: hasError ? 'var(--color-red-50)' : colors.colorMainTheme,
			gridArea: '1/1/2/3',
			fontWeight: 'bold',
		}),
		container: (styles) => ({
			...styles,
		}),

		control: (styles, state) => ({
			...styles,
			backgroundColor: state.isDisabled ? '#ececec' : 'white',
			minHeight: '45px',
			borderRadius: '100px',
			borderColor: colors.colorMainTheme,
			boxShadow: 'none',
			':hover': {
				...styles[':hover'],
				outline: '0!important',
				borderColor: colors.colorMainTheme,
				boxShadow: 'none',
			},
			':focus': {
				...styles[':focus'],
				outline: '0!important',
				borderColor: colors.colorMainTheme,
				boxShadow: 'none',
			},
			':active': {
				...styles[':active'],
				outline: '0!important',
				borderColor: colors.colorMainTheme,
				boxShadow: 'none',
			},
		}),

		menu: (provided, state) => ({
			...provided,
			padding: 0,
			borderRadius: '10px',
			zIndex: '10',
			width: '100%',
		}),

		menuList: (provided, state) => ({
			...provided,
			padding: 0,
			marginTop: 0,
			marginBottom: 0,
			maxHeight: '220px',
			overflowY: 'auto',
		}),

		dropdownIndicator: (provided, state) => ({
			...provided,
			padding: '8px',
			color: colors.colorMainTheme,
		}),
		indicatorSeparator: (provided, state) => ({
			...provided,
			display: 'none',
		}),
		option: (styles, {isDisabled, isFocused, isSelected}) => {
			const whiteColor = '#fff';
			const blackColor = '#000';
			const mainColor = colors.colorMainTheme;
			const greyColor = colors.colorNeutral80;

			return {
				...styles,

				backgroundColor: isDisabled ? '#ececec' : isSelected ? whiteColor : isFocused ? whiteColor : null,

				color: isDisabled ? greyColor : isSelected ? mainColor : blackColor,

				cursor: isDisabled ? 'not-allowed' : 'default',

				':hover': {
					...styles[':hover'],
					backgroundColor: !isDisabled && (isSelected ? colors.colorNeutral95 : colors.colorNeutral95),
				},
			};
		},
	};
};
